import React, { useState, useRef, useEffect } from 'react';
import { getCurrentUser, getUser } from '../api';
import MessageList from './MessageList';
import TextInput from './TextInput';
import VoiceInput from './VoiceInput';
import VocabFlashcards from './VocabFlashcards';
import WritingAssessment from './WritingAssessment';
import API from '../services/api';
import { getCurrentVocabBlock, markBlockAsRead } from '../api';
import MessageLimitTracker from './MessageLimitTracker';
import ConversationStarter from './ConversationStarter';
import ttsService from '../services/ttsService';
import '../styles/Chat.css';
import { processTextToSpeech } from '../utils/ttsHandler';


const ChatInterface = () => {
  // Core state management
  const [currentStep, setCurrentStep] = useState('vocab');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transcribedText, setTranscribedText] = useState('');
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [context, setContext] = useState('english_tutor'); // Force english_tutor context
  const [error, setError] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [shouldStartConversation, setShouldStartConversation] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // Vocabulary and media state management
  const [vocabBlock, setVocabBlock] = useState(null);
  const [vocabLoading, setVocabLoading] = useState(true);
  const [mediaError, setMediaError] = useState(null);
  const [activeMedia, setActiveMedia] = useState(null);

  // Message limit tracking
  const {
    messageCount,
    remainingMessages,
    incrementCount,
    MessageAlert,
    MessageBadge
  } = MessageLimitTracker({
    onLimitReached: () => {
      setError('You have reached your daily message limit. Please wait until tomorrow.');
    }
  });

  // Refs for DOM manipulation
  const messagesEndRef = useRef(null);

  // Initialize vocabulary content
  useEffect(() => {
    initializeVocab();
  }, []);

  useEffect(() => {
    const initializeUser = async () => {
        try {
            const userResponse = await getCurrentUser();
            const userData = userResponse.data;
            setCurrentUser({
                id: userData.id,
                name: `${userData.first_name} ${userData.last_name}`.trim()
            });
        } catch (error) {
            console.error('Error fetching user data:', error);
            setError('Failed to fetch user data');
        }
    };

    initializeUser();
}, []);

  // Initialize chat when entering chat step
  useEffect(() => {
    if (currentStep === 'chat' && !isInitialized) {
      checkChatAccess();
    }
  }, [currentStep, isInitialized]);

  // Auto-scroll for messages
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Cleanup operations
  useEffect(() => {
    return () => {
      ttsService.stopAll();
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const validateMediaUrl = (url) => {
    try {
      const processedUrl = new URL(url);
      return processedUrl.toString();
    } catch (error) {
      console.error('Invalid media URL:', url);
      return null;
    }
  };

  const initializeVocab = async () => {
    try {
      setVocabLoading(true);
      const response = await getCurrentVocabBlock();
      
      if (response?.data && response.data.block) {
        const processedBlock = {
          ...response.data.block,
          progressId: response.data.id,
          words: response.data.block.words.map(word => ({
            ...word,
            imageUrl: word.imageUrl ? validateMediaUrl(word.imageUrl) : null,
            videoUrl: word.videoUrl ? validateMediaUrl(word.videoUrl) : null,
          }))
        };
        
        setVocabBlock(processedBlock);
      } else {
        setCurrentStep('chat');
      }
    } catch (error) {
      console.error('Vocabulary initialization error:', error);
      setMediaError('Failed to load vocabulary content. Please try again.');
      setCurrentStep('chat');
    } finally {
      setVocabLoading(false);
    }
  };

  const checkChatAccess = async () => {
    try {
      setIsLoading(true);
      const currentUserResponse = await getCurrentUser();
      const userId = currentUserResponse.data.id;
      
      const userResponse = await getUser(userId);
      const userData = userResponse.data;
      
      const hasAccess = userData.chatbot_access;
      const expiryDate = userData.chatbot_expiry ? new Date(userData.chatbot_expiry) : null;
      const isExpired = expiryDate ? new Date() > expiryDate : false;
      
      setUserAccess({
        hasAccess,
        isExpired,
        expiryDate,
        userData
      });
  
      if (hasAccess && !isExpired) {
        setIsInitialized(true);
      }
    } catch (error) {
      console.error('Error checking chat access:', error);
      setError('Failed to verify chat access. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleConversationStarted = async ({ initialMessage, topic }) => {
    try {
        if (!currentUser) {
            console.error('No current user data available');
            setError('User information not available');
            return;
        }

        console.log('Starting conversation with user:', currentUser); // Debug log

        const newConversation = await API.chat.newConversation({
            context: 'english_tutor', // Force english_tutor
            user_id: currentUser.id,
            user_name: currentUser.name
        });

        console.log('Conversation created:', newConversation); // Debug log

        setConversationId(newConversation.conversation_id);
        if (initialMessage) {
            setMessages([initialMessage]);
        }
        setTranscribedText('');
        setActiveMedia(null);
    } catch (error) {
        console.error('Error starting conversation:', error);
        setError('Failed to start conversation: ' + (error.message || 'Unknown error'));
    }
};

  const handleContextChange = (newContext) => {
    setContext(newContext);
    setIsInitialized(false);
    setConversationId(null);
    setMessages([]);
  };

  const handleTextSubmit = async (text) => {
    if (!text || typeof text !== 'string' || !text.trim() || !conversationId || !currentUser) {
        return;
    }

    if (!incrementCount()) {
        return;
    }

    setIsLoading(true);
    try {
        const userMessage = {
            text: text.trim(),
            sender: 'user',
            timestamp: new Date()
        };
        setMessages(prev => [...prev, userMessage]);

        const response = await API.chat.sendMessage({
            message: text.trim(),
            conversation_id: conversationId,
            context: context,
            user_id: currentUser.id,
            user_name: currentUser.name
        });

        if (response) {
            const botMessage = {
                text: response.response,
                sender: 'bot',
                timestamp: new Date(),
                corrections: response.corrections,
                learning_points: response.learning_points,
                follow_up_questions: response.follow_up_questions
            };
            setMessages(prev => [...prev, botMessage]);

        if (context === 'english_tutor') {
          try {
            setIsSpeaking(true);
            await processTextToSpeech(response.response, ttsService);
            
            if (response.follow_up_questions?.length > 0) {
              await new Promise(resolve => setTimeout(resolve, 1000));
              await processTextToSpeech(response.follow_up_questions[0], ttsService);
            }
          } catch (ttsError) {
            console.error('Text-to-speech processing error:', ttsError);
          } finally {
            setIsSpeaking(false);
          }
        }
      }
    } catch (error) {
      console.error('Message submission error:', error);
      const errorMessage = {
          text: 'Sorry, I encountered an error processing your message.',
          sender: 'bot',
          timestamp: new Date(),
          isError: true
      };
      setMessages(prev => [...prev, errorMessage]);
  } finally {
      setIsLoading(false);
      setTranscribedText('');
  }
};
  const handleVoiceInput = async (text) => {
    if (typeof text === 'string') {
      setTranscribedText(text);
      await handleTextSubmit(text);
    }
  };

  const handleVocabComplete = async () => {
    try {
      if (vocabBlock && vocabBlock.progressId) {
        await markBlockAsRead(vocabBlock.progressId);
        
        if (activeMedia) {
          setActiveMedia(null);
        }
      }
    } catch (error) {
      console.error('Error marking vocabulary as complete:', error);
    } finally {
      setCurrentStep('writing');
    }
  };

  const handleWritingComplete = async (result) => {
    const assessmentMessage = {
      text: `Writing Assessment Results:

Overall Score: ${result.assessment.overall_score}/100

Your paragraph: "${result.paragraph}"

Feedback:
${result.assessment.grammar_feedback.map(f => `- ${f.error || 'Good job!'}`).join('\n')}
${result.assessment.writing_improvements?.map(i => `- ${i.suggestion}`).join('\n') || ''}`,
      sender: 'system',
      timestamp: new Date(),
      isAssessment: true,
      vocabularyUsed: result.vocabularyUsed || []
    };
    
    setMessages([assessmentMessage]);
    setCurrentStep('chat');
  };

  // Loading state - only show during vocab loading
  if (vocabLoading) {
    return (
      <div className="chat-interface loading">
        <div className="loading-message">
          <div className="loading-spinner"></div>
          <p>Loading your learning session...</p>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="chat-interface error">
        <div className="error-message">
          <div className="text-red-500">{error}</div>
          <button onClick={() => setError(null)} className="retry-button">
            Dismiss
          </button>
        </div>
      </div>
    );
  }

  // Vocabulary step
  if (currentStep === 'vocab' && vocabBlock) {
    return (
      <div className="chat-interface">
        <VocabFlashcards 
          block={vocabBlock} 
          onComplete={handleVocabComplete}
          progressId={vocabBlock.progressId}
        />
        {mediaError && (
          <div className="media-error-message">
            {mediaError}
            <button onClick={() => setMediaError(null)}>Dismiss</button>
          </div>
        )}
      </div>
    );
  }

  // Writing assessment step
  if (currentStep === 'writing' && vocabBlock) {
    return (
      <div className="chat-interface">
        <WritingAssessment
          words={vocabBlock.words}
          onComplete={handleWritingComplete}
        />
      </div>
    );
  }

  // Chat interface
  if (currentStep === 'chat') {
    // Check chat access
    if (!userAccess?.hasAccess || userAccess?.isExpired) {
      return (
        <div className="chat-interface restricted">
          <div className="access-restricted">
            <div className="text-xl font-semibold mb-4">Chat Access Restricted</div>
            <div className="text-gray-400 mb-4">
              {!userAccess?.hasAccess 
                ? "You don't have access to the chat feature. Please contact support to enable chat functionality."
                : "Your chat access has expired. Please renew your subscription to continue using the chat feature."}
            </div>
            {userAccess?.expiryDate && (
              <div className="text-sm text-gray-500">
                Access expired on: {userAccess.expiryDate.toLocaleDateString()}
              </div>
            )}
            <button
              onClick={() => setCurrentStep('vocab')}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Return to Vocabulary Practice
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="chat-interface">
        <div className="chat-header">
          <h2>{context === 'english_tutor' ? 'English Tutor' : 'Chat Assistant'}</h2>
          <select 
            value={context} 
            onChange={(e) => handleContextChange(e.target.value)}
            className="context-selector"
          >
            <option value="english_tutor">English Tutor</option>
            <option value="general">General Chat</option>
          </select>
        </div>

        <MessageAlert />
        
        {isInitialized && !conversationId && (
  <ConversationStarter 
    onConversationStarted={handleConversationStarted}
    context={context}
  />
)}
        
        <div className="messages-container">
          <MessageList 
            messages={messages} 
            isEnglishTutor={context === 'english_tutor'}
            isLoading={isLoading || isSpeaking} 
          />
          <div ref={messagesEndRef} />
        </div>
        
        <div className="input-section relative">
          <MessageBadge />
          <TextInput 
            onSubmit={handleTextSubmit} 
            isLoading={isLoading || isSpeaking} 
            initialValue={transcribedText}
            disabled={remainingMessages <= 0 || !conversationId}
          />
          <VoiceInput 
            onVoiceInput={handleVoiceInput} 
            isLoading={isLoading || isSpeaking}
            conversationId={conversationId}
            context={context}
            disabled={remainingMessages <= 0 || !conversationId}
          />
        </div>
      </div>
    );
  }

  // Default fallback UI
  return (
    <div className="chat-interface">
      <div className="loading-message">
        <p>Loading next step...</p>
      </div>
    </div>
  );
};

export default ChatInterface;