import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  AttachFile, 
  Link as LinkIcon, 
  Delete as DeleteIcon, 
  ZoomIn,
  Image as ImageIcon,
  VideoCameraBack as VideoIcon,
  Description as FileIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';

const GlassCard = styled(Box)(({ theme }) => ({
  background: 'rgba(30, 30, 30, 0.5)',
  borderRadius: '10px',
  padding: '20px',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const NoticeExcerpt = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
  color: 'white',
});

const ViewButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  padding: '4px 12px',
  fontSize: '0.75rem',
  minWidth: 'auto',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(30, 30, 30, 0.5)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    color: 'white',
  },
}));

const MediaPreview = styled('div')({
  maxWidth: '600px',
  margin: '0 auto',
  marginTop: '16px',
  marginBottom: '16px',
  '& img, & video': {
    width: '100%',
    height: 'auto',
    maxWidth: '600px',
    objectFit: 'contain',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
});

const VideoContainer = styled('div')({
  width: '100%',
  maxWidth: '800px',
  margin: '16px auto',
  position: 'relative',
  '& video': {
    width: '100%',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '& iframe': {
    width: '100%',
    borderRadius: '8px',
    aspectRatio: '16/9',
    border: 'none',
  }
});

const AttachmentItem = styled(ListItem)(({ theme }) => ({
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  marginBottom: '8px',
  background: 'rgba(255, 255, 255, 0.05)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

const FileIconWrapper = styled(ListItemIcon)({
  color: 'rgba(255, 255, 255, 0.8)',
});

const NoticeCard = ({ notices, userRole, onDelete }) => {
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const extractVideoUrl = (content) => {
    // Regular expression to match URLs with video extensions
    const urlRegex = /(https?:\/\/[^\s]+\.(mp4|webm|ogg|mov))/i;
    const match = content?.match(urlRegex);
    return match ? match[0] : null;
  };

  const handleNoticeClick = (notice) => {
    setSelectedNotice(notice);
  };

  const handleCloseNotice = () => {
    setSelectedNotice(null);
  };

  const handleDeleteClick = (event, notice) => {
    event.stopPropagation();
    setSelectedNotice(notice);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedNotice) {
      onDelete(selectedNotice.id);
      setDeleteConfirmOpen(false);
      setSelectedNotice(null);
    }
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('MMMM D, YYYY h:mm A');
  };

  const getFileType = (filename) => {
    const extension = filename?.split('.').pop()?.toLowerCase();
    if (/^(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(extension)) return 'image';
    if (/^(mp4|webm|ogg|mov|avi)$/i.test(extension)) return 'video';
    return 'other';
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'image':
        return <ImageIcon />;
      case 'video':
        return <VideoIcon />;
      default:
        return <FileIcon />;
    }
  };

  const renderAttachmentPreview = (attachment) => {
    const fileType = getFileType(attachment.image);
    
    switch (fileType) {
      case 'image':
        return (
          <MediaPreview>
            <img
              src={attachment.image}
              alt={`Attachment ${attachment.id}`}
              onClick={() => setImagePreviewUrl(attachment.image)}
              style={{ cursor: 'pointer' }}
              loading="lazy"
            />
          </MediaPreview>
        );
      case 'video':
        return (
          <MediaPreview>
            <video controls playsInline>
              <source src={attachment.image} />
              Your browser does not support the video tag.
            </video>
          </MediaPreview>
        );
      default:
        return null;
    }
  };

  const renderNoticeContent = (notice) => {
    if (!notice?.content) return null;
    
    // Extract video URL
    const videoUrl = extractVideoUrl(notice.content);
    
    // Clean the content by removing the video URL and any text before it
    let displayContent = notice.content;
    if (videoUrl) {
      // Find the position of the URL
      const urlIndex = displayContent.indexOf(videoUrl);
      // Get everything before the URL (if any non-whitespace exists)
      let beforeUrl = displayContent.substring(0, urlIndex).trim();
      // Get everything after the URL
      let afterUrl = displayContent.substring(urlIndex + videoUrl.length).trim();
      
      // Combine the parts, ensuring we don't add extra spaces
      displayContent = [beforeUrl, afterUrl].filter(Boolean).join('\n');
    }
    
    // Handle newlines by splitting content and creating separate Typography elements
    // Use a regex that matches all types of newlines
    const contentLines = displayContent.split(/\\r\\n|\r\n|\n|\r/).filter(Boolean).map((line, index, array) => (
      <Typography 
        key={index} 
        variant="body1" 
        color="white" 
        sx={{ 
          whiteSpace: 'pre-line',
          mb: index === array.length - 1 ? 0 : 2  // Add margin between paragraphs
        }}
      >
        {line.trim()}
      </Typography>
    ));

    return (
      <>
        {contentLines.length > 0 ? contentLines : null}
        
        {videoUrl && (
          <VideoContainer>
            <video 
              controls 
              playsInline
              style={{ width: '100%', maxWidth: '800px' }}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </VideoContainer>
        )}
      </>
    );
  };

  const isAdminOrManager = userRole === 'ADMIN' || userRole === 'MANAGER';

  return (
    <GlassCard>
      <Typography variant="h6" color="white" gutterBottom>Notices</Typography>
      {notices && notices.length > 0 ? (
        <>
          {notices.slice(0, 3).map((notice) => (
            <NoticeExcerpt key={notice.id}>
              <Typography variant="body2" component="span" sx={{ flex: 1, marginRight: '8px' }}>
                {notice.title}
              </Typography>
              <Box>
                <ViewButton onClick={() => handleNoticeClick(notice)}>
                  View
                </ViewButton>
                {isAdminOrManager && (
                  <IconButton 
                    size="small" 
                    onClick={(e) => handleDeleteClick(e, notice)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </NoticeExcerpt>
          ))}
          {notices.length > 3 && (
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => handleNoticeClick(notices[3])} 
              fullWidth
              sx={{ mt: 2 }}
            >
              View More Notices
            </Button>
          )}
        </>
      ) : (
        <Typography variant="body2" color="white">No notices available</Typography>
      )}

      <GlassDialog open={!!selectedNotice} onClose={handleCloseNotice} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography variant="h6" color="white">{selectedNotice?.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} gutterBottom>
            Posted by: {selectedNotice?.author?.full_name} on {formatDate(selectedNotice?.created_at)}
          </Typography>
          
          {renderNoticeContent(selectedNotice)}

          {selectedNotice?.attachments && selectedNotice.attachments.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1" color="white" gutterBottom>Attachments:</Typography>
              <List>
                {selectedNotice.attachments.map((attachment, index) => {
                  const fileType = getFileType(attachment.image);
                  const fileName = attachment.image?.split('/').pop().split('?')[0];
                  
                  return (
                    <Box key={attachment.id || index}>
                      <AttachmentItem>
                        <FileIconWrapper>
                          {getFileIcon(fileType)}
                        </FileIconWrapper>
                        <ListItemText 
                          primary={
                            <Typography color="white">{fileName}</Typography>
                          }
                          secondary={
                            <Typography color="rgba(255, 255, 255, 0.7)">
                              {fileType.toUpperCase()}
                            </Typography>
                          }
                        />
                        {fileType === 'image' ? (
                          <IconButton 
                            onClick={() => setImagePreviewUrl(attachment.image)}
                            sx={{ color: 'white' }}
                          >
                            <ZoomIn />
                          </IconButton>
                        ) : (
                          <Button 
                            href={attachment.image} 
                            download={fileName}
                            variant="contained" 
                            color="primary"
                            startIcon={<AttachFile />}
                            size="small"
                          >
                            Download
                          </Button>
                        )}
                      </AttachmentItem>
                      {renderAttachmentPreview(attachment)}
                    </Box>
                  );
                })}
              </List>
            </Box>
          )}

          {selectedNotice?.links && selectedNotice.links.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1" color="white" gutterBottom>Links:</Typography>
              <List>
                {selectedNotice.links.map((link, index) => (
                  <AttachmentItem key={index}>
                    <FileIconWrapper>
                      <LinkIcon />
                    </FileIconWrapper>
                    <ListItemText 
                      primary={
                        <Typography color="white">{link.title}</Typography>
                      }
                    />
                    <Button 
                      href={link.url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      variant="contained" 
                      color="primary"
                      size="small"
                    >
                      Open Link
                    </Button>
                  </AttachmentItem>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotice} color="primary" variant="contained">
            Close
          </Button>
          {isAdminOrManager && (
            <Button 
              onClick={(e) => handleDeleteClick(e, selectedNotice)} 
              color="error" 
              variant="contained"
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </GlassDialog>

      <Dialog 
        open={deleteConfirmOpen} 
        onClose={() => setDeleteConfirmOpen(false)}
        PaperProps={{
          style: {
            background: 'rgba(30, 30, 30, 0.9)',
            color: 'white',
            borderRadius: '12px',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          }
        }}
      >
        <DialogTitle>
          <Typography color="white">Confirm Deletion</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color="white">Are you sure you want to delete this notice?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={!!imagePreviewUrl} 
        onClose={() => setImagePreviewUrl(null)} 
        maxWidth="lg" 
        fullWidth
        PaperProps={{
          style: {
            background: 'rgba(0, 0, 0, 0.9)',
            backdropFilter: 'blur(10px)',
            borderRadius: '12px',
          }
        }}
      >
        <DialogContent>
          <MediaPreview>
            <img 
              src={imagePreviewUrl} 
              alt="Preview" 
              style={{ maxHeight: '80vh' }}
            />
          </MediaPreview>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImagePreviewUrl(null)} color="primary">
            Close
          </Button>
          <Button 
            href={imagePreviewUrl} 
            download
            color="primary" 
            variant="contained"
            startIcon={<AttachFile />}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </GlassCard>
  );
};

export default NoticeCard;