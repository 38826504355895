import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MessageSquare } from 'lucide-react';

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(15, 23, 42, 0.95)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '12px',
    color: 'white',
    minWidth: '320px',
  }
}));

const TeamUpdateNotificationModal = ({ open, onClose, notifications, onMarkRead }) => {
    const navigate = useNavigate();

    const handleViewTeamUpdates = () => {
        // Only mark as read when actually viewing updates
        notifications.forEach(notification => {
          onMarkRead(notification.id);
        });
        navigate('/team-updates');
        onClose();
      };
    
      // Simple close without marking as read
      const handleClose = () => {
        onClose();
      };

      return (
        <GlassDialog 
          open={open} 
          onClose={handleClose}  // Use handleClose instead of onClose directly
          maxWidth="sm"
          fullWidth
        >
      <DialogTitle sx={{ 
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        p: 2 
      }}>
        <Box display="flex" alignItems="center" gap={1}>
          <MessageSquare />
          <Typography variant="h6">New Team Update Comments</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2, minHeight: '100px', maxHeight: '400px', overflowY: 'auto' }}>
        {notifications.map((notification) => (
          <Box 
            key={notification.id} 
            sx={{ 
              mb: 2,
              p: 2,
              background: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              border: '1px solid rgba(255, 255, 255, 0.1)'
            }}
          >
            <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.9)', mb: 1 }}>
              {notification.message}
            </Typography>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)' }}>
              {new Date(notification.created_at).toLocaleString()}
            </Typography>
          </Box>
        ))}
      </DialogContent>
      <DialogActions sx={{ 
        borderTop: '1px solid rgba(255, 255, 255, 0.1)', 
        p: 2,
        gap: 1 
      }}>
        <Button 
          onClick={handleClose}  // Use handleClose here
          variant="outlined" 
          color="primary"
        >
          Close
        </Button>
        <Button 
          onClick={handleViewTeamUpdates} 
          variant="contained" 
          color="primary"
          startIcon={<MessageSquare size={16} />}
        >
          View Team Updates
        </Button>
      </DialogActions>
    </GlassDialog>
  );
};

export default TeamUpdateNotificationModal;