import React from 'react';

const TypingIndicator = () => {
  return (
    <div className="message bot">
      <div className="message-content">
        <div className="flex items-center gap-2">
          <div className="typing-animation">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <span className="text-white text-sm">Responding...</span>
        </div>
      </div>
      <style jsx>{`
        .typing-animation {
          display: flex;
          gap: 4px;
          padding: 4px 0;
        }

        .dot {
          width: 8px;
          height: 8px;
          background: white;
          border-radius: 50%;
          animation: bouncing 1.2s linear infinite;
        }

        .dot:nth-child(2) {
          animation-delay: 0.2s;
        }

        .dot:nth-child(3) {
          animation-delay: 0.4s;
        }

        @keyframes bouncing {
          0%, 80%, 100% {
            transform: translateY(0);
            opacity: 0.4;
          }
          40% {
            transform: translateY(-6px);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default TypingIndicator;