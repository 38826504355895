import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Alert,
  AlertTitle,
  Box,
  Typography,
  Button
} from '@mui/material';
import dayjs from 'dayjs';
import { getUser } from '../api';

const STORAGE_KEY = 'chatbot_notification';
const MAX_SHOWS = 2;
const MIN_DAYS_BETWEEN_SHOWS = 1; // Minimum days between showing the notification

const ChatbotNotification = ({ user, onClose }) => {
  const [open, setOpen] = useState(false);
  const [fullUserData, setFullUserData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFullUserData = async () => {
      if (!user?.id) return;
      
      try {
        const response = await getUser(user.id);
        setFullUserData(response.data);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch chatbot access information');
      }
    };

    fetchFullUserData();
  }, [user?.id]);

  useEffect(() => {
    if (!fullUserData?.chatbot_access || dayjs(fullUserData.chatbot_expiry).isBefore(dayjs())) {
      return;
    }

    // Get stored notification data
    const storedData = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
    const timesShown = storedData.timesShown || 0;
    const lastShown = storedData.lastShown ? dayjs(storedData.lastShown) : null;
    
    // Check if we can show the notification
    const canShow = timesShown < MAX_SHOWS && 
      (!lastShown || dayjs().diff(lastShown, 'day') >= MIN_DAYS_BETWEEN_SHOWS);

    if (canShow) {
      setOpen(true);
      // Update localStorage
      localStorage.setItem(STORAGE_KEY, JSON.stringify({
        timesShown: timesShown + 1,
        lastShown: dayjs().toISOString()
      }));
    }
  }, [fullUserData]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  if (!fullUserData?.chatbot_access || dayjs(fullUserData.chatbot_expiry).isBefore(dayjs())) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#0B1537',
          color: 'white',
          border: '1px solid #3b82f6',
          borderRadius: '12px'
        }
      }}
    >
      <DialogTitle sx={{ 
        fontSize: '1.5rem', 
        fontWeight: 'bold',
        color: '#60a5fa',
        mb: 2
      }}>
        Welcome to NIL AI Chatbot! 🤖
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Box
            component="img"
            src="https://nationalinstituteoflanguage.in/wp-content/uploads/2025/01/Untitled-design-13.png"
            alt="AI Chatbot"
            sx={{
              width: '100%',
              height: 200,
              objectFit: 'cover',
              borderRadius: '8px',
              mb: 2
            }}
          />
          
          <Alert 
            severity="info"
            sx={{
              mb: 2,
              backgroundColor: 'rgba(59, 130, 246, 0.1)',
              color: 'white',
              '& .MuiAlert-icon': {
                color: '#60a5fa'
              }
            }}
          >
            <AlertTitle sx={{ color: '#60a5fa' }}>
              You have been granted access to our A.I Chatbot!
            </AlertTitle>
            The chatbot is currently in beta, and we're actively working on improvements. 
            Your feedback is valuable to us!
          </Alert>
          
          <Box sx={{ color: 'rgba(255, 255, 255, 0.7)', '& > p': { mb: 1 } }}>
            <Typography>
              ⚡ Try it out and help us make it better! (go to daily vocab)
            </Typography>
            <Typography>
              🔄 If you encounter any issues or have suggestions, please use the "Submit Feedback" option.
            </Typography>
            <Typography sx={{ 
              fontSize: '0.875rem',
              color: '#60a5fa',
              mt: 2
            }}>
              Access valid until: {dayjs(fullUserData.chatbot_expiry).format('DD MMM YYYY')}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={handleClose}
          sx={{
            background: 'linear-gradient(to right, #2563eb, #3b82f6)',
            color: 'white',
            borderRadius: '9999px',
            px: 4,
            py: 1,
            '&:hover': {
              background: 'linear-gradient(to right, #1d4ed8, #2563eb)',
            }
          }}
        >
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatbotNotification;