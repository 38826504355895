import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Badge,
  IconButton,
  Popper,
  Paper,
  ClickAwayListener,
  Grow,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Autocomplete,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MessageIcon from '@mui/icons-material/Message';
import FilterListIcon from '@mui/icons-material/FilterList';
import webSocketService from '../services/apiws';
import { listUsers } from '../api';

const UserMessagesNotification = ({ userRole }) => {
  const [messages, setMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState('all');

  const calculateUnreadCount = useCallback((messagesList) => {
    // For all users, only count unread messages where they are tagged or are the direct recipient
    return messagesList.filter(msg => {
      const currentUserId = localStorage.getItem('userId');
      const isRecipient = msg.tagged_users?.some(user => user.id === currentUserId) || 
                         msg.recipient?.id === currentUserId;
      return !msg.is_read && isRecipient;
    }).length;
  }, []);

  const fetchMessages = useCallback(async () => {
    try {
      setLoading(true);
      console.log('Fetching messages:', { page, filter });
      
      const response = await fetch(`https://dj.nilconnect.in/api/user-messages/?page=${page}&filter=${filter}`, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Received messages from API:', data);
      
      const messagesList = Array.isArray(data) ? data : [];
      
      setMessages(prev => {
        // If it's the first page, replace all messages
        if (page === 1) {
          return messagesList;
        }
        // Otherwise, append new messages while avoiding duplicates
        const existingIds = new Set(prev.map(msg => msg.id));
        const newMessages = messagesList.filter(msg => !existingIds.has(msg.id));
        return [...prev, ...newMessages];
      });
      
      setHasMore(messagesList.length > 0);
      setUnreadCount(calculateUnreadCount(messagesList));
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  }, [page, filter, calculateUnreadCount]);

  useEffect(() => {
    let mounted = true;
    let wsUnsubscribe = null;
    
    const initializeWebSocket = async () => {
      try {
        if (!mounted) return;

        if (webSocketService.isConnected()) {
          console.log('WebSocket already connected');
          return;
        }

        await webSocketService.connect();
        
        wsUnsubscribe = webSocketService.addListener((data) => {
          if (!mounted) return;
          console.log('WebSocket message received:', data);
          
          switch(data.type) {
            case 'notification':
              if (data.notification) {
                console.log('Adding notification to messages:', data.notification);
                setMessages(prevMessages => {
                  const newMessages = [...prevMessages];
                  const messageExists = newMessages.some(msg => msg.id === data.notification.id);
                  
                  if (!messageExists) {
                    newMessages.unshift(data.notification);
                    // Only increment unread count for admin/manager if they are recipients
                    if (!data.notification.is_read) {
                      if (['ADMIN', 'MANAGER'].includes(userRole)) {
                        const isRecipient = data.notification.tagged_users?.some(
                          user => user.id === localStorage.getItem('userId')
                        ) || data.notification.recipient?.id === localStorage.getItem('userId');
                        
                        if (isRecipient) {
                          setUnreadCount(prev => prev + 1);
                        }
                      } else {
                        setUnreadCount(prev => prev + 1);
                      }
                    }
                  }
                  
                  return newMessages;
                });
              }
              break;
              
            case 'notification_marked_read':
              console.log('Marking message as read:', data.notification_id);
              setMessages(prevMessages =>
                prevMessages.map(msg =>
                  msg.id === data.notification_id
                    ? { ...msg, is_read: true }
                    : msg
                )
              );
              setUnreadCount(prev => Math.max(0, prev - 1));
              break;
              
            case 'connection_established':
              console.log('WebSocket connection established');
              // Don't fetch messages immediately to avoid duplicates
              break;
              
            default:
              console.log('Unhandled message type:', data.type);
          }
        });
      } catch (error) {
        console.error('Failed to initialize WebSocket:', error);
      }
    };
    
    initializeWebSocket();
    return () => {
      mounted = false;
      if (wsUnsubscribe) {
        wsUnsubscribe();
      }
    };
  }, [userRole]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const handleSearchUsers = async (searchTerm) => {
    try {
      setSearchLoading(true);
      const response = await listUsers({ search: searchTerm });
      setUserOptions(response.results || []);
    } catch (error) {
      console.error('Error searching users:', error);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
  
    try {
      setLoading(true);
      
      // Create a temporary message object for immediate feedback
      const tempMessage = {
        id: `temp-${Date.now()}`,
        text: newMessage,
        sender: {
          id: null, // Will be replaced with actual sender info
          full_name: 'Sending...'
        },
        tagged_users: taggedUsers,
        created_at: new Date().toISOString(),
        is_read: true,
        is_delivered: false
      };
  
      // Add temporary message to state
      setMessages(prev => [tempMessage, ...prev]);
      
      // Send the actual message
      await webSocketService.sendMessage(
        newMessage,
        taggedUsers.map(user => user.id)
      );
      
      // Clear input fields
      setNewMessage('');
      setTaggedUsers([]);
      
    } catch (error) {
      console.error('Error sending message:', error);
      // Remove temporary message on error
      setMessages(prev => prev.filter(msg => msg.id !== `temp-${Date.now()}`));
    } finally {
      setLoading(false);
    }
  };
  const handleMarkAsRead = async (messageId) => {
    try {
      await webSocketService.markAsRead(messageId);
      setMessages(prev =>
        prev.map(msg =>
          msg.id === messageId ? { ...msg, is_read: true } : msg
        )
      );
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  const handleLoadMore = () => {
    if (hasMore && !loading) {
      setPage(prev => prev + 1);
    }
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setPage(1);
    setFilterAnchorEl(null);
  };

  const canSendMessages = ['ADMIN', 'MANAGER', 'TRAINER'].includes(userRole);
  const canViewAllMessages = ['ADMIN', 'MANAGER'].includes(userRole);

  const handleClickAway = () => {
    setAnchorEl(null);
    setFilterAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Badge badgeContent={unreadCount} color="error">
          <MessageIcon />
        </Badge>
      </IconButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 1400 }}
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
           <Grow {...TransitionProps}>
           <Paper 
             sx={{
               background: 'rgba(25, 25, 25, 0.95)',
               backdropFilter: 'blur(10px)',
               border: '1px solid rgba(255, 255, 255, 0.1)',
               color: 'white',
               maxHeight: '90vh', // Limit maximum height
               display: 'flex', // Enable flex layout
               flexDirection: 'column' // Stack children vertically
             }}
           >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ width: 360, maxHeight: '70vh' }}>
                  <Box sx={{ 
                    p: 2, 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                  }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>Messages</Typography>
                    <Box>
                      <Tooltip title="Filter messages">
                        <IconButton 
                          onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                          sx={{ color: 'white' }}
                        >
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>

                  <Menu
                    anchorEl={filterAnchorEl}
                    open={Boolean(filterAnchorEl)}
                    onClose={() => setFilterAnchorEl(null)}
                    PaperProps={{
                      sx: {
                        background: 'rgba(25, 25, 25, 0.95)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        color: 'white'
                      }
                    }}
                  >
                    <MenuItem onClick={() => handleFilterChange('all')} sx={{ color: 'white' }}>
                      All Messages
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterChange('sent')} sx={{ color: 'white' }}>
                      Sent Messages
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterChange('received')} sx={{ color: 'white' }}>
                      Received Messages
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterChange('tagged')} sx={{ color: 'white' }}>
                      Tagged Messages
                    </MenuItem>
                    {canViewAllMessages && (
                      <MenuItem onClick={() => handleFilterChange('system')} sx={{ color: 'white' }}>
                        System Messages
                      </MenuItem>
                    )}
                  </Menu>

                  {canSendMessages && (
                    <Box sx={{ p: 2 }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message..."
                        variant="outlined"
                        sx={{ 
                          mb: 1,
                          '& .MuiOutlinedInput-root': {
                            color: 'white',
                            '& fieldset': {
                              borderColor: 'rgba(255, 255, 255, 0.23)',
                            },
                            '&:hover fieldset': {
                              borderColor: 'rgba(255, 255, 255, 0.4)',
                            },
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: 'rgba(255, 255, 255, 0.5)',
                          },
                        }}
                      />
                      <Autocomplete
                        multiple
                        options={userOptions}
                        getOptionLabel={(option) => `${option.full_name} (${option.role})`}
                        value={taggedUsers}
                        onChange={(_, newValue) => setTaggedUsers(newValue)}
                        onInputChange={(_, value) => handleSearchUsers(value)}
                        loading={searchLoading}
                        PopperComponent={(props) => (
                            <Popper {...props} style={{ zIndex: 1500 }} />
                          )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Box>
                              <Typography sx={{ color: 'white' }}>
                                {option.full_name}
                              </Typography>
                              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                {option.role} • {option.username}
                              </Typography>
                            </Box>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search and tag users..."
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                color: 'white',
                                '& fieldset': {
                                  borderColor: 'rgba(255, 255, 255, 0.23)',
                                },
                                '&:hover fieldset': {
                                  borderColor: 'rgba(255, 255, 255, 0.4)',
                                },
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {searchLoading && <CircularProgress color="inherit" size={20} />}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        sx={{ mb: 1 }}
                      />
                      <Button
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={handleSendMessage}
                        disabled={!newMessage.trim()}
                        fullWidth
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.1)',
                          '&:hover': {
                            bgcolor: 'rgba(255, 255, 255, 0.2)',
                          },
                        }}
                      >
                        Send
                      </Button>
                    </Box>
                  )}

<List sx={{ 
                    flexGrow: 1, // Take remaining space
                    overflowY: 'auto',
                    maxHeight: '40vh', // Limit height of message list
                    '&::-webkit-scrollbar': {
                      width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'rgba(255, 255, 255, 0.1)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'rgba(255, 255, 255, 0.3)',
                      borderRadius: '4px'
                    }
                  }}>
                    {messages.map((message) => (
                      <ListItem
                        key={message.id}
                        sx={{
                          bgcolor: message.is_read ? 'rgba(255, 255, 255, 0.05)' : 'rgba(59, 130, 246, 0.1)',
                          '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' },
                          borderRadius: 1,
                          my: 0.5,
                          mx: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => !message.is_read && handleMarkAsRead(message.id)}
                      >
                        <ListItemText
                          primary={
                            <Typography sx={{ color: 'white' }}>
                              {message.text}
                            </Typography>
                          }
                          secondary={
                            <Box>
                              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} display="block">
                                From: {message.sender.full_name}
                              </Typography>
                              {message.tagged_users.length > 0 && (
                                <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} display="block">
                                  Tagged: {message.tagged_users.map(u => u.full_name).join(', ')}
                                </Typography>
                              )}
                              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)' }} display="block">
                                {new Date(message.created_at).toLocaleString()}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                    {loading && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <CircularProgress size={24} sx={{ color: 'white' }} />
                      </Box>
                    )}
                    {hasMore && !loading && (
                      <Button
                        fullWidth
                        onClick={handleLoadMore}
                        sx={{ 
                          mt: 1, 
                          mx: 1, 
                          width: 'calc(100% - 16px)',
                          color: 'white',
                          borderColor: 'rgba(255, 255, 255, 0.23)',
                          '&:hover': {
                            borderColor: 'rgba(255, 255, 255, 0.4)',
                          },
                        }}
                      >
                        Load More
                      </Button>
                    )}
                    {!loading && messages.length === 0 && (
                      <Box sx={{ p: 2, textAlign: 'center' }}>
                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                          No messages found for the selected filter
                        </Typography>
                      </Box>
                    )}
                  </List>

                  <Box sx={{ 
                    p: 1,
                    borderTop: 1,
                    borderColor: 'rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                      {unreadCount} unread message{unreadCount !== 1 ? 's' : ''}
                    </Typography>
                    {webSocketService.isConnected() ? (
                      <Tooltip title="Connected">
                        <Box sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          bgcolor: '#4caf50',
                          boxShadow: '0 0 10px 0 #4caf50'
                        }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Disconnected - Click to reconnect">
                        <Box 
                          sx={{ 
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            bgcolor: '#f44336',
                            cursor: 'pointer',
                            '&:hover': {
                              boxShadow: '0 0 10px 0 #f44336'
                            }
                          }}
                          onClick={() => webSocketService.reconnect()}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UserMessagesNotification;