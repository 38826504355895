import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, Fade } from '@mui/material';

const LoadingScreen = ({ 
  loading, 
  loadingSteps = [
    'Loading trainer profile',
    'Fetching student data',
    'Loading course information',
    'Retrieving attendance records',
    'Setting up trainer details'
  ]
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (loading) {
      const stepDuration = 1000; // Duration for each step in milliseconds
      const totalDuration = stepDuration * loadingSteps.length;
      const progressIncrement = 100 / totalDuration * 16.67; // For 60fps

      const progressInterval = setInterval(() => {
        setProgress(prev => {
          const newProgress = prev + progressIncrement;
          if (newProgress >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return newProgress;
        });
      }, 16.67); // Approximately 60fps

      const stepInterval = setInterval(() => {
        setCurrentStep(prev => {
          if (prev < loadingSteps.length - 1) return prev + 1;
          clearInterval(stepInterval);
          return prev;
        });
      }, stepDuration);

      return () => {
        clearInterval(progressInterval);
        clearInterval(stepInterval);
      };
    }
  }, [loading, loadingSteps.length]);

  if (!loading) return null;

  return (
    <Fade in={loading}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(0, 0, 0, 0.9)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            width: '80%',
            maxWidth: '600px',
            bgcolor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 2,
            p: 4,
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Loading Trainer Dashboard
          </Typography>

          <Box sx={{ mb: 2 }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 8,
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '& .MuiLinearProgress-bar': {
                  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
                  borderRadius: 4,
                },
              }}
            />
          </Box>

          {loadingSteps.map((step, index) => (
            <Fade key={step} in={index <= currentStep}>
              <Typography
                sx={{
                  color: 'white',
                  opacity: index === currentStep ? 1 : 0.5,
                  mb: 1,
                  transition: 'opacity 0.3s ease',
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: index <= currentStep ? '#FF1B6B' : 'rgba(255, 255, 255, 0.2)',
                    marginRight: '12px',
                    transition: 'background-color 0.3s ease',
                  },
                }}
              >
                {step}
              </Typography>
            </Fade>
          ))}
        </Box>
      </Box>
    </Fade>
  );
};

export default LoadingScreen;