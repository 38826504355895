// PolicyManagementPage.js
import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Tabs, Tab, Button, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, IconButton, List, ListItem, ListItemText,
  ListItemSecondaryAction, CircularProgress, Alert, Paper, MenuItem,
  Divider, Card, CardMedia, CardContent, Grid
} from '@mui/material';
import { 
  Delete as DeleteIcon, 
  Edit as EditIcon, 
  Add as AddIcon, 
  Description as DescriptionIcon,
  Visibility as VisibilityIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  listPolicyCategories,
  createPolicyCategory,
  updatePolicyCategory,
  deletePolicyCategory,
  listPolicies,
  createPolicy,
  updatePolicy,
  deletePolicy,
  addPolicyAttachment,
  removePolicyAttachment,
  getCurrentUser,
} from '../api';

// Styled Components
const GlassContainer = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const PolicyCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}));

const VideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  marginBottom: theme.spacing(2),
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  }
}));

const PolicyContent = styled(Box)(({ theme }) => ({
  color: 'white',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
  '& a': {
    color: theme.palette.primary.main,
  },
  '& p': {
    marginBottom: theme.spacing(2),
  },
  '& *': {
    color: 'inherit',
  }
}));

const StyledQuillEditor = styled(Box)(({ theme }) => ({
  '& .ql-container': {
    borderRadius: '0 0 4px 4px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
  '& .ql-toolbar': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px 4px 0 0',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    '& button': {
      color: 'white',
    },
    '& .ql-stroke': {
      stroke: 'white',
    },
    '& .ql-fill': {
      fill: 'white',
    },
  },
  '& .ql-editor': {
    minHeight: '200px',
    color: 'white',
  },
}));

const SidebarCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateX(-5px)',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}));

// Helper function to extract first image from content
const extractFirstImage = (content) => {
  const imgRegex = /<img[^>]+src="([^">]+)"/;
  const match = content.match(imgRegex);
  return match ? match[1] : null;
};

// Helper function to extract text excerpt
const extractExcerpt = (content, wordCount = 50) => {
  const strippedContent = content.replace(/<[^>]+>/g, ' ').replace(/\s+/g, ' ').trim();
  const words = strippedContent.split(' ');
  return words.slice(0, wordCount).join(' ') + (words.length > wordCount ? '...' : '');
};

// Policy View Dialog Component
const PolicyViewDialog = ({ open, onClose, policy, allPolicies, onPolicySelect }) => {
  const [videoLinks, setVideoLinks] = useState([]);
  const [cleanContent, setCleanContent] = useState('');

  useEffect(() => {
    if (policy?.content) {
      const videoRegex = /https?:\/\/[^\s<>"]+?\.mp4/g;
      const foundVideos = policy.content.match(videoRegex) || [];
      setVideoLinks(foundVideos);

      let contentWithoutVideos = policy.content;
      foundVideos.forEach(video => {
        contentWithoutVideos = contentWithoutVideos.replace(video, '');
      });

      const clean = DOMPurify.sanitize(contentWithoutVideos);
      setCleanContent(clean);
    }
  }, [policy]);

  if (!policy) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullScreen
      PaperProps={{
        style: {
          background: 'rgba(18, 18, 18, 0.95)',
          backdropFilter: 'blur(10px)',
        }
      }}
    >
      <DialogContent sx={{ display: 'flex', gap: 3, p: 0 }}>
        {/* Main Content */}
        <Box sx={{ flex: 1, p: 4, overflowY: 'auto', maxHeight: '100vh' }}>
          <Typography variant="h4" sx={{ color: 'white', mb: 2 }}>{policy.title}</Typography>
          <Typography variant="subtitle1" sx={{ color: 'rgba(255,255,255,0.7)', mb: 3 }}>
            Category: {policy.category_name || 'Uncategorized'}
          </Typography>
          
          {videoLinks.map((videoUrl, index) => (
            <VideoContainer key={index}>
              <video controls width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0 }}>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </VideoContainer>
          ))}

          <PolicyContent>
            {parse(cleanContent)}
          </PolicyContent>

          {policy.attachments?.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Attachments</Typography>
              <List>
                {policy.attachments.map((attachment, index) => (
                  <ListItem key={index}>
                    <ListItemText 
                      primary={attachment.title}
                      sx={{ color: 'white' }}
                    />
                    <Button 
                      variant="outlined"
                      startIcon={<DescriptionIcon />}
                      href={attachment.file}
                      target="_blank"
                      sx={{ color: 'white', borderColor: 'white' }}
                    >
                      View
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>

        {/* Sidebar */}
        <Box 
          sx={{ 
            width: 350, 
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            p: 3,
            overflowY: 'auto',
            maxHeight: '100vh'
          }}
        >
          <Typography variant="h6" sx={{ color: 'white', mb: 3 }}>More Policies</Typography>
          {allPolicies
            .filter(p => p.id !== policy.id)
            .map(relatedPolicy => {
              const thumbnail = extractFirstImage(relatedPolicy.content);
              const excerpt = extractExcerpt(relatedPolicy.content);
              
              return (
                <SidebarCard 
                  key={relatedPolicy.id}
                  onClick={() => onPolicySelect(relatedPolicy)}
                >
                  {thumbnail && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={thumbnail}
                      alt={relatedPolicy.title}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
                      {relatedPolicy.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                      {excerpt}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.5)' }}>
                        Read More
                      </Typography>
                      <KeyboardArrowRightIcon sx={{ color: 'rgba(255,255,255,0.5)', fontSize: 16 }} />
                    </Box>
                  </CardContent>
                </SidebarCard>
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions sx={{ position: 'absolute', top: 8, right: 8 }}>
        <Button 
          onClick={onClose} 
          variant="contained"
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            }
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Main Component
const PolicyManagementPage = () => {
  const [categories, setCategories] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [policyDialogOpen, setPolicyDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [userRole, setUserRole] = useState(null);
  
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    title: '',
    content: '',
    category: '',
    attachments: []
  });

  useEffect(() => {
    const fetchUserAndData = async () => {
      try {
        setLoading(true);
        const userResponse = await getCurrentUser();
        setUserRole(userResponse.data.role);
        
        const [categoriesRes, policiesRes] = await Promise.all([
          listPolicyCategories(),
          listPolicies()
        ]);
        
        setCategories(categoriesRes.data);
        setPolicies(policiesRes.data);
        setError(null);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndData();
  }, []);

  const resetFormData = () => {
    setFormData({
      name: '',
      description: '',
      title: '',
      content: '',
      category: '',
      attachments: []
    });
  };

  const handleCategorySave = async () => {
    try {
      const categoryData = {
        name: formData.name,
        description: formData.description
      };

      if (currentItem) {
        await updatePolicyCategory(currentItem.id, categoryData);
      } else {
        await createPolicyCategory(categoryData);
      }
      
      const categoriesRes = await listPolicyCategories();
      setCategories(categoriesRes.data);
      setCategoryDialogOpen(false);
      setCurrentItem(null);
      resetFormData();
    } catch (err) {
      setError('Failed to save category');
      console.error(err);
    }
  };

  const handlePolicySave = async () => {
    try {
      if (!formData.title || !formData.content) {
        setError('Title and content are required');
        return;
      }

      const policyData = new FormData();
      policyData.append('title', formData.title);
      policyData.append('content', formData.content);
      
      if (formData.category) {
        policyData.append('category', formData.category);
      }

      if (formData.attachments?.length > 0) {
        Array.from(formData.attachments).forEach(file => {
          policyData.append('attachments', file);
        });
      }

      if (currentItem) {
        await updatePolicy(currentItem.id, policyData);
      } else {
        await createPolicy(policyData);
      }

      const policiesRes = await listPolicies();
      setPolicies(policiesRes.data);
      setPolicyDialogOpen(false);
      setCurrentItem(null);
      resetFormData();
    } catch (err) {
      console.error('Error saving policy:', err);
      setError('Failed to save policy: ' + (err.message || 'Unknown error'));
    }
  };

  const handleDelete = async (item, type) => {
    try {
      if (type === 'category') {
        await deletePolicyCategory(item.id);
        const categoriesRes = await listPolicyCategories();
        setCategories(categoriesRes.data);
    } else {
      await deletePolicy(item.id);
      const policiesRes = await listPolicies();
      setPolicies(policiesRes.data);
    }
  } catch (err) {
    setError(`Failed to delete ${type}`);
  }
};

if (loading) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress sx={{ color: 'white' }} />
    </Box>
  );
}

const renderPolicies = () => {
  return (
    <Grid container spacing={3}>
      {policies.map((policy) => {
        const thumbnail = extractFirstImage(policy.content) || '/placeholder-policy-image.jpg';
        const excerpt = extractExcerpt(policy.content);

        return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={policy.id}>

            <PolicyCard>
              <CardMedia
                component="img"
                height="300"
                image={thumbnail}
                alt={policy.title}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="h2" sx={{ color: 'white', mb: 1 }}>
                  {policy.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)', mb: 2 }}>
                  {excerpt}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button
                    size="small"
                    onClick={() => {
                      setSelectedPolicy(policy);
                      setViewDialogOpen(true);
                    }}
                    sx={{ color: 'primary.main' }}
                  >
                    Read More
                  </Button>
                  {userRole === 'ADMIN' && (
                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setCurrentItem(policy);
                          setFormData({
                            ...formData,
                            title: policy.title,
                            content: policy.content,
                            category: policy.category?.id || '',
                            attachments: []
                          });
                          setPolicyDialogOpen(true);
                        }}
                        sx={{ color: 'white', mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(policy, 'policy')}
                        sx={{ color: 'white' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </PolicyCard>
          </Grid>
        );
      })}
    </Grid>
  );
};

return (
  <Box sx={{ p: 3 }}>
    <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
      {userRole === 'ADMIN' ? 'Policy Management' : 'Company Policies'}
    </Typography>
    
    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

    {userRole === 'ADMIN' && (
      <Tabs 
        value={selectedTab} 
        onChange={(e, v) => setSelectedTab(v)} 
        sx={{ 
          mb: 3,
          '& .MuiTab-root': { color: 'white' },
          '& .Mui-selected': { color: 'primary.main' }
        }}
      >
        <Tab label="Categories" />
        <Tab label="Policies" />
      </Tabs>
    )}

    {selectedTab === 0 && userRole === 'ADMIN' && (
      <Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentItem(null);
            resetFormData();
            setCategoryDialogOpen(true);
          }}
          sx={{ mb: 2 }}
        >
          Add Category
        </Button>

        <List>
          {categories.map((category) => (
            <GlassContainer key={category.id}>
              <ListItem disablePadding>
                <ListItemText 
                  primary={category.name} 
                  secondary={category.description}
                  primaryTypographyProps={{ color: 'white' }}
                  secondaryTypographyProps={{ color: 'rgba(255,255,255,0.7)' }}
                />
                <ListItemSecondaryAction>
                  <IconButton 
                    edge="end" 
                    onClick={() => {
                      setCurrentItem(category);
                      setFormData({
                        ...formData,
                        name: category.name,
                        description: category.description
                      });
                      setCategoryDialogOpen(true);
                    }}
                    sx={{ color: 'white' }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    edge="end" 
                    onClick={() => handleDelete(category, 'category')}
                    sx={{ color: 'white' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </GlassContainer>
          ))}
        </List>

        <Dialog 
          open={categoryDialogOpen} 
          onClose={() => {
            setCategoryDialogOpen(false);
            resetFormData();
          }}
          PaperProps={{
            style: {
              background: 'rgba(18, 18, 18, 0.95)',
              backdropFilter: 'blur(10px)',
            }
          }}
        >
          <DialogTitle sx={{ color: 'white' }}>
            {currentItem ? 'Edit Category' : 'Add Category'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              label="Name"
              fullWidth
              value={formData.name}
              onChange={(e) => setFormData({ 
                ...formData, 
                name: e.target.value 
              })}
              sx={{
                '& .MuiInputBase-input': { color: 'white' },
                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                  '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' }
                }
              }}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={formData.description}
              onChange={(e) => setFormData({ 
                ...formData, 
                description: e.target.value 
              })}
              sx={{
                '& .MuiInputBase-input': { color: 'white' },
                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                  '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' }
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => {
                setCategoryDialogOpen(false);
                resetFormData();
              }} 
              sx={{ color: 'white' }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleCategorySave} 
              variant="contained"
              disabled={!formData.name}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )}

    {(selectedTab === 1 || userRole !== 'ADMIN') && (
      <Box>
        {userRole === 'ADMIN' && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setCurrentItem(null);
              resetFormData();
              setPolicyDialogOpen(true);
            }}
            sx={{ mb: 2 }}
          >
            Add Policy
          </Button>
        )}

        {renderPolicies()}

        <Dialog 
          open={policyDialogOpen} 
          onClose={() => {
            setPolicyDialogOpen(false);
            resetFormData();
          }}
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: {
              background: 'rgba(18, 18, 18, 0.95)',
              backdropFilter: 'blur(10px)',
            }
          }}
        >
          <DialogTitle sx={{ color: 'white' }}>
            {currentItem ? 'Edit Policy' : 'Add Policy'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Title"
              fullWidth
              required
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              sx={{
                '& .MuiInputBase-input': { color: 'white' },
                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                  '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' }
                }
              }}
            />
            <TextField
              select
              margin="dense"
              label="Category"
              fullWidth
              value={formData.category}
              onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              sx={{
                '& .MuiInputBase-input': { color: 'white' },
                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                  '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' }
                },
                '& .MuiMenuItem-root': { color: 'black' }
              }}
            >
              <MenuItem value="">
                <em>Select a category</em>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
            
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom sx={{ color: 'white' }}>
                Content
              </Typography>
              <StyledQuillEditor>
                <ReactQuill 
                  value={formData.content}
                  onChange={(content) => setFormData({ ...formData, content })}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                      ['link', 'image', 'video'],
                      ['clean']
                    ],
                  }}
                />
              </StyledQuillEditor>
            </Box>
            
            <Box sx={{ mt: 2 }}>
              <input
                accept="image/*,video/*,.pdf"
                style={{ display: 'none' }}
                id="attachment-button"
                multiple
                type="file"
                onChange={(e) => setFormData({ 
                  ...formData, 
                  attachments: [...(formData.attachments || []), ...e.target.files]
                })}
              />
              <label htmlFor="attachment-button">
                <Button 
                  variant="outlined" 
                  component="span"
                  sx={{ 
                    color: 'white',
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                    '&:hover': {
                      borderColor: 'rgba(255, 255, 255, 0.5)'
                    }
                  }}
                >
                  Add Attachments
                </Button>
              </label>
            </Box>

            {formData.attachments?.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'white', mb: 1 }}>
                  Selected Files:
                </Typography>
                <List>
                  {Array.from(formData.attachments).map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText 
                        primary={file.name}
                        sx={{ color: 'white' }}
                      />
                      <IconButton
                        onClick={() => setFormData({
                          ...formData,
                          attachments: Array.from(formData.attachments).filter((_, i) => i !== index)
                        })}
                        sx={{ color: 'white' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setPolicyDialogOpen(false);
              resetFormData();
            }} sx={{ color: 'white' }}>
              Cancel
            </Button>
            <Button 
              onClick={handlePolicySave} 
              variant="contained"
              disabled={!formData.title || !formData.content}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <PolicyViewDialog 
          open={viewDialogOpen}
          onClose={() => {
            setViewDialogOpen(false);
            setSelectedPolicy(null);
        }}
        policy={selectedPolicy}
        allPolicies={policies}
        onPolicySelect={(newPolicy) => {
          setSelectedPolicy(newPolicy);
        }}
      />
    </Box>
  )}
</Box>
);
};

export default PolicyManagementPage;