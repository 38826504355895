// VocabBlocksModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Space, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { listVocabBlocks } from '../api';

const VocabBlocksModal = ({ isOpen, onClose, onEdit, onDelete }) => {
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBlocks = async () => {
    setLoading(true);
    try {
      const response = await listVocabBlocks();
      // Transform the data for table display
      const transformedBlocks = response.data.map(block => ({
        key: block.id,
        order: block.order,
        words: block.words.map(w => w.word).join(', '),
        created_at: new Date(block.created_at).toLocaleDateString(),
        id: block.id
      }));
      setBlocks(transformedBlocks);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch vocabulary blocks'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchBlocks();
    }
  }, [isOpen]);

  const columns = [
    {
      title: 'Block Order',
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order
    },
    {
      title: 'Words',
      dataIndex: 'words',
      key: 'words',
      ellipsis: true
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => onEdit(record.id)}
          >
            Edit
          </Button>
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      )
    }
  ];

  return (
    <Modal
      title="Vocabulary Blocks"
      visible={isOpen}
      onCancel={onClose}
      width={1000}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>
      ]}
    >
      <Table
        columns={columns}
        dataSource={blocks}
        loading={loading}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} blocks`
        }}
        expandable={{
          expandedRowRender: (record) => {
            const block = blocks.find(b => b.id === record.id);
            return (
              <div className="p-4">
                <h4 className="text-lg font-semibold mb-2">Words in this block:</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {record.words.split(', ').map((word, index) => (
                    <div key={index} className="p-2 bg-gray-50 rounded">
                      {word}
                    </div>
                  ))}
                </div>
              </div>
            );
          }
        }}
      />
    </Modal>
  );
};

export default VocabBlocksModal;