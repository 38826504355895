// src/components/TextInput.js
import React, { useState } from 'react';
import '../styles/Chat.css';

const TextInput = ({ onSubmit, isLoading, initialValue = '', disabled = false }) => {
  const [input, setInput] = useState(initialValue);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isLoading && !disabled && input.trim()) {
      onSubmit(input);
      setInput('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="text-input-form">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder={disabled ? "Daily message limit reached, come back tomorrow" : "Type your message..."}
        disabled={isLoading || disabled}
        className="text-input"
      />
      <button 
        type="submit" 
        disabled={isLoading || disabled || !input.trim()}
        className="send-button"
      >
        Send
      </button>
    </form>
  );
};

export default TextInput;