import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import { Clipboard } from 'lucide-react';

const TrainerAuditNotificationModal = ({ open, onClose, notifications, onMarkRead }) => {
  const navigate = useNavigate();

  const handleViewAudit = () => {
    // Mark all audit notifications as read
    notifications.forEach(notification => {
      if (!notification.is_read) {
        onMarkRead(notification.id);
      }
    });
    onClose();
    
    // Always navigate to employment page
    navigate('/my-employment');
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          background: 'rgba(17, 25, 40, 0.95)',
          backdropFilter: 'blur(20px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '12px',
          maxWidth: '500px',
          width: '90%'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <Clipboard size={24} />
        <Typography variant="h6" component="span">
          New Trainer Audit Notifications
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        {notifications.map((notification) => (
          <Box 
            key={notification.id} 
            sx={{ 
              mb: 2,
              p: 2,
              borderRadius: '8px',
              background: 'rgba(255, 255, 255, 0.05)',
              '&:last-child': { mb: 0 }
            }}
          >
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {notification.message}
            </Typography>
            <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              {new Date(notification.created_at).toLocaleString()}
            </Typography>
          </Box>
        ))}
      </DialogContent>
      <DialogActions sx={{ 
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        px: 3,
        py: 2
      }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{ color: 'white', borderColor: 'rgba(255, 255, 255, 0.3)' }}
        >
          Close
        </Button>
        <Button 
          onClick={handleViewAudit}
          variant="contained"
          sx={{ 
            bgcolor: '#3b82f6',
            '&:hover': { bgcolor: '#2563eb' }
          }}
        >
          View Audit Details
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrainerAuditNotificationModal;