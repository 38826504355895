import React, { useState, useEffect } from 'react';
import { submitWritingAssessment } from '../api';
import './WritingAssessment.css';

const WritingAssessment = ({ words, onComplete }) => {
  const [paragraph, setParagraph] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState(null);
  const [assessment, setAssessment] = useState(null);

  useEffect(() => {
    let timeouts = [];
    
    if (loading) {
      setLoadingMessage('Wait while we analyse your writing');
      
      timeouts.push(setTimeout(() => {
        setLoadingMessage('Checking grammar and sentence structures');
      }, 500));
      
      timeouts.push(setTimeout(() => {
        setLoadingMessage('Preparing report');
      }, 1000));
    }

    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, [loading]);

  const handleSubmit = async () => {
    if (!paragraph.trim()) {
      setError('Please write a paragraph using the vocabulary words.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await submitWritingAssessment({
        writing_content: paragraph,
        target_vocabulary: words.map(w => w.word)
      });

      setAssessment(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to submit writing assessment. Please try again.');
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  };

  return (
    <div className="writing-assessment-container">
      <div className="writing-prompt">
        <h2 className="writing-title">Writing Practice</h2>
        <p className="writing-instruction">
          Write a paragraph using all of these vocabulary words:
        </p>
        <div className="vocab-words">
          {words.map((word, index) => (
            <span key={index} className="vocab-word">
              {word.word}
            </span>
          ))}
        </div>
      </div>

      <textarea
        value={paragraph}
        onChange={(e) => setParagraph(e.target.value)}
        placeholder="Write your paragraph here..."
        className="paragraph-input"
        disabled={loading}
      />

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {loading && (
        <div className="loading-indicator">
          {loadingMessage}
        </div>
      )}

      {assessment && (
        <div className="assessment-results">
          <div className="assessment-score">
            Score: {assessment.overall_score}/100
          </div>

          <div className="feedback-section">
            <h3>Vocabulary Usage</h3>
            {assessment.vocabulary_usage.map((item, index) => (
              <div key={index} className="feedback-item">
                <div className={`word-usage ${item.used_correctly ? 'correct' : 'incorrect'}`}>
                  {item.word}: {item.used_correctly ? '✓ Used correctly' : '× Needs improvement'}
                </div>
                {item.suggestions && (
                  <div className="suggestion">
                    {item.suggestions}
                  </div>
                )}
              </div>
            ))}
          </div>

          {assessment.grammar_feedback.length > 0 && (
            <div className="feedback-section">
              <h3>Grammar Feedback</h3>
              {assessment.grammar_feedback.map((feedback, index) => (
                <div key={index} className="feedback-item">
                  {feedback.error && (
                    <>
                      <div className="error-text">{feedback.error}</div>
                      {feedback.correction && (
                        <div className="correction-text">
                          Suggestion: {feedback.correction}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          <div className="feedback-section">
            <h3>Writing Improvements</h3>
            {assessment.writing_improvements?.map((improvement, index) => (
              <div key={index} className="feedback-item">
                <div className="improvement-text">
                  <strong>{improvement.aspect}:</strong> {improvement.suggestion}
                </div>
                {improvement.example && (
                  <div className="example-text">
                    Example: {improvement.example}
                  </div>
                )}
              </div>
            ))}
          </div>

          <button
            onClick={() => onComplete({ paragraph, assessment })}
            className="submit-button"
          >
            Continue to Chat
          </button>
        </div>
      )}

      {!assessment && (
        <button
          onClick={handleSubmit}
          disabled={loading || !paragraph.trim()}
          className="submit-button"
        >
          {loading ? 'Analyzing...' : 'Submit Writing'}
        </button>
      )}
    </div>
  );
};

export default WritingAssessment;