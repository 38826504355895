const splitTextForTTS = (text, maxLength = 250) => {
    if (!text || typeof text !== 'string') {
      return [];
    }
  
    // If text is shorter than maxLength, return it as a single chunk
    if (text.length <= maxLength) {
      return [text];
    }
  
    const chunks = [];
    let currentText = text;
  
    while (currentText.length > 0) {
      let chunk;
      
      if (currentText.length <= maxLength) {
        chunks.push(currentText);
        break;
      }
  
      // Find the last sentence break within maxLength
      let lastSentenceBreak = -1;
      for (let i = 0; i < Math.min(currentText.length, maxLength); i++) {
        if (currentText[i] === '.' || currentText[i] === '!' || currentText[i] === '?') {
          lastSentenceBreak = i + 1;
        }
      }
  
      // If no sentence break found, look for last space before maxLength
      if (lastSentenceBreak === -1) {
        lastSentenceBreak = currentText.lastIndexOf(' ', maxLength);
      }
  
      // If no space found, force break at maxLength
      if (lastSentenceBreak === -1) {
        lastSentenceBreak = maxLength;
      }
  
      chunk = currentText.substring(0, lastSentenceBreak).trim();
      if (chunk) {
        chunks.push(chunk);
      }
      
      currentText = currentText.substring(lastSentenceBreak).trim();
    }
  
    return chunks.filter(chunk => chunk.length > 0);
  };
  
  const processTextToSpeech = async (text, ttsService) => {
    const chunks = splitTextForTTS(text);
    
    for (const chunk of chunks) {
      try {
        await ttsService.convertTextToSpeech(chunk);
        // Optional pause between chunks for better speech flow
        await new Promise(resolve => setTimeout(resolve, 100));
      } catch (error) {
        console.error('TTS error for chunk:', chunk, error);
        throw error;
      }
    }
  };
  
  export { splitTextForTTS, processTextToSpeech };