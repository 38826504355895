import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Link,
  IconButton,
  Box,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Launch as LaunchIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import StudentDetailsModal from './StudentDetailsModal';

// Styled button component
const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '8px 16px',
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const EnhancedAttendanceReviewTable = ({ attendanceReviews, handleProcessReview }) => {
  const [selectedStudent, setSelectedStudent] = useState(null);
  
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Review ID</TableCell>
            <TableCell>Attendance ID</TableCell>
            <TableCell>Student</TableCell>
            <TableCell>Trainer</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Current Status</TableCell>
            <TableCell>Remark</TableCell>
            <TableCell>Review Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attendanceReviews.map((review) => (
            <TableRow key={review.id}>
              <TableCell>{review.id}</TableCell>
              <TableCell>{review.attendance.id}</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Link
                    component="button"
                    onClick={() => setSelectedStudent(review.attendance.student_id)}
                    sx={{
                      color: 'white',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      },
                      cursor: 'pointer'
                    }}
                  >
                    {review.attendance.student}
                  </Link>
                  <IconButton
                    size="small"
                    onClick={() => setSelectedStudent(review.attendance.student_id)}
                    sx={{ 
                      color: 'white',
                      padding: '4px',
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.1)'
                      }
                    }}
                  >
                    <LaunchIcon fontSize="small" />
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell>{review.trainerName}</TableCell>
              <TableCell>{dayjs(review.attendance.date).format('MMMM D, YYYY')}</TableCell>
              <TableCell>{review.attendance.status}</TableCell>
              <TableCell>{review.remark}</TableCell>
              <TableCell>{review.status}</TableCell>
              <TableCell>
                {review.status === 'PENDING' && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <GradientButton 
                      onClick={() => handleProcessReview(review.id, 'APPROVED')} 
                      size="small"
                    >
                      Approve
                    </GradientButton>
                    <GradientButton 
                      onClick={() => handleProcessReview(review.id, 'REJECTED')} 
                      size="small"
                    >
                      Reject
                    </GradientButton>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <StudentDetailsModal
        open={Boolean(selectedStudent)}
        onClose={() => setSelectedStudent(null)}
        studentId={selectedStudent}
      />
    </>
  );
};

export default EnhancedAttendanceReviewTable;