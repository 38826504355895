import React from 'react';
import { 
  Typography, 
  Paper, 
  Box, 
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Grid,
  Button,
  Tooltip
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Person as PersonIcon,
  Warning as WarningIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';

const formatTime = (time24) => {
  return dayjs(time24, 'HH:mm').format('h:mm A');
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = dayjs(dateString);
  return date.isValid() ? date.format('D-MMM-YYYY').toLowerCase() : 'Invalid Date';
};

const GroupedSuggestions = ({ suggestions, expandedTrainer, handleTrainerClick, onAssignTrainer, lead }) => {
  // Group trainers
  const groupedTrainers = suggestions.reduce((acc, suggestion) => {
    const warningCount = suggestion.warnings?.length || 0;
    if (warningCount === 0) {
      acc.noWarnings.push(suggestion);
    } else if (warningCount <= 2) {
      acc.someWarnings.push(suggestion);
    } else {
      acc.criticalWarnings.push(suggestion);
    }
    return acc;
  }, {
    noWarnings: [],
    someWarnings: [],
    criticalWarnings: []
  });

  // Render a group of trainers
  const renderTrainerGroup = (trainers, title) => {
    if (trainers.length === 0) return null;

    return (
      <Box mb={3}>
        <Typography variant="h6" gutterBottom style={{ color: '#fff', marginTop: '16px' }}>
          {title} ({trainers.length})
        </Typography>
        {trainers.map((suggestion) => (
          <Paper 
            key={suggestion.trainer.id} 
            elevation={3} 
            style={{ marginBottom: '16px', overflow: 'hidden', backgroundColor: '#181818' }}
          >
            <ListItem 
              button 
              onClick={() => handleTrainerClick(suggestion.trainer.id)}
              style={{ backgroundColor: '#181818' }}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography>
                      {`${suggestion.trainer.first_name} ${suggestion.trainer.last_name}`}
                    </Typography>
                    {suggestion.warnings && suggestion.warnings.length > 0 && (
                      <Alert 
                        severity="warning" 
                        style={{ 
                          marginLeft: '16px',
                          padding: '0 8px',
                          height: '32px'
                        }}
                      >
                        {suggestion.warnings.length} Warning{suggestion.warnings.length > 1 ? 's' : ''}
                      </Alert>
                    )}
                  </Box>
                }
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textSecondary">
                      Available: {suggestion.availability?.length || 0} day(s)
                    </Typography>
                    <br />
                    {suggestion.hours && (
                      <Typography component="span" variant="body2" color="textSecondary">
                        Hours: {suggestion.hours.occupied}/{suggestion.hours.approved} (Occupied/Approved)
                      </Typography>
                    )}
                  </>
                }
              />
              {expandedTrainer === suggestion.trainer.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </ListItem>
            <Collapse in={expandedTrainer === suggestion.trainer.id} timeout="auto" unmountOnExit>
              <Box padding={2}>
                {suggestion.warnings && suggestion.warnings.length > 0 && (
                  <Box mb={2}>
                    <Typography variant="subtitle2" gutterBottom color="warning.main">
                      Warnings:
                    </Typography>
                    <List dense>
                      {suggestion.warnings.map((warning, index) => (
                        <ListItem key={index}>
                          <Alert severity="warning" style={{ width: '100%' }}>
                            {warning}
                          </Alert>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
                <Grid container spacing={2}>
                  {suggestion.availability?.map((slot, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Paper elevation={1} style={{ padding: '8px', height: '100%' }}>
                        <Typography variant="subtitle2" gutterBottom>
                          {formatDate(slot.date)}
                        </Typography>
                        <Typography variant="body2">
                          {formatTime(slot.slots[0])}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={() => onAssignTrainer(lead, suggestion.trainer)}
                  style={{ marginTop: '16px' }}
                  endIcon={suggestion.warnings && suggestion.warnings.length > 0 ? 
                    <Tooltip title="Assignment has warnings">
                      <WarningIcon color="warning" />
                    </Tooltip> : null
                  }
                >
                  Assign {suggestion.warnings && suggestion.warnings.length > 0 ? '(with warnings)' : ''}
                </Button>
              </Box>
            </Collapse>
          </Paper>
        ))}
      </Box>
    );
  };

  return (
    <List>
      {renderTrainerGroup(groupedTrainers.noWarnings, "Recommended Trainers (No Warnings)")}
      {renderTrainerGroup(groupedTrainers.someWarnings, "Trainers with Minor Warnings")}
      {renderTrainerGroup(groupedTrainers.criticalWarnings, "Trainers with Critical Warnings")}
    </List>
  );
};

export default GroupedSuggestions;