import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';

const CollapsibleMessage = ({ message }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasDetails = message.corrections?.length > 0 || 
                    message.learning_points?.length > 0 || 
                    (message.follow_up_questions?.length > 1);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderCorrections = () => {
    if (!message.corrections?.length) return null;
    
    return (
      <div className="feedback-section">
        <h4>Language Corrections:</h4>
        <ul className="space-y-2">
          {message.corrections.map((correction, index) => (
            <li key={index}>
              <div className="text-gray-400">
                <span className="px-2 py-0.5 bg-gray-700 rounded text-xs">
                  {correction.type}
                </span>
              </div>
              <p className="mt-1 text-gray-400">
                Original: <span className="text-red-400">"{correction.original}"</span>
              </p>
              <p className="text-gray-400">
                Correction: <span className="text-green-400">"{correction.correction}"</span>
              </p>
              <p className="mt-1 text-gray-300">{correction.explanation}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderLearningPoints = () => {
    if (!message.learning_points?.length) return null;

    return (
      <div className="feedback-section">
        <h4>Learning Points:</h4>
        <ul className="space-y-2">
          {message.learning_points.map((point, index) => (
            <li key={index}>
              <h5 className="font-medium text-gray-300">{point.topic}</h5>
              <p className="text-gray-400 mt-1">{point.explanation}</p>
              <div className="mt-1 text-gray-400">
                Example: <span className="text-blue-400">"{point.example}"</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderFollowUpQuestions = () => {
    if (!message.follow_up_questions?.length) return null;

    const remainingQuestions = message.follow_up_questions.slice(1);
    if (remainingQuestions.length === 0) return null;

    return (
      <div className="feedback-section">
        <h4>More Follow-up Questions:</h4>
        <ul className="space-y-1">
          {remainingQuestions.map((question, index) => (
            <li key={index} className="text-gray-400">
              {question}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-2 w-full">
      <div className="message bot">
        <div className="message-content">
          <p>{message.text}</p>
          
          {/* Show first follow-up question inline */}
          {message.follow_up_questions?.[0] && (
            <>
              <div className="follow-up-divider" />
              <p>{message.follow_up_questions[0]}</p>
            </>
          )}
          
          {hasDetails && (
            <button
              onClick={toggleExpand}
              className="feedback-button"
            >
              <AlertCircle size={16} />
              {isExpanded ? 'Hide Feedback' : 'Show Feedback'}
            </button>
          )}

          <span className="timestamp">
            {new Date(message.timestamp).toLocaleTimeString()}
          </span>
        </div>
      </div>

      {isExpanded && (
        <div className="feedback-expanded ml-4">
          {renderCorrections()}
          {renderLearningPoints()}
          {renderFollowUpQuestions()}
        </div>
      )}
    </div>
  );
};

export default CollapsibleMessage;