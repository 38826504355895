import React, { useState, useEffect } from 'react';
import { 
  createVocabBlock, 
  listVocabBlocks, 
  updateVocabBlock, 
  deleteVocabBlock,
  reorderVocabBlocks 
} from '../api';
import './VocabAdmin.css';
import VocabModal from './VocabBlocksModal';
import { notification, Modal, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Parts of speech options from original file
const PARTS_OF_SPEECH = [
  { value: 'NOUN', label: 'Noun' },
  { value: 'VERB', label: 'Verb' },
  { value: 'ADJ', label: 'Adjective' },
  { value: 'ADV', label: 'Adverb' },
  { value: 'PREP', label: 'Preposition' },
  { value: 'CONJ', label: 'Conjunction' },
  { value: 'PRON', label: 'Pronoun' },
  { value: 'INTERJ', label: 'Interjection' }
];

const VocabAdmin = () => {
  // State management
  const [words, setWords] = useState([
    { word: '', part_of_speech: '', hindi_definition: '', english_definition: '', example1: '', example2: '', example3: '', imageUrl: '', videoUrl: '' },
    { word: '', part_of_speech: '', hindi_definition: '', english_definition: '', example1: '', example2: '', example3: '', imageUrl: '', videoUrl: '' },
    { word: '', part_of_speech: '', hindi_definition: '', english_definition: '', example1: '', example2: '', example3: '', imageUrl: '', videoUrl: '' },
    { word: '', part_of_speech: '', hindi_definition: '', english_definition: '', example1: '', example2: '', example3: '', imageUrl: '', videoUrl: '' },
    { word: '', part_of_speech: '', hindi_definition: '', english_definition: '', example1: '', example2: '', example3: '', imageUrl: '', videoUrl: '' }
  ]);
  
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isReorderModalVisible, setIsReorderModalVisible] = useState(false);
  const [nextOrder, setNextOrder] = useState(1);

  // Fetch blocks on mount and after operations
  useEffect(() => {
    fetchBlocks();
  }, [success]);

  const fetchBlocks = async () => {
    try {
      const response = await listVocabBlocks();
      setBlocks(response.data);
      
      if (response.data.length > 0) {
        const maxOrder = Math.max(...response.data.map(block => block.order));
        setNextOrder(maxOrder + 1);
      }
    } catch (err) {
      console.error('Error fetching blocks:', err);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch vocabulary blocks'
      });
    }
  };

  // Word handling
  const handleWordChange = (index, field, value) => {
    const newWords = [...words];
    newWords[index] = { ...newWords[index], [field]: value };
    setWords(newWords);
  };

  // Validation
  const validateWords = () => {
    let isValid = true;
    let errorMessage = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (!word.word.trim()) {
        errorMessage = `Word ${i + 1} is empty`;
        isValid = false;
        break;
      }
      if (!word.part_of_speech) {
        errorMessage = `Part of speech for word ${i + 1} is not selected`;
        isValid = false;
        break;
      }
      if (!word.hindi_definition.trim()) {
        errorMessage = `Hindi definition for word ${i + 1} is empty`;
        isValid = false;
        break;
      }
      if (!word.english_definition.trim()) {
        errorMessage = `English definition for word ${i + 1} is empty`;
        isValid = false;
        break;
      }
      if (!word.example1.trim() || !word.example2.trim() || !word.example3.trim()) {
        errorMessage = `Please provide all three examples for word ${i + 1}`;
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      setError(errorMessage);
    } else {
      setError(null);
    }

    return isValid;
  };

  // Submit handler for both create and edit
  const handleSubmit = async () => {
    if (!validateWords()) return;

    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      if (selectedBlock) {
        // Update existing block
        await updateVocabBlock(selectedBlock.id, {
          order: selectedBlock.order,
          words: words.map(word => ({
            ...word,
            word: word.word.trim(),
            hindi_definition: word.hindi_definition.trim(),
            english_definition: word.english_definition.trim(),
            example1: word.example1.trim(),
            example2: word.example2.trim(),
            example3: word.example3.trim()
          }))
        });
        setIsEditModalVisible(false);
      } else {
        // Create new block
        await createVocabBlock({
          order: nextOrder,
          words: words.map(word => ({
            ...word,
            word: word.word.trim(),
            hindi_definition: word.hindi_definition.trim(),
            english_definition: word.english_definition.trim(),
            example1: word.example1.trim(),
            example2: word.example2.trim(),
            example3: word.example3.trim()
          }))
        });
      }

      setSuccess(true);
      resetForm();
      fetchBlocks();
      
      notification.success({
        message: 'Success',
        description: `Block ${selectedBlock ? 'updated' : 'created'} successfully`
      });
    } catch (err) {
      setError(err.response?.data?.error || err.message);
      notification.error({
        message: 'Error',
        description: `Failed to ${selectedBlock ? 'update' : 'create'} block`
      });
    } finally {
      setLoading(false);
    }
  };

  // Form reset
  const resetForm = () => {
    setWords(words.map(() => ({
      word: '',
      part_of_speech: '',
      hindi_definition: '',
      english_definition: '',
      example1: '',
      example2: '',
      example3: '',
      imageUrl: '',
      videoUrl: ''
    })));
    setSelectedBlock(null);
  };

  // Edit handler
  const handleEdit = async (blockId) => {
    try {
      setLoading(true);
      const block = blocks.find(b => b.id === blockId);
      setSelectedBlock(block);
      setWords(block.words.map(w => ({
        ...w,
        imageUrl: w.image_url,
        videoUrl: w.video_url
      })));
      setIsEditModalVisible(true);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Failed to load block for editing'
      });
    } finally {
      setLoading(false);
    }
  };

  // Delete handler
  const handleDelete = async (blockId) => {
    try {
      setLoading(true);
      await deleteVocabBlock(blockId);
      notification.success({
        message: 'Success',
        description: 'Block deleted successfully'
      });
      fetchBlocks();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Failed to delete block'
      });
    } finally {
      setLoading(false);
    }
  };

  // Reorder handler
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    try {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      if (sourceIndex === destIndex) return;

      const sourceBlock = blocks[sourceIndex];
      const targetBlock = blocks[destIndex];

      await reorderVocabBlocks({
        source_block_id: sourceBlock.id,
        target_block_id: targetBlock.id
      });

      fetchBlocks();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Failed to reorder blocks'
      });
    }
  };

  // Reorder Modal Component
  const ReorderModal = () => (
    <Modal
      title="Reorder Vocabulary Blocks"
      visible={isReorderModalVisible}
      onCancel={() => setIsReorderModalVisible(false)}
      footer={null}
      width={800}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="blocks">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {blocks.map((block, index) => (
                <Draggable key={block.id} draggableId={`block-${block.id}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="reorder-item p-4 mb-2 bg-gray-100 rounded shadow"
                    >
                      Block {block.order}: First word - {block.words[0]?.word}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );

  return (
    <div className="vocab-admin-container">
      <h2 className="vocab-admin-title">Create Vocabulary Block #{nextOrder}</h2>

      <div className="flex justify-between mb-4">
        <button 
          className="view-blocks-button bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          onClick={() => setIsModalOpen(true)}
        >
          View All Vocabulary Blocks
        </button>

        <Button 
          type="primary"
          onClick={() => setIsReorderModalVisible(true)}
          className="ml-2"
        >
          Reorder Blocks
        </Button>
      </div>

      <VocabModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        onEdit={handleEdit}
        onDelete={(blockId) => {
          setSelectedBlock(blocks.find(b => b.id === blockId));
          setIsDeleteModalVisible(true);
        }}
      />

      {words.map((word, index) => (
        <div key={index} className="vocab-word-card">
          <h3 className="vocab-word-title">Word {index + 1}</h3>
          <div className="vocab-word-grid">
            {/* Word input */}
            <div className="vocab-input-group">
              <label>Word</label>
              <input
                type="text"
                value={word.word}
                onChange={(e) => handleWordChange(index, 'word', e.target.value)}
                placeholder="Enter word"
                className="form-input"
              />
            </div>
            
            {/* Part of Speech select */}
            <div className="vocab-input-group">
              <label>Part of Speech</label>
              <select
                value={word.part_of_speech}
                onChange={(e) => handleWordChange(index, 'part_of_speech', e.target.value)}
                className="form-select"
              >
                <option value="">Select part of speech</option>
                {PARTS_OF_SPEECH.map(pos => (
                  <option key={pos.value} value={pos.value}>
                    {pos.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Definitions */}
            <div className="vocab-input-group">
              <label>Hindi Definition</label>
              <input
                type="text"
                value={word.hindi_definition}
                onChange={(e) => handleWordChange(index, 'hindi_definition', e.target.value)}
                placeholder="Hindi meaning"
                className="form-input"
              />
            </div>

            <div className="vocab-input-group">
              <label>English Definition</label>
              <input
                type="text"
                value={word.english_definition}
                onChange={(e) => handleWordChange(index, 'english_definition', e.target.value)}
                placeholder="English meaning"
                className="form-input"
              />
            </div>

            {/* URLs */}
            <div className="vocab-input-group">
              <label>Image URL</label>
              <input
                type="url"
                value={word.imageUrl}
                onChange={(e) => handleWordChange(index, 'imageUrl', e.target.value)}
                placeholder="Enter image URL"
                className="form-input"
              />
            </div>

            <div className="vocab-input-group">
              <label>Video URL</label>
              <input
                type="url"
                value={word.videoUrl}
                onChange={(e) => handleWordChange(index, 'videoUrl', e.target.value)}
                placeholder="Enter video URL"
                className="form-input"
              />
            </div>

            {/* Examples */}
            <div className="vocab-input-group full-width">
              <label>Example 1</label>
              <input
                type="text"
                value={word.example1}
                onChange={(e) => handleWordChange(index, 'example1', e.target.value)}
                placeholder="First example sentence"
                className="form-input"
              />
            </div>

            <div className="vocab-input-group full-width">
              <label>Example 2</label>
              <input
                type="text"
                value={word.example2}
                onChange={(e) => handleWordChange(index, 'example2', e.target.value)}
                placeholder="Second example sentence"
                className="form-input"
              />
            </div>

            <div className="vocab-input-group full-width">
              <label>Example 3</label>
              <input
                type="text"
                value={word.example3}
                onChange={(e) => handleWordChange(index, 'example3', e.target.value)}
                placeholder="Third example sentence"
                className="form-input"
              />
            </div>
          </div>
        </div>
      ))}

      {error && (
        <div className="vocab-admin-error" role="alert">
          {error}
        </div>
      )}

      {success && (
        <div className="vocab-admin-success" role="alert">
          {selectedBlock ? 'Vocabulary block updated successfully!' : 'Vocabulary block created successfully!'}
        </div>
      )}

      <button
        className="vocab-admin-submit"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? (selectedBlock ? 'Updating Block...' : 'Creating Block...') 
          : (selectedBlock ? 'Update Vocabulary Block' : 'Create Vocabulary Block')}
      </button>

      {/* Edit Modal */}
      <Modal
        title="Edit Vocabulary Block"
        visible={isEditModalVisible}
        onOk={handleSubmit}
        onCancel={() => {
          setIsEditModalVisible(false);
          resetForm();
        }}
        confirmLoading={loading}
        width={1000}
      >
        <div className="edit-modal-content">
          {words.map((word, index) => (
            <div key={index} className="vocab-word-card">
              <h3 className="vocab-word-title">Word {index + 1}</h3>
              <div className="vocab-word-grid">
                <div className="vocab-input-group">
                  <label>Word</label>
                  <input
                    type="text"
                    value={word.word}
                    onChange={(e) => handleWordChange(index, 'word', e.target.value)}
                    placeholder="Enter word"
                    className="form-input"
                  />
                </div>
                
                <div className="vocab-input-group">
                  <label>Part of Speech</label>
                  <select
                    value={word.part_of_speech}
                    onChange={(e) => handleWordChange(index, 'part_of_speech', e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select part of speech</option>
                    {PARTS_OF_SPEECH.map(pos => (
                      <option key={pos.value} value={pos.value}>
                        {pos.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="vocab-input-group">
                  <label>Hindi Definition</label>
                  <input
                    type="text"
                    value={word.hindi_definition}
                    onChange={(e) => handleWordChange(index, 'hindi_definition', e.target.value)}
                    placeholder="Hindi meaning"
                    className="form-input"
                  />
                </div>

                <div className="vocab-input-group">
                  <label>English Definition</label>
                  <input
                    type="text"
                    value={word.english_definition}
                    onChange={(e) => handleWordChange(index, 'english_definition', e.target.value)}
                    placeholder="English meaning"
                    className="form-input"
                  />
                </div>

                <div className="vocab-input-group">
                  <label>Image URL</label>
                  <input
                    type="url"
                    value={word.imageUrl}
                    onChange={(e) => handleWordChange(index, 'imageUrl', e.target.value)}
                    placeholder="Enter image URL"
                    className="form-input"
                  />
                </div>

                <div className="vocab-input-group">
                  <label>Video URL</label>
                  <input
                    type="url"
                    value={word.videoUrl}
                    onChange={(e) => handleWordChange(index, 'videoUrl', e.target.value)}
                    placeholder="Enter video URL"
                    className="form-input"
                  />
                </div>

                <div className="vocab-input-group full-width">
                  <label>Example 1</label>
                  <input
                    type="text"
                    value={word.example1}
                    onChange={(e) => handleWordChange(index, 'example1', e.target.value)}
                    placeholder="First example sentence"
                    className="form-input"
                  />
                </div>

                <div className="vocab-input-group full-width">
                  <label>Example 2</label>
                  <input
                    type="text"
                    value={word.example2}
                    onChange={(e) => handleWordChange(index, 'example2', e.target.value)}
                    placeholder="Second example sentence"
                    className="form-input"
                  />
                </div>

                <div className="vocab-input-group full-width">
                  <label>Example 3</label>
                  <input
                    type="text"
                    value={word.example3}
                    onChange={(e) => handleWordChange(index, 'example3', e.target.value)}
                    placeholder="Third example sentence"
                    className="form-input"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Delete Vocabulary Block"
        visible={isDeleteModalVisible}
        onOk={() => {
          handleDelete(selectedBlock?.id);
          setIsDeleteModalVisible(false);
        }}
        onCancel={() => {
          setIsDeleteModalVisible(false);
          setSelectedBlock(null);
        }}
        confirmLoading={loading}
      >
        <p>Are you sure you want to delete this vocabulary block? This action cannot be undone.</p>
      </Modal>

      {/* Reorder Modal */}
      <ReorderModal />
    </div>
  );
};

export default VocabAdmin;