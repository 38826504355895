import React, { useState, useEffect } from 'react';
import { Typography, Box, Tooltip, Button, Paper } from '@mui/material';
import { keyframes, styled } from '@mui/system';

// Styled Components
const GlassCard = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '10px',
  padding: '16px',
  color: '#fff',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transform: 'translate3d(0, 0, 0)',
  willchange: 'backdrop-filter',
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: '#fff',
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 27, 107, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(255, 27, 107, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 27, 107, 0);
  }
`;

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-3px);
  }
  60% {
    transform: translateY(-1.5px);
  }
`;

const AnimatedLeaveStats = ({ dashboardData, leaveRequests, setLeaveRequestModalOpen }) => {
  const [isHovered, setIsHovered] = useState(false);
  const pendingRequests = leaveRequests.filter(request => request.status === 'PENDING').length;

  return (
    <GlassCard>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h6" gutterBottom color="white">
          Total Trainers
        </Typography>
        <Typography variant="h3" color="white">
          {dashboardData.total_trainers}
        </Typography>
        <Typography variant="subtitle2" color="white" mt={1}>
          Total Students: {dashboardData.total_students}
        </Typography>
        <Typography variant="subtitle2" color="white">
          Active Courses: {dashboardData.active_courses}
        </Typography>
        
        <Tooltip 
          title={pendingRequests > 0 
            ? `${pendingRequests} leave request${pendingRequests > 1 ? 's' : ''} pending review` 
            : ''}
          placement="right"
          arrow
          enterDelay={200}
          leaveDelay={200}
        >
          <Typography 
            variant="subtitle1" 
            color="white" 
            mt={2}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              cursor: pendingRequests > 0 ? 'pointer' : 'default',
              textDecoration: pendingRequests > 0 ? 'underline' : 'none',
              display: 'inline-flex',
              alignItems: 'center',
              transition: 'all 0.3s ease',
              transform: isHovered ? 'scale(1.05)' : 'scale(1)',
              '&:hover': {
                color: pendingRequests > 0 ? '#FF1B6B' : 'white',
              }
            }}
          >
            Leave Requests: {dashboardData.total_leave_requests}
            {pendingRequests > 0 && (
              <Box
                component="span"
                sx={{
                  backgroundColor: '#FF1B6B',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '8px',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  animation: `${pulseAnimation} 2s infinite, ${bounceAnimation} 2s infinite`,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  }
                }}
              >
                {pendingRequests}
              </Box>
            )}
          </Typography>
        </Tooltip>
        
        <GradientButton 
          onClick={() => setLeaveRequestModalOpen(true)} 
          sx={{ 
            mt: 2,
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
            }
          }}
        >
          View Leave Requests
        </GradientButton>
      </Box>
    </GlassCard>
  );
};

export default AnimatedLeaveStats;