import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { IconButton, Box } from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import ChatInterface from "../Chat/ChatInterface";

const Chatbot = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className="relative">
      {isMobile && (
        <Box 
          className="fixed top-0 left-0 z-50 w-full bg-gray-900 border-b border-gray-800"
          sx={{ 
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            px: 2
          }}
        >
          <div className="flex items-center gap-2">
            <IconButton
              onClick={() => navigate('/')}
              className="text-white"
              size="large"
            >
              <ArrowLeft className="h-6 w-6" />
            </IconButton>
            <span className="text-white text-base">Go back to Dashboard</span>
          </div>
        </Box>
      )}
      <Box sx={{ pt: isMobile ? '5px' : 0 }}>
        <ChatInterface />
      </Box>
    </div>
  );
};

export default Chatbot;