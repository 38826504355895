import React, { useState, useEffect } from 'react';
import '../styles/Chat.css';

const MessageLimitTracker = ({ onLimitReached = () => {} }) => {
  // Helper function to check if a date is today
  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  const [messageCount, setMessageCount] = useState(() => {
    // Initialize from localStorage, checking if it's from today
    const stored = localStorage.getItem('dailyMessageCount');
    const lastUpdate = localStorage.getItem('lastMessageUpdate');
    
    if (stored && lastUpdate && isToday(new Date(lastUpdate))) {
      return parseInt(stored, 10);
    }
    return 0;
  });

  // Reset counter at midnight
  useEffect(() => {
    const checkDate = () => {
      const lastUpdate = localStorage.getItem('lastMessageUpdate');
      if (lastUpdate && !isToday(new Date(lastUpdate))) {
        setMessageCount(0);
        localStorage.setItem('dailyMessageCount', '0');
        localStorage.setItem('lastMessageUpdate', new Date().toISOString());
      }
    };

    // Check every minute
    const interval = setInterval(checkDate, 60000);
    
    // Check on component mount
    checkDate();

    return () => clearInterval(interval);
  }, []);

  // Function to increment message count
  const incrementCount = () => {
    if (messageCount >= 15) {
      onLimitReached();
      return false;
    }
    
    const newCount = messageCount + 1;
    setMessageCount(newCount);
    localStorage.setItem('dailyMessageCount', newCount.toString());
    localStorage.setItem('lastMessageUpdate', new Date().toISOString());
    return true;
  };

  // Calculate remaining messages
  const remainingMessages = 15 - messageCount;

  return {
    messageCount,
    remainingMessages,
    incrementCount,
    MessageAlert: () => (
      <>
        {messageCount >= 10 && remainingMessages > 0 && (
          <div className="message-limit-alert warning">
            You have {remainingMessages} messages remaining today
          </div>
        )}
        {remainingMessages <= 0 && (
          <div className="message-limit-alert error">
            You have reached your daily message limit. Please wait until tomorrow.
          </div>
        )}
      </>
    ),
    MessageBadge: () => (
      messageCount >= 10 && remainingMessages > 0 ? (
        <div className="message-limit-badge">
          {remainingMessages} messages remaining
        </div>
      ) : null
    )
  };
};

export default MessageLimitTracker;