import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  CircularProgress,
  Avatar,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Tabs,
  Tab,
  Paper,
  ButtonGroup,
  DialogContentText,
  useMediaQuery,IconButton,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TrainerNotification from '../components/TrainerNotification';
import MessagePopupHandler from '../components/MessagePopupHandler';
import {
  getCurrentUser,
  listStudentCourses,
  changeAttendanceStatus,
  createAttendance,
  getAttendance,
  updateUserMe,
  createStudentFeedback,
  listStudentFeedbacks,
  createCourseHold,
  getStudyMaterialsForStudent,
  getStudentFeedbackHistory,
  getImageUrl,
  getCoordinator,
  getStudentAbsences, listNotices
} from '../api';
import NoticeCard from './NoticeCard';
import { Link } from 'react-router-dom';
import { Timeline as TimelineIcon } from '@mui/icons-material';
import webSocketService from '../services/apiws';
import { MessageIcon } from '@mui/icons-material';

import { formatDate } from '../utils/dateUtils';
import AttendanceCalendar from '../components/AttendanceCalendar';
import { Description as DescriptionIcon, Warning as WarningIcon } from '@mui/icons-material';
import ClassContentSection from '../components/ClassContentSection';
import Messaging from '../components/Messaging';

import { listAttendances } from '../api';
import DashboardCourseFlow from '../components/DashboardCourseFlow';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ChatbotNotification from '../components/ChatbotNotification';


// New styled components for glass effect and gradients
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '15px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
}));


const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
  '&:disabled': {
    background: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

const ProfileImage = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  margin: '0 auto 20px',
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: `0 0 20px ${theme.palette.primary.main}`,
}));

const ScrollableList = styled(List)({
  maxHeight: '300px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
});
dayjs.extend(utc);
dayjs.extend(timezone);
const StudentDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [studentData, setStudentData] = useState(null);
  const [coursesData, setCoursesData] = useState([]);
  const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);

  const [coordinatorData, setCoordinatorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [attendanceData, setAttendanceData] = useState({});
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackDetailsModalOpen, setFeedbackDetailsModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [feedbackType, setFeedbackType] = useState('GENERAL');
  const [feedbackTopic, setFeedbackTopic] = useState('');
  const [feedbackContent, setFeedbackContent] = useState('');
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [courseHoldModalOpen, setCourseHoldModalOpen] = useState(false);
  const [showAbsenceWarning, setShowAbsenceWarning] = useState(false);
  const [absenceWarningMessage, setAbsenceWarningMessage] = useState('');
  const [notices, setNotices] = useState([]);
  const [courseHoldData, setCourseHoldData] = useState({
    start_date: null,
    end_date: null,
    reason: '',
  });
  const [newProfileImage, setNewProfileImage] = useState(null);

  const getCurrentIST = useCallback(() => {
    return dayjs().tz('Asia/Kolkata');
  }, []);

  const defaultCourseIndex = useMemo(() => {
    if (!coursesData.length) return 0;
    
    // Find the first personal training course
    const personalCourseIndex = coursesData.findIndex(
      course => !course.course.is_group_class
    );
    
    // If a personal course exists, return its index, otherwise return 0
    return personalCourseIndex >= 0 ? personalCourseIndex : 0;
  }, [coursesData]);

  useEffect(() => {
    setSelectedCourseIndex(defaultCourseIndex);
  }, [defaultCourseIndex]);
  // Function to convert local date to IST date
  const getISTDate = useCallback((localDate) => {
    return dayjs(localDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
  }, []);

  const checkRecentAbsences = useCallback(async () => {
    if (!studentData) return;
  
    const endDate = dayjs().format('YYYY-MM-DD');
    const startDate = dayjs().subtract(30, 'days').format('YYYY-MM-DD');
  
    try {
      const response = await listAttendances({
        student: studentData.id,
        start_date: startDate,
        end_date: endDate,
      });
  
      const absences = response.data.filter(
        (attendance) => attendance.status === 'ABSENT'
      );
      const absenceCount = absences.length;
  
      if (absenceCount === 1) {
        setAbsenceWarningMessage(
          "You have already been marked absent once in the last 30 days. " +
            "Please make sure not to take any more leaves as it will impact your studies, " +
            "and more than 2 leaves will not be compensated."
        );
        setShowAbsenceWarning(true);
      } else if (absenceCount > 1) {
        setAbsenceWarningMessage(
          "You have been absent for more than 2 days now, and you will not be entitled to any more compensations. " +
            "Make sure to not take any more leaves to be able to complete your course on time.\n\n" +
            "आप अब 2 दिनों से ज़्यादा समय से अनुपस्थित हैं और आपको अब कोई अतिरिक्त क्लास नहीं मिलेगी। " +
            "सुनिश्चित करें कि आप समय पर अपना कोर्स पूरा करने के लिए अब और छुट्टी न लें।"
        );
        setShowAbsenceWarning(true);
      }
    } catch (error) {
      console.error('Error fetching recent attendance:', error);
    }
  }, [studentData]);
  
  useEffect(() => {
    if (studentData) checkRecentAbsences();
  }, [studentData, checkRecentAbsences]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [userResponse, coursesResponse, feedbacksResponse, feedbackHistoryResponse, noticesResponse ] = await Promise.all([
        getCurrentUser(),
        listStudentCourses({ student: studentData?.id }),
        listStudentFeedbacks(),
        getStudentFeedbackHistory(),
        listNotices(),
      ]);

      setStudentData(userResponse.data);
      setCoursesData(coursesResponse.data);
      setFeedbacks(feedbacksResponse.data);
      setFeedbackHistory(feedbackHistoryResponse.data);
      setNotices(noticesResponse.data);

      if (userResponse.data.coordinator) {
        const coordinatorResponse = await getCoordinator(userResponse.data.coordinator);
        setCoordinatorData(coordinatorResponse.data);
      } else {
        setCoordinatorData(null);
      }

      const materialsResponse = await getStudyMaterialsForStudent(userResponse.data.id);
      setStudyMaterials(materialsResponse.data);

      // Fetch attendance data for all courses
      const attendancePromises = coursesResponse.data.map(async (course) => {
        const istDate = getISTDate(new Date());
        const attendanceResponse = await getAttendance({
          student: userResponse.data.id,
          date: istDate,
          student_course: course.id
        });

        return {
          courseId: course.id,
          data: attendanceResponse.data[0] || null,
        };
      });
      
      const attendanceResults = await Promise.all(attendancePromises);
      const newAttendanceData = {};
      attendanceResults.forEach(result => {
        newAttendanceData[result.courseId] = result.data ? {
          marked: true,
          time: dayjs(result.data.timestamp).tz('Asia/Kolkata'),
          status: result.data.status,
          id: result.data.id
        } : {
          marked: false,
          time: null,
          status: null,
          id: null
        };
      });

      setAttendanceData(newAttendanceData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [studentData?.id, getISTDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const checkISTMidnight = () => {
      const istNow = getCurrentIST();
      if (istNow.hour() === 0 && istNow.minute() === 0) {
        setAttendanceData({});
      }
    };

    const timer = setInterval(checkISTMidnight, 60000); // Check every minute
    return () => clearInterval(timer);
  }, [getCurrentIST]);


  const hasIndividualCourses = useMemo(() => {
    return coursesData.some(course => !course.course.is_group_class);
  }, [coursesData]);

  const isAnyIndividualCourseAttendanceMarked = useMemo(() => {
    return coursesData.some(course => 
      !course.course.is_group_class && attendanceData[course.id]?.marked
    );
  }, [coursesData, attendanceData]);

  
  
  const shouldShowAttendanceButtons = useCallback((course) => {
    // If student has any individual courses
    const hasIndividualCourse = coursesData.some(c => !c.course.is_group_class);
    
    // If this is an individual course
    if (!course.course.is_group_class) {
      return true; // Always show attendance buttons for individual courses
    }
    
    // If this is a group course
    if (course.course.is_group_class) {
      // Only show if there are NO individual courses
      return !hasIndividualCourse;
    }
    
    return false;
  }, [coursesData]);

  const canJoinCourse = useCallback((course) => {
  const istNow = getCurrentIST();
  
  // Check if any individual course has marked attendance
  const hasIndividualCourseAttendance = coursesData.some(c => 
    !c.course.is_group_class && 
    attendanceData[c.id]?.marked &&
    dayjs(attendanceData[c.id].time).tz('Asia/Kolkata').format('YYYY-MM-DD') === istNow.format('YYYY-MM-DD')
  );
  
  // For individual courses
  if (!course.course.is_group_class) {
    const courseAttendance = attendanceData[course.id];
    if (!courseAttendance?.marked) return false;
    
    // Check if attendance was marked today
    const attendanceDate = dayjs(courseAttendance.time).tz('Asia/Kolkata').format('YYYY-MM-DD');
    const currentDate = istNow.format('YYYY-MM-DD');
    return attendanceDate === currentDate;
  }
  
  // For group courses
  if (course.course.is_group_class) {
    // If there are individual courses, allow joining if any individual course has attendance
    if (coursesData.some(c => !c.course.is_group_class)) {
      return hasIndividualCourseAttendance;
    }
    
    // If no individual courses, check group course attendance
    const courseAttendance = attendanceData[course.id];
    if (!courseAttendance?.marked) return false;
    
    const attendanceDate = dayjs(courseAttendance.time).tz('Asia/Kolkata').format('YYYY-MM-DD');
    const currentDate = istNow.format('YYYY-MM-DD');
    return attendanceDate === currentDate;
  }
  
  return false;
}, [coursesData, attendanceData, getCurrentIST]);


  const handleProfileUpdate = async () => {
    try {
      const formData = new FormData();
      Object.keys(updatedProfile).forEach(key => {
        formData.append(key, updatedProfile[key]);
      });
      if (newProfileImage) {
        formData.append('profile_image', newProfileImage);
      }
      const response = await updateUserMe(formData);
      setStudentData({ ...studentData, ...response.data });
      setEditMode(false);
      setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
    } catch (err) {
      console.error('Error updating profile:', err);
      setSnackbar({ open: true, message: 'Failed to update profile. Please try again.', severity: 'error' });
    }
  };

  const handleProfileImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewProfileImage(e.target.files[0]);
    }
  };

  const handleMaterialClick = (material) => {
    setSelectedMaterial(material);
  };

  const handleCloseMaterialDialog = () => {
    setSelectedMaterial(null);
  };

  const handleRequestCourseHold = () => {
    setCourseHoldModalOpen(true);
  };

  const handleCourseHoldInputChange = (field, value) => {
    setCourseHoldData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmitCourseHold = async () => {
    try {
      const formattedHoldData = {
        student_course: coursesData[selectedCourseIndex].id,
        start_date: dayjs(courseHoldData.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(courseHoldData.end_date).format('YYYY-MM-DD'),
        reason: courseHoldData.reason,
      };

      await createCourseHold(formattedHoldData);
      setSnackbar({ open: true, message: 'Course hold request submitted successfully', severity: 'success' });
      setCourseHoldModalOpen(false);
      setCourseHoldData({ start_date: null, end_date: null, reason: '' });
      fetchData();
    } catch (err) {
      console.error('Error submitting course hold request:', err);
      setSnackbar({ open: true, message: 'Failed to submit course hold request. Please try again.', severity: 'error' });
    }
  };

  const handleAttendanceMarking = async (status, courseId) => {
    try {
      const course = coursesData.find(c => c.id === courseId);
      const istDate = getISTDate(new Date());
      
      const attendancePayload = {
        student: studentData.id,
        course: course.course.id,
        student_course: courseId,
        status: status,
        trainer: course.trainer?.id || null,
        date: istDate, // Using IST date
      };
      
      const response = await createAttendance(attendancePayload);
      
      setAttendanceData(prev => ({
        ...prev,
        [courseId]: {
          marked: true,
          time: getCurrentIST(), // Using IST time
          status: status,
          id: response.data.id
        }
      }));
      
      setSnackbar({ open: true, message: 'Attendance marked successfully', severity: 'success' });
    } catch (err) {
      console.error('Error marking attendance:', err.response?.data || err.message);
      setSnackbar({ open: true, message: 'Failed to mark attendance. Please try again.', severity: 'error' });
    }
  };

  const handleAttendanceChange = async (newStatus, courseId) => {
    try {
      const currentAttendance = attendanceData[courseId];
      const istDate = getISTDate(new Date());
      
      let response;
      if (currentAttendance && currentAttendance.id) {
        response = await changeAttendanceStatus(currentAttendance.id, newStatus);
      } else {
        response = await createAttendance({
          student_course: courseId,
          status: newStatus,
          date: istDate, // Using IST date
        });
      }
  
      setAttendanceData(prev => ({
        ...prev,
        [courseId]: {
          marked: true,
          time: getCurrentIST(), // Using IST time
          status: newStatus,
          id: response.data.id
        }
      }));
  
      setSnackbar({ open: true, message: 'Attendance updated successfully', severity: 'success' });
    } catch (err) {
      console.error('Error updating attendance:', err.response?.data || err.message);
      if (err.response?.status === 400) {
        setSnackbar({ open: true, message: err.response.data.error || 'Failed to update attendance.', severity: 'error' });
      } else {
        setSnackbar({ open: true, message: 'An error occurred while updating attendance.', severity: 'error' });
      }
    }
  };

  const handleJoinRoom = (courseId) => {
    const course = coursesData.find(c => c.id === courseId);
    if (course?.bbb_join_url) {
      window.open(course.bbb_join_url, '_blank');
    } else {
      setSnackbar({ open: true, message: 'Join URL not available. Please try again later.', severity: 'error' });
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      await createStudentFeedback({
        student: studentData.id,
        course: coursesData[selectedCourseIndex].course.id,
        feedback_type: feedbackType,
        topic: feedbackTopic,
        content: feedbackContent,
      });
      setFeedbackModalOpen(false);
      setFeedbackType('GENERAL');
      setFeedbackTopic('');
      setFeedbackContent('');
      setSnackbar({ open: true, message: 'Feedback submitted successfully', severity: 'success' });
      fetchData();
    } catch (err) {
      console.error('Error submitting feedback:', err);
      setSnackbar({ open: true, message: 'Failed to submit feedback. Please try again.', severity: 'error' });
    }
  };

  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
    setFeedbackDetailsModalOpen(true);
  };

  const renderAttendanceButtons = (course) => {
    if (!shouldShowAttendanceButtons(course)) {
      return null;
    }
    
    const attendance = attendanceData[course.id];
    
    if (!attendance || !attendance.marked) {
      return (
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          <GradientButton onClick={() => handleAttendanceMarking('PRESENT', course.id)}>
            Mark Present
          </GradientButton>
          <GradientButton onClick={() => handleAttendanceMarking('TRAINER_ABSENT', course.id)}>
            Mark Trainer Absent
          </GradientButton>
        </Box>
      );
    }
    
    return (
      <Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="body2" mr={1}>
            Current Status:
          </Typography>
          <Chip 
            label={attendance.status}
            color={attendance.status === 'PRESENT' ? 'success' : 'warning'}
            size="small"
          />
        </Box>
        <Typography variant="body2" mb={1}>
          Marked at: {attendance.time?.format('HH:mm:ss')}
        </Typography>
        <Typography variant="body2" mb={1}>
          Update attendance:
        </Typography>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          <GradientButton 
            onClick={() => handleAttendanceChange('PRESENT', course.id)}
            disabled={attendance.status === 'PRESENT'}
          >
            Present
          </GradientButton>
          <GradientButton 
            onClick={() => handleAttendanceChange('ABSENT', course.id)}
            disabled={attendance.status === 'ABSENT'}
          >
            Absent
          </GradientButton>
          <GradientButton 
            onClick={() => handleAttendanceChange('TRAINER_ABSENT', course.id)}
            disabled={attendance.status === 'TRAINER_ABSENT'}
          >
            Trainer Absent
          </GradientButton>
        </Box>
      </Box>
    );
  };

  const renderClassButtons = useCallback((course) => {
    const isJoinEnabled = canJoinCourse(course);
    return (
      <Box mt={2}>
        <ButtonGroup 
          variant="contained" 
          color="primary" 
          fullWidth 
          orientation={isMobile ? 'vertical' : 'horizontal'}
        >
          <GradientButton 
            onClick={() => handleJoinRoom(course.id)}
            disabled={!isJoinEnabled || !course.bbb_join_url}
          >
            Join Class Room
          </GradientButton>
          {course.course.is_group_class ? (
            course.course.trainers.map((trainer, index) => (
              <React.Fragment key={trainer.id}>
                {trainer.google_meet_link && (
                  <GradientButton 
                    href={trainer.google_meet_link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    disabled={!isJoinEnabled}
                  >
                    Google Meet ({trainer.full_name})
                  </GradientButton>
                )}
                {trainer.zoom_meeting_link && (
                  <GradientButton 
                    href={trainer.zoom_meeting_link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    disabled={!isJoinEnabled}
                  >
                    Zoom ({trainer.full_name})
                  </GradientButton>
                )}
              </React.Fragment>
            ))
          ) : course.trainer ? (
            <>
              {course.trainer.google_meet_link && (
                <GradientButton 
                  href={course.trainer.google_meet_link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  disabled={!isJoinEnabled}
                >
                  Google Meet
                </GradientButton>
              )}
              {course.trainer.zoom_meeting_link && (
                <GradientButton 
                  href={course.trainer.zoom_meeting_link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  disabled={!isJoinEnabled}
                >
                  Zoom
                </GradientButton>
              )}
            </>
          ) : null}
        </ButtonGroup>
      </Box>
    );
  }, [isMobile, canJoinCourse, handleJoinRoom]);


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Box sx={{ minHeight: '100vh', position: 'relative', overflow: 'hidden' }}>
       {studentData && <ChatbotNotification user={studentData} />}
       {studentData && <MessagePopupHandler user={studentData} />}

     <Container 
  maxWidth="xl" 
  sx={{ 
    paddingTop: 4, 
    paddingBottom: '400px',
    px: { xs: 1, sm: 3 }  // Different padding for mobile (xs) and larger screens
  }}>
        <Typography variant="h4" gutterBottom color="white" sx={{ textAlign: 'left', mb: 4 }}>
          Student Dashboard
        </Typography>{/* Add NoticeCard here, only shown when notices are available */}
        {notices.length > 0 && (
          <Box mb={4}>
            <NoticeCard 
              notices={notices}
              userRole="STUDENT" // or pass the actual user role from your auth system
              
            />
          </Box>
        )}<GlassCard sx={{ 
          mb: 3, 
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundImage: {
              xs: 'url(https://nationalinstituteoflanguage.in/wp-content/uploads/2024/11/Untitled-design-2.png)',
              sm: 'url(https://nationalinstituteoflanguage.in/wp-content/uploads/2025/01/Untitled-design-12.png)'
            },
            backgroundSize: {
              xs: 'cover',  // Changed to cover for mobile
              sm: 'fill'    // Keep fill for desktop
            },
            backgroundPosition: {
              xs: 'center center',  // Center the background for mobile
              sm: 'right center'    // Keep right alignment for desktop
            },
            backgroundRepeat: 'no-repeat',
            opacity: 1,
            zIndex: 0,
          }
        }}>
          <CardContent sx={{ 
            position: 'relative',
            zIndex: 1,
            borderRadius: 1,
            minHeight: {  // Added minimum height to ensure card has enough space
              xs: '300px',
              sm: 'auto'
            }
          }}>
            {/* Edit Button */}
            <IconButton 
              onClick={() => setEditMode(!editMode)}
              sx={{ 
                position: 'absolute', 
                top: 8, 
                left: 8,
                color: 'rgba(255, 255, 255, 0.8)',
                '&:hover': { color: 'white' }
              }}
            >
              <Edit />
            </IconButton>
        
            {/* Profile Content */}
            <Box sx={{ maxWidth: '80%', p: 2 }}>
              <Grid container spacing={3}>
                {/* Profile Image Column */}
                <Grid item xs={12} md={3}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    position: 'relative'
                  }}>
                    <Avatar
                      src={getImageUrl(studentData?.profile_image)}
                      alt={`${studentData?.first_name} ${studentData?.last_name}`}
                      sx={{
                        width: 100,
                        height: 100,
                        border: '4px solid',
                        borderColor: 'primary.main',
                        boxShadow: '0 0 20px rgba(0,0,0,0.3)',
                        mb: 2,
                        background: 'rgba(255, 255, 255, 0.1)',
                        backdropFilter: 'blur(10px)',
                      }}
                    />
                  </Box>
                </Grid>
        
                {/* Profile Details Column */}
                <Grid item xs={12} md={9}>
                  {!editMode ? (
                    <>
                      <Typography variant="h4" gutterBottom sx={{ 
                        background: 'linear-gradient(45deg, #60A5FA, #A78BFA)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'orange',
                        fontWeight: 'bold',
                        mb: 1
                      }}>
                        Hi, {`${studentData?.first_name || ''} ${studentData?.last_name || ''}`.trim()}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'rgba(255, 255, 255, 0.6)', mb: 3 }}>
                        Student at National Institute of Language
                      </Typography>
        
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                          <Stack spacing={2}>
                            <Box>
                              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                Email
                              </Typography>
                              <Typography>{studentData?.email}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                Phone
                              </Typography>
                              <Typography>{studentData?.phone || 'Not provided'}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                City
                              </Typography>
                              <Typography>{studentData?.city || 'Not provided'}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack spacing={2}>
                            <Box>
                              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                Date of Birth
                              </Typography>
                              <Typography>{formatDate(studentData?.date_of_birth) || 'Not provided'}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                About Me
                              </Typography>
                              <Typography>{studentData?.about_me || 'No information provided'}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Box sx={{ mt: 4 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="First Name"
                            value={updatedProfile.first_name || studentData?.first_name || ''}
                            onChange={(e) => setUpdatedProfile({ ...updatedProfile, first_name: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="Last Name"
                            value={updatedProfile.last_name || studentData?.last_name || ''}
                            onChange={(e) => setUpdatedProfile({ ...updatedProfile, last_name: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="Email"
                            value={studentData?.email || ''}
                            disabled
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="Phone"
                            value={updatedProfile.phone || studentData?.phone || ''}
                            onChange={(e) => setUpdatedProfile({ ...updatedProfile, phone: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="City"
                            value={updatedProfile.city || studentData?.city || ''}
                            onChange={(e) => setUpdatedProfile({ ...updatedProfile, city: e.target.value })}
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            fullWidth
                            label="About Me"
                            value={updatedProfile.about_me || studentData?.about_me || ''}
                            onChange={(e) => setUpdatedProfile({ ...updatedProfile, about_me: e.target.value })}
                            multiline
                            rows={3}
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="Date of Birth"
                            value={dayjs(updatedProfile.date_of_birth || studentData?.date_of_birth)}
                            onChange={(date) => setUpdatedProfile({ ...updatedProfile, date_of_birth: date?.format('YYYY-MM-DD') })}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
                          />
                          <input
                            type="file"
                            onChange={handleProfileImageChange}
                            style={{ display: 'none' }}
                            id="profile-image-input"
                          />
                          <label htmlFor="profile-image-input">
                            <GradientButton component="span" fullWidth sx={{ mb: 1 }}>
                              Upload Profile Image
                            </GradientButton>
                          </label>
                          {newProfileImage && (
                            <Typography variant="caption" sx={{ display: 'block', mb: 2 }}>
                              New image selected: {newProfileImage.name}
                            </Typography>
                          )}
                          <GradientButton onClick={handleProfileUpdate} fullWidth>
                            Save Changes
                          </GradientButton>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </GlassCard><DashboardCourseFlow studentId={studentData?.id} />
        <Grid container spacing={3}>
          {/* Left Column */}
          
          <Grid item xs={12} md={4}>
          


            <GlassCard sx={{ mb: 3, mt: 4 }}>
  <CardContent>
    <Typography variant="h6" gutterBottom>Course Information</Typography>
    {coursesData.length > 0 ? (
      <>
        <Tabs
          value={selectedCourseIndex}
          onChange={(event, newValue) => setSelectedCourseIndex(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {coursesData.map((course, index) => (
            <Tab key={course.id} label={course.course.name} />
          ))}
        </Tabs>
        <Box mt={2}>
          <Typography variant="body1">Course: {coursesData[selectedCourseIndex].course.name}</Typography>
          <Typography variant="body2">Start Date: {formatDate(coursesData[selectedCourseIndex].start_date)}</Typography>
          <Typography variant="body2">End Date: {formatDate(coursesData[selectedCourseIndex].end_date)}</Typography>
          <Typography variant="body2">Class Time: {coursesData[selectedCourseIndex].course.class_time || coursesData[selectedCourseIndex].class_time}</Typography>
          <Typography variant="body2">
            Course Type: {coursesData[selectedCourseIndex].course.is_group_class ? 'Group' : 'Individual'}
          </Typography>
          
          {/* Add the Course Progress button here */}
          <Box mt={2} display="flex" gap={2}>
            <GradientButton 
              onClick={handleRequestCourseHold}
            >
              Request Course Hold
            </GradientButton>
            
          </Box>
        </Box>
      </>
    ) : (
      <Typography variant="body1">No course information available.</Typography>
    )}
  </CardContent>
</GlassCard>

            <GlassCard sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Feedback/Complaints</Typography>
                <GradientButton 
                  onClick={() => setFeedbackModalOpen(true)}
                  sx={{ mb: 2 }}
                >
                  Send Feedback
                </GradientButton>
                <Typography variant="subtitle1" gutterBottom>Pending Feedback:</Typography>
                <ScrollableList>
                  {feedbacks.filter(f => f.status !== 'RESOLVED').map((feedback) => (
                    <ListItem key={feedback.id} button onClick={() => handleFeedbackClick(feedback)}>
                      <ListItemText 
                        primary={feedback.topic} 
                        secondary={`${feedback.content.substring(0, 50)}...`}
                      />
                      <Chip label={feedback.status} color={feedback.status === 'PENDING' ? 'default' : 'primary'} />
                    </ListItem>
                  ))}
                </ScrollableList>
                <Typography variant="subtitle1" gutterBottom>Recent Resolved Feedback:</Typography>
                {feedbackHistory.length > 0 && (
                  <List>
                    <ListItem button onClick={() => handleFeedbackClick(feedbackHistory[0])}>
                      <ListItemText 
                        primary={feedbackHistory[0].topic} 
                        secondary={`${feedbackHistory[0].content.substring(0, 50)}...`}
                      />
                      <Chip label="RESOLVED" color="success" />
                    </ListItem>
                  </List>
                )}
                {feedbackHistory.length > 1 && (
                  <GradientButton onClick={() => setFeedbackDetailsModalOpen(true)}>
                    View All Feedback History
                  </GradientButton>
                )}
              </CardContent>
            </GlassCard>

            <GlassCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>Attendance Calendar</Typography>
                <GradientButton onClick={() => setCalendarModalOpen(true)}>
                  View Full Calendar
                </GradientButton>
              </CardContent>
            </GlassCard>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={8}>
            <GlassCard sx={{ mb: 3, mt:4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Attendance and Class Access</Typography>
                {coursesData.map((course) => (
                  <Box key={course.id} mb={2}>
                    <Typography variant="subtitle1">{course.course.name}</Typography>
                    {renderAttendanceButtons(course)}
                    {renderClassButtons(course)}
                    <Box><Typography variant="subtitle1">Note: If the trainer doesn't come in the class you can come back here to mark trainer absent (within 30 minutes)</Typography>
                    </Box></Box>
                ))}
              </CardContent>
            </GlassCard>

            <GlassCard sx={{ mb: 3 }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>Assigned Study Materials</Typography>
      <ScrollableList>
        {studyMaterials.map((material) => (
          <ListItem 
            key={material.id} 
            button 
            onClick={() => handleMaterialClick(material)}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText 
              primary={material.topic} 
              secondary={
                <>
                  <Typography variant="body2">
                    Created: {dayjs(material.created_at).format('MMMM D, YYYY')}
                  </Typography>
                  <Typography variant="body2">
                    Available until: {dayjs(material.expiry_date).format('MMMM D, YYYY')}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </ScrollableList>
    </CardContent>
  </GlassCard>

            <GlassCard sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Additional Information</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>Trainer Information</Typography>
                    {coursesData[selectedCourseIndex]?.trainer ? (
                      <>
                        <ProfileImage src={coursesData[selectedCourseIndex].trainer.profile_image} alt={coursesData[selectedCourseIndex].trainer.full_name} />
                        <Typography variant="body1">{coursesData[selectedCourseIndex].trainer.full_name}</Typography>
                        <Typography variant="body2">{coursesData[selectedCourseIndex].trainer.about_me}</Typography>
                        <Typography variant="body2">City: {coursesData[selectedCourseIndex].trainer.city}</Typography>
                        <TrainerNotification trainer={coursesData[selectedCourseIndex].trainer} />
                      </>
                    ) : coursesData[selectedCourseIndex]?.course.is_group_class ? (
                      <>
                        <Typography variant="body1">Group Course Trainers:</Typography>
                        {coursesData[selectedCourseIndex].course.trainer_assignments.map((assignment, index) => (
                          <Box key={index} mb={2}>
                            <Typography variant="body2">{assignment.trainer_name}</Typography>
                            <Typography variant="caption">
                              Time: {assignment.start_time} - {assignment.end_time}
                            </Typography>
                          </Box>
                        ))}
                        <Chip label="Group Course" color="secondary" size="small" style={{ marginTop: '10px' }} />
                      </>
                    ) : (
                      <Typography variant="body1">No trainer assigned yet.</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>Coordinator Profile</Typography>
                    {coordinatorData ? (
                      <>
                        <ProfileImage src={coordinatorData.profile_image} alt={coordinatorData.name} />
                        <Typography variant="body1">{coordinatorData.name}</Typography>
                        <Typography variant="body2">Phone: {coordinatorData.phone}</Typography>
                      </>
                    ) : (
                      <Typography variant="body1">No coordinator assigned yet.</Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </GlassCard>

            
             
              
                <ClassContentSection studentId={studentData?.id} />
              
            
          </Grid>
        </Grid>

        {/* Messaging Component */}
       
      </Container>

      {/* Attendance Calendar Modal */}
      <Dialog
        open={calendarModalOpen}
        onClose={() => setCalendarModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Attendance Calendar
        </DialogTitle>
        <DialogContent>
          <AttendanceCalendar
            studentId={studentData?.id}
            
            userRole="STUDENT"
            onRequestReview={() => {}} // Implement this if needed
          />
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={() => setCalendarModalOpen(false)}>Close</GradientButton>
        </DialogActions>
      </Dialog>

      {/* Feedback Modal */}
      <Dialog
        open={feedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Send Feedback</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Feedback Type</InputLabel>
            <Select
              value={feedbackType}
              onChange={(e) => setFeedbackType(e.target.value)}
            >
              <MenuItem value="GENERAL">General</MenuItem>
              <MenuItem value="COURSE">Course Related</MenuItem>
              <MenuItem value="TRAINER">Trainer Related</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Feedback Topic"
            value={feedbackTopic}
            onChange={(e) => setFeedbackTopic(e.target.value)}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Your Feedback"
            multiline
            rows={4}
            value={feedbackContent}
            onChange={(e) => setFeedbackContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={() => setFeedbackModalOpen(false)}>Cancel</GradientButton>
          <GradientButton onClick={handleFeedbackSubmit}>Submit</GradientButton>
        </DialogActions>
      </Dialog>

      {/* Course Hold Request Modal */}
      <Dialog open={courseHoldModalOpen} onClose={() => setCourseHoldModalOpen(false)}>
    <DialogTitle>Request Course Hold</DialogTitle>
    <DialogContent>
      <DatePicker
        label="Start Date"
        value={courseHoldData.start_date}
        onChange={(date) => handleCourseHoldInputChange('start_date', date)}
        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
        minDate={dayjs().add(1, 'day')} // This ensures the minimum selectable date is tomorrow
      />
      <DatePicker
        label="End Date"
        value={courseHoldData.end_date}
        onChange={(date) => handleCourseHoldInputChange('end_date', date)}
        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
        minDate={dayjs(courseHoldData.start_date).add(1, 'day')} // This ensures end date is after start date
      />
      <TextField
        fullWidth
        multiline
        rows={4}
        margin="normal"
        label="Reason for Hold"
        value={courseHoldData.reason}
        onChange={(e) => handleCourseHoldInputChange('reason', e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <GradientButton onClick={() => setCourseHoldModalOpen(false)}>Cancel</GradientButton>
      <GradientButton onClick={handleSubmitCourseHold}>
        Submit Request
      </GradientButton>
    </DialogActions>
  </Dialog>

      {/* Feedback Details Modal */}
      <Dialog
        open={feedbackDetailsModalOpen}
        onClose={() => setFeedbackDetailsModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Feedback Details</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>Pending Feedbacks</Typography>
          <List>
            {feedbacks.filter(f => f.status !== 'RESOLVED').map((feedback) => (
              <ListItem key={feedback.id}>
                <ListItemText 
                  primary={feedback.topic}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        Type: {feedback.feedback_type}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textPrimary">
                        {feedback.content}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textSecondary">
                        Status: {feedback.status}
                      </Typography>
                      {feedback.admin_remarks && (
                        <>
                          <br />
                          <Typography component="span" variant="body2" color="textSecondary">
                            Admin Remarks: {feedback.admin_remarks}
                          </Typography>
                        </>
                      )}
                    </>
                  }
                />
                <Chip label={feedback.status} color={feedback.status === 'PENDING' ? 'default' : 'primary'} />
              </ListItem>
            ))}
          </List>
          <Typography variant="h6" gutterBottom>Feedback History</Typography>
          <List>
            {feedbackHistory.map((feedback) => (
              <ListItem key={feedback.id}>
                <ListItemText 
                  primary={feedback.topic}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        Type: {feedback.feedback_type}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textPrimary">
                        {feedback.content}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textSecondary">
                        Status: RESOLVED
                      </Typography>
                      {feedback.admin_remarks && (
                        <>
                          <br />
                          <Typography component="span" variant="body2" color="textSecondary">
                            Admin Remarks: {feedback.admin_remarks}
                          </Typography>
                        </>
                      )}
                    </>
                  }
                />
                <Chip label="RESOLVED" color="success" />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={() => setFeedbackDetailsModalOpen(false)}>Close</GradientButton>
        </DialogActions>
      </Dialog>

      <Dialog open={Boolean(selectedMaterial)} onClose={handleCloseMaterialDialog} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedMaterial?.topic}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedMaterial?.course?.name}</Typography>
          <Typography variant="body2">
            Available until: {dayjs(selectedMaterial?.expiry_date).format('MMMM D, YYYY')}
          </Typography>
          <List>
            {selectedMaterial?.files.map((file) => (
              <ListItem key={file.id}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={file.file_name} />
                <Box>
                  {file.file_type.startsWith('image/') ? (
                    <img src={file.file} alt={file.file_name} style={{ maxWidth: '100%', maxHeight: '300px' }} />
                  ) : file.file_type === 'application/pdf' ? (
                    <iframe src={file.file} width="100%" height="500px" title={file.file_name} />
                  ) : (
                    <Typography variant="body2">Preview not available for this file type.</Typography>
                  )}
                </Box>
                <GradientButton href={file.file} target="_blank" rel="noopener noreferrer">
                  Download
                </GradientButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={handleCloseMaterialDialog}>
            Close
          </GradientButton>
        </DialogActions>
      </Dialog>

      {/* Absence Warning Dialog */}
      {/* Absence Warning Dialog */}
<Dialog
  open={showAbsenceWarning}
  onClose={() => setShowAbsenceWarning(false)}
  PaperProps={{
    sx: {
      background: 'rgba(255, 255, 255, 0.05)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
      borderRadius: '15px',
      color: 'white',
    }
  }}
>
  <DialogTitle sx={{ 
    background: 'linear-gradient(45deg, rgba(255, 27, 107, 0.3), rgba(255, 147, 15, 0.3))',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    display: 'flex',
    alignItems: 'center'
  }}>
    <WarningIcon sx={{ mr: 1, color: '#FFD700' }} />
    Attendance Warning
  </DialogTitle>
  <DialogContent sx={{ mt: 2 }}>
    <DialogContentText sx={{ color: 'rgba(255, 255, 255, 0.87)' }}>
      <Typography sx={{ mb: 2 }}>
        You have been absent for more than 2 days now, and you will not be entitled to any more compensations. 
        Make sure to not take any more leaves to be able to complete your course on time.
      </Typography>
      <Typography sx={{ 
        p: 2, 
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        borderLeft: '4px solid #FFD700'
      }}>
        आप 2 दिनों से ज़्यादा समय से अनुपस्थित हैं और आपको अब कोई अतिरिक्त क्लास नहीं मिल सकेगी  सुनिश्चित करें कि आप समय पर अपना कोर्स पूरा करने के लिए अब और अवकाश न लें।

        
      </Typography>
      <Typography sx={{ 
        p: 2, 
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        borderLeft: '4px solid #FFD700'
      }}>
        நீங்கள் இப்போது 2 நாட்களுக்கும் மேலாக வரவில்லை, மேலும் இழப்பீடுகளுக்கு உங்களுக்கு உரிமை இல்லை. உங்கள் பாடத்திட்டத்தை சரியான நேரத்தில் முடிக்க இனி எந்த இலைகளையும் எடுக்காமல் பார்த்துக் கொள்ளுங்கள்.

        
      </Typography>
    </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ p: 2 }}>
    <Button
      onClick={() => setShowAbsenceWarning(false)}
      sx={{
        background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
        color: 'white',
        borderRadius: '50px',
        padding: '10px 20px',
        '&:hover': {
          background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
          boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
        }
      }}
    >
      Understood
    </Button>
  </DialogActions>
</Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StudentDashboard;