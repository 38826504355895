import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import { Message as MessageIcon, Close as CloseIcon } from '@mui/icons-material';
import webSocketService from '../services/apiws';

const TrainerNotification = ({ trainer }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    
    setSending(true);
    try {
      await webSocketService.sendMessage(message, [trainer.id]);
      setMessage('');
      setOpen(false);
    } catch (error) {
      console.error('Error sending message to trainer:', error);
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <Button
        startIcon={<MessageIcon />}
        onClick={() => setOpen(true)}
        sx={{
          mt: 2,
          background: 'linear-gradient(45deg, #4A90E2, #2196F3)',
          color: 'white',
          '&:hover': {
            background: 'linear-gradient(45deg, #2196F3, #4A90E2)',
          },
          borderRadius: 2,
          py: 1,
          width: '100%'
        }}
      >
        Send Notification to Trainer
      </Button>

      <Dialog 
        open={open} 
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            background: '#0B1537',
            color: 'white',
            borderRadius: 2
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
        }}>
          <Typography>Message to {trainer.full_name}</Typography>
          <IconButton 
            onClick={() => setOpen(false)}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ mt: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'white',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.4)',
                },
              },
            }}
          />
        </DialogContent>

        <DialogActions sx={{ p: 2, borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
          <Button 
            onClick={() => setOpen(false)}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSendMessage}
            disabled={!message.trim() || sending}
            sx={{
              background: 'linear-gradient(45deg, #4A90E2, #2196F3)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(45deg, #2196F3, #4A90E2)',
              },
              '&:disabled': {
                background: 'rgba(255, 255, 255, 0.12)',
                color: 'rgba(255, 255, 255, 0.3)'
              }
            }}
          >
            {sending ? 'Sending...' : 'Send Message'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TrainerNotification;