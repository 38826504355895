import React, { useState } from 'react';
import { markBlockAsRead, getVocabProgressHistory, getVocabProgressStats } from '../api';
import { Calendar, Video } from 'lucide-react';
import './vocab-styles.css';

// Main VocabFlashcards component
const VocabFlashcards = ({ block, onComplete, progressId }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [progressHistory, setProgressHistory] = useState([]);
  const [progressStats, setProgressStats] = useState(null);
  const [selectedHistoryWord, setSelectedHistoryWord] = useState(null);
  const [wordHistoryInfo, setWordHistoryInfo] = useState(null);

  // Fetch history and stats
  const fetchProgressData = async () => {
    try {
      const [historyRes, statsRes] = await Promise.all([
        getVocabProgressHistory(),
        getVocabProgressStats()
      ]);
      setProgressHistory(historyRes.data);
      setProgressStats(statsRes.data);
    } catch (err) {
      console.error('Error fetching progress data:', err);
    }
  };

  const handleMediaClick = (mediaType, url, e) => {
    e.stopPropagation();
    setSelectedMedia({ type: mediaType, url });
    setIsMediaModalOpen(true);
  };

  const toggleDetails = (e) => {
    e.stopPropagation();
    setIsFlipped(!isFlipped);
  };

  const handleMarkAsRead = async () => {
    try {
      setLoading(true);
      await markBlockAsRead(progressId);
      setSubmitSuccess(true);
      setTimeout(() => {
        if (onComplete) {
          onComplete();
        }
      }, 1000);
    } catch (err) {
      console.error('Error marking block as read:', err);
      setError('Failed to mark block as complete. Please try again.');
      setTimeout(() => setError(null), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleHistoryWordClick = (word, entry) => {
    setSelectedHistoryWord(word);
    setWordHistoryInfo({
      blockOrder: entry.block.order,
      unlockedAt: entry.unlocked_at,
      completedAt: entry.completed_at
    });
  };

  if (!block || !block.words || block.words.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-[300px]">
        <div className="text-lg">No vocabulary content available</div>
      </div>
    );
  }

  const currentWord = block.words[currentWordIndex];

  return (
    <div className="flashcard-container">
      {/* Header */}
      <div className="flashcard-header">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Daily Vocabulary</h2>
          <button
            onClick={() => {
              fetchProgressData();
              setIsHistoryOpen(true);
            }}
            className="progress-button"
          >
            <Calendar className="h-4 w-4" />
            <span>View Progress</span>
          </button>
        </div>
        <p>Learn and review today's vocabulary words before starting your chat session</p>
      </div>

      {/* Main Flashcard */}
      <div className="flashcard">
        {isFlipped ? (
          // Back of Card
          <div className="flashcard-content">
            <div className="back-link-container">
              <button 
                className="back-link"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDetails(e);
                }}
              >
                ← Back to word "{currentWord.word}"
              </button>
            </div>

            <div className="definitions-container">
              <div className="content-section">
                <h4>Hindi:</h4>
                <p>{currentWord.hindi_definition}</p>
              </div>
              
              <div className="content-section">
                <h4>English:</h4>
                <p>{currentWord.english_definition}</p>
              </div>
              
              <div className="content-section">
                <h4>Examples:</h4>
                <ul className="examples-list">
                  {currentWord.example1 && <li>{currentWord.example1}</li>}
                  {currentWord.example2 && <li>{currentWord.example2}</li>}
                  {currentWord.example3 && <li>{currentWord.example3}</li>}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          // Front of Card
          <div className="text-center">
            <div className="word-header">
              <div className="word-title-container">
                <h3 className="word-title">{currentWord.word}</h3>
                <span className="part-of-speech">{currentWord.part_of_speech}</span>
              </div>
              <button 
                className="details-link"
                onClick={toggleDetails}
              >
                View meaning and examples →
              </button>
            </div>
            
            {(currentWord.image_url || currentWord.video_url) && (
              <div className="media-container">
                {currentWord.image_url && (
                  <div className="media-preview">
                    <img 
                      src={currentWord.image_url} 
                      alt={`Preview for ${currentWord.word}`}
                      className="media-thumbnail"
                    />
                    {currentWord.video_url && (
                      <button
                        className="media-button"
                        onClick={(e) => handleMediaClick('video', currentWord.video_url, e)}
                      >
                        <Video className="h-4 w-4" />
                        <span>Watch Video</span>
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Navigation */}
      <div className="navigation-container">
        <button 
          className={`nav-button ${currentWordIndex === 0 ? 'disabled' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setIsFlipped(false);
            setCurrentWordIndex(prev => Math.max(0, prev - 1));
          }}
          disabled={currentWordIndex === 0}
        >
          Previous
        </button>
        
        <span className="font-bold">
          {currentWordIndex + 1} / {block.words.length}
        </span>
        
        <button 
          className={`nav-button ${currentWordIndex === block.words.length - 1 ? 'disabled' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setIsFlipped(false);
            setCurrentWordIndex(prev => Math.min(block.words.length - 1, prev + 1));
          }}
          disabled={currentWordIndex === block.words.length - 1}
        >
          Next
        </button>
      </div>

      {/* Complete Button */}
      <button 
        className={`complete-button ${
          loading ? 'bg-gray-400' : 
          submitSuccess ? 'bg-green-500' : 
          error ? 'bg-red-500' : 
          ''
        }`}
        onClick={handleMarkAsRead}
        disabled={loading || submitSuccess}
      >
        {loading ? 'Processing...' :
         submitSuccess ? 'Completed!' :
         error ? error :
         'Mark as Complete'}
      </button>

      {/* Media Modal */}
      {isMediaModalOpen && selectedMedia && (
        <div 
          className="media-modal"
          onClick={() => setIsMediaModalOpen(false)}
        >
          <div 
            className="media-modal-content"
            onClick={e => e.stopPropagation()}
          >
            <button
              className="media-modal-close"
              onClick={() => setIsMediaModalOpen(false)}
            >
              ×
            </button>
            {selectedMedia.type === 'image' ? (
              <img
                src={selectedMedia.url}
                alt="Word visualization"
                className="media-preview-content"
              />
            ) : (
              <video
                controls
                autoPlay
                className="media-preview-content"
              >
                <source src={selectedMedia.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      )}

      {/* Progress History Modal */}
      {isHistoryOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Vocabulary Progress History</h2>
              <button onClick={() => setIsHistoryOpen(false)} className="modal-close">×</button>
            </div>

            <div className="stats-container">
              <div className="stats-grid">
                <div className="stat-card">
                  <div className="stat-label">Total Blocks Completed</div>
                  <div className="stat-value text-blue">{progressHistory?.length || 0}</div>
                </div>
                <div className="stat-card">
                  <div className="stat-label">Total Words</div>
                  <div className="stat-value text-green">
                    {progressHistory?.reduce((total, entry) => total + (entry.block?.words?.length || 0), 0) || 0}
                  </div>
                </div>
                <div className="stat-card">
                  <div className="stat-label">Last Activity</div>
                  <div className="stat-value text-purple">
                    {progressHistory?.[0]?.unlocked_at ? 
                      new Date(progressHistory[0].unlocked_at).toLocaleDateString() : 
                      'N/A'}
                  </div>
                </div>
              </div>
            </div>

            <div className="history-list">
              {progressHistory?.map((entry) => (
                <div key={entry.id} className="history-item">
                  <div className="history-item-header">
                    <div className="history-date">
                      <Calendar className="h-4 w-4" />
                      <span>Block #{entry.block.order}</span>
                    </div>
                    <span className="history-block-number">
                      {entry.block.words.length} Words
                    </span>
                  </div>
                  
                  <div className="history-words">
                    {entry.block.words.map((word) => (
                      <button
                        key={word.id}
                        className="history-word hover:bg-blue-600 transition-colors cursor-pointer"
                        onClick={() => handleHistoryWordClick(word, entry)}
                      >
                        {word.word} ({word.part_of_speech})
                      </button>
                    ))}
                  </div>
                  
                  <div className="history-times">
                    <div className="history-time-item">
                      <span>Started: {new Date(entry.unlocked_at).toLocaleString()}</span>
                    </div>
                    <div className="history-time-item">
                      <span>Completed: {new Date(entry.completed_at).toLocaleString()}</span>
                    </div>
                  </div>
                </div>
              ))}
              
              {(!progressHistory || progressHistory.length === 0) && (
                <div className="history-empty">
                  No vocabulary blocks completed yet
                </div>
              )}
            </div>
          </div>
        </div>
      )}

     {/* Word Details Modal */}
     {selectedHistoryWord && (
        <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" onClick={() => {
          setSelectedHistoryWord(null);
          setWordHistoryInfo(null);
        }}>
          <div className="modal-content relative bg-gray-900 rounded-lg w-full max-w-2xl mx-4" style={{ maxHeight: 'calc(100vh - 2rem)' }} onClick={e => e.stopPropagation()}>
            <div className="modal-header sticky top-0 z-10 bg-gray-900 flex justify-between items-center p-4 border-b border-gray-700 rounded-t-lg">
              <div className="word-title-container">
                <h2 className="word-title text-xl font-bold">{selectedHistoryWord.word}</h2>
                <span className="part-of-speech text-sm text-gray-400">{selectedHistoryWord.part_of_speech}</span>
              </div>
              <button 
                onClick={() => {
                  setSelectedHistoryWord(null);
                  setWordHistoryInfo(null);
                }} 
                className="modal-close text-2xl hover:text-gray-400 transition-colors"
              >×</button>
            </div>

            <div className="overflow-y-auto p-6" style={{ maxHeight: 'calc(100vh - 8rem)' }}>
              {(selectedHistoryWord.image_url || selectedHistoryWord.video_url) && (
                <div className="media-container">
                  {selectedHistoryWord.image_url && (
                    <div className="media-preview">
                      <img 
                        src={selectedHistoryWord.image_url} 
                        alt={`Preview for ${selectedHistoryWord.word}`}
                        className="media-thumbnail"
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="definitions-container">
                <div className="content-section">
                  <h4>Hindi:</h4>
                  <p>{selectedHistoryWord.hindi_definition}</p>
                </div>
                
                <div className="content-section">
                  <h4>English:</h4>
                  <p>{selectedHistoryWord.english_definition}</p>
                </div>
                
                <div className="content-section">
                  <h4>Examples:</h4>
                  <ul className="examples-list">
                    {selectedHistoryWord.example1 && <li>{selectedHistoryWord.example1}</li>}
                    {selectedHistoryWord.example2 && <li>{selectedHistoryWord.example2}</li>}
                    {selectedHistoryWord.example3 && <li>{selectedHistoryWord.example3}</li>}
                  </ul>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VocabFlashcards;