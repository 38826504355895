import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Box, Tooltip
} from '@mui/material';
import { Message as MessageIcon, Close as CloseIcon } from '@mui/icons-material';
import webSocketService from '../services/apiws';

const StudentNotification = ({ student }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    
    setSending(true);
    try {
      await webSocketService.sendMessage(message, [student]);
      setMessage('');
      setOpen(false);
    } catch (error) {
      console.error('Error sending message to student:', error);
    } finally {
      setSending(false);
    }
  };

  return (
    <>
    <Tooltip title="Notify student, if you want to switch from portal to GMeet or vice versa">
      <Button
        variant="contained"
        startIcon={<MessageIcon />}
        onClick={() => setOpen(true)}
        sx={{
          background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
          color: 'white',
          borderRadius: '8px',
          padding: '6px 12px',
          minWidth: '120px',
          fontSize: '12px',
          fontWeight: 'bold',
          textTransform: 'none',
          border: 'none',
          boxShadow: '0 2px 8px rgba(255, 27, 107, 0.3)',
          '&:hover': {
            background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
            boxShadow: '0 4px 12px rgba(255, 27, 107, 0.5)',
          },
        }}
      >
        Notify Student
      </Button>
      </Tooltip>

      <Dialog 
        open={open} 
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            background: '#1a1a1a',
            
            borderRadius: '16px',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
            color: 'white',
            minWidth: '400px'
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          pb: 2
        }}>
          <Typography>Send Notification to Student</Typography>
          <IconButton 
            onClick={() => setOpen(false)}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ mt: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'white',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.4)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FF1B6B',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'rgba(255, 255, 255, 0.5)',
              },
            }}
          />
        </DialogContent>

        <DialogActions sx={{ 
          p: 2, 
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          gap: 1
        }}>
          <Button 
            onClick={() => setOpen(false)}
            sx={{ 
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSendMessage}
            disabled={!message.trim() || sending}
            sx={{
              background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
              color: 'white',
              borderRadius: '8px',
              '&:hover': {
                background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
              },
              '&:disabled': {
                background: 'rgba(255, 255, 255, 0.12)',
                color: 'rgba(255, 255, 255, 0.3)'
              }
            }}
          >
            {sending ? 'Sending...' : 'Send Message'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentNotification;