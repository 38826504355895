import React from 'react';
import CollapsibleMessage from './CollapsibleMessage';
import TypingIndicator from './TypingIndicator';
import './chat-messages.css';

const MessageList = ({ messages, isEnglishTutor, isLoading }) => {
  const renderRegularMessage = (message, index) => (
    <div key={index} className={`message ${message.sender}`}>
      <div className="message-content">
        <p>{message.text}</p>
        <span className="timestamp">
          {new Date(message.timestamp).toLocaleTimeString()}
        </span>
      </div>
    </div>
  );

  return (
    <div className="message-list">
      {messages.map((message, index) => {
        // Try to parse message content if it's a JSON string
        let processedMessage = { ...message };
        
        if (typeof message.text === 'string' && message.text.trim().startsWith('{')) {
          try {
            const parsedContent = JSON.parse(message.text);
            processedMessage = {
              ...message,
              text: parsedContent.response,
              corrections: parsedContent.corrections,
              learning_points: parsedContent.learning_points,
              follow_up_questions: parsedContent.follow_up_questions
            };
          } catch (e) {
            console.error('Failed to parse message content as JSON:', e);
          }
        }

        return isEnglishTutor && processedMessage.sender === 'bot' ? (
          <CollapsibleMessage key={index} message={processedMessage} />
        ) : (
          renderRegularMessage(processedMessage, index)
        );
      })}
      
      {isLoading && <TypingIndicator />}
    </div>
  );
};

export default MessageList;