// src/services/ttsService.js

class TokenManager {
    constructor(tokens) {
        this.tokens = tokens;
        this.currentIndex = 0;
        this.failedTokens = new Set();
    }

    getCurrentToken() {
        return this.tokens[this.currentIndex];
    }

    markCurrentTokenAsFailed() {
        console.log(`Marking token as failed: ${this.getCurrentToken().substring(0, 20)}...`);
        this.failedTokens.add(this.getCurrentToken());
        this.rotateToNextValidToken();
    }

    rotateToNextValidToken() {
        const startIndex = this.currentIndex;
        do {
            this.currentIndex = (this.currentIndex + 1) % this.tokens.length;
            // If we've checked all tokens and come back to where we started
            if (this.currentIndex === startIndex) {
                // If all tokens are failed, reset failed tokens and try again
                if (this.failedTokens.size === this.tokens.length) {
                    console.log('All tokens have failed, resetting failed tokens list');
                    this.failedTokens.clear();
                }
            }
        } while (this.failedTokens.has(this.getCurrentToken()));
        
        console.log(`Rotated to new token: ${this.getCurrentToken().substring(0, 20)}...`);
    }
}

class TTSService {
    constructor() {
        this.audioContext = null;
        this.audioQueue = [];
        this.isPlaying = false;
        
        // Initialize token manager with all available tokens
        const API_TOKENS = [
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNmZlZDUxMmFiZjBmMTUzMDc4NTkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI4NzE3LCJleHAiOjQ4OTA3ODg3MTd9.WoiFHZYhkFwcqW2xuS57QjaNLgoZ6XwI4tL3zO3P8F4',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzA1NzUxMmFiZjBmMTUzMDc4ODEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI4ODIzLCJleHAiOjQ4OTA3ODg4MjN9.jdBVB_4hocryfI7ppx62F_wG0W9PeLVTOgymivJ5iKQ',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzA5YTUxMmFiZjBmMTUzMDc4OWMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI4ODkwLCJleHAiOjQ4OTA3ODg4OTB9.MzZRVWHwJ1J2cwn8wIhOWg9AS_UXVwXqrA-XUcaH2DE',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzBjNTUxMmFiZjBmMTUzMDc4YjciLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI4OTMzLCJleHAiOjQ4OTA3ODg5MzN9.7HzyvfXwq4QOmCAW7C2y9wRtuBBCuKJA94hMeXIeozs',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzBmNjUxMmFiZjBmMTUzMDc4ZDIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI4OTgyLCJleHAiOjQ4OTA3ODg5ODJ9.BSQCLN6jmG2_2seBdI-fS696x9X1v97ILkoUjowokYE',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzEyYjUxMmFiZjBmMTUzMDc4ZWQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5MDM1LCJleHAiOjQ4OTA3ODkwMzV9.yL8MLKoqT9T7D_A1hloldKYkjcfTXEbPfqQAT_G1vD8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzE1ZDUxMmFiZjBmMTUzMDc5MGEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5MDg1LCJleHAiOjQ4OTA3ODkwODV9.0xR207D3kgsjS2UR5t2IOt3XzdP6Bn5kvbpSrx130mQ',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzE4MjUxMmFiZjBmMTUzMDc5MjYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5MTIyLCJleHAiOjQ4OTA3ODkxMjJ9.Y9XKmQ528hC5YtnEieAgxuA0u47nyZPAS3mvAJ9s1CQ',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzFhMzUxMmFiZjBmMTUzMDc5NDIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5MTU1LCJleHAiOjQ4OTA3ODkxNTV9.amWBs6KnjevrrupSJzjYW1gnMhptA7TKFL2nlq5M_MI',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzFjYjUxMmFiZjBmMTUzMDc5NWQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5MTk1LCJleHAiOjQ4OTA3ODkxOTV9.GOeG3DDncbQ6WUm5Wbpk7XljZ2hp-g0gXQFmo5BHaM4',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzFmMDUxMmFiZjBmMTUzMDc5NzgiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5MjMyLCJleHAiOjQ4OTA3ODkyMzJ9.PfG_eNm5gnHe5KzUquR34mQ0hoiydyKtDF2xR6U5SzU',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzIxNjUxMmFiZjBmMTUzMDc5OTMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5MjcwLCJleHAiOjQ4OTA3ODkyNzB9.Ctm2V-a7eZyLO9XzQUERlOHnOU9ehVEdOOcEgkYEU6g',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzIzYTUxMmFiZjBmMTUzMDc5YWUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5MzA2LCJleHAiOjQ4OTA3ODkzMDZ9.SnKCy5_3vuDx5rrxSS-uyty2wZACnIuQ-BItmZjHA84',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzJhMTUxMmFiZjBmMTUzMDc5YzkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5NDA5LCJleHAiOjQ4OTA3ODk0MDl9.xFMRhsNo5lUOwa64geVvyefMK_M9eJX7rPJljIqiGzo',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzMwZTUxMmFiZjBmMTUzMDc5ZmMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5NTE4LCJleHAiOjQ4OTA3ODk1MTh9.-1YRTOQeKUpZcmcC-6NhKVk7N5yEJjmnOsWhQd5W62Q',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzMzOTUxMmFiZjBmMTUzMDdhMTciLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5NTYxLCJleHAiOjQ4OTA3ODk1NjF9.o6xd9PRN05etYdQ0dchU9HRVzA_iTmTsvEIzr0aXFlE',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzM2MTUxMmFiZjBmMTUzMDdhMzUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5NjAxLCJleHAiOjQ4OTA3ODk2MDF9.nA6djEB45X_pLfx7RGd9-6Qbl_rh_p_WCYuzgEh-GHI',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzM4NjUxMmFiZjBmMTUzMDdhN2UiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5NjM4LCJleHAiOjQ4OTA3ODk2Mzh9.8NCu_RB6g_W6JYfnIC-sBnDwCFO6xTZc-hrfnf9GWVo',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzNhZjUxMmFiZjBmMTUzMDdhOTkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5Njc5LCJleHAiOjQ4OTA3ODk2Nzl9.xeGxvteypG7Tn7HKc660u7Evb7qFxBpWfEUizfDYkjQ',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzNkMzUxMmFiZjBmMTUzMDdiMDUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5NzE1LCJleHAiOjQ4OTA3ODk3MTV9.AU9lsmE9O6OpbqsukZvcjxQU14btd1VipV-Zw3XrdS8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzNmYjUxMmFiZjBmMTUzMDdiM2MiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5NzU1LCJleHAiOjQ4OTA3ODk3NTV9.cGv6z3FxBBE5SoH4aETVcKcLOsMXRrOvEBUS5UpdycQ',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzQ5NzUxMmFiZjBmMTUzMDdiY2MiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5OTExLCJleHAiOjQ4OTA3ODk5MTF9.VimMeRRKfC-euVUSbuCtaSUxEWOmWcnBeU2f2l-eyy0',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzRiODUxMmFiZjBmMTUzMDdjMDAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5OTQ0LCJleHAiOjQ4OTA3ODk5NDR9.C_cQgSbf8mPJqsYWXSZ548RNFeA-KmdkyMXlsL62Eck',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzRkYjUxMmFiZjBmMTUzMDdjMzIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDI5OTc5LCJleHAiOjQ4OTA3ODk5Nzl9.gtNFqyVNhwI5wkQjQkIQqzPWZ31dVBgk3P1OAhqCy-s',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzUwMDUxMmFiZjBmMTUzMDdjNmYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMDE2LCJleHAiOjQ4OTA3OTAwMTZ9.edkZjhmjkvwHxwxtNUu0hMp75XzigkNe08OyFFaJGw4',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzUyYTUxMmFiZjBmMTUzMDdjOTIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMDU4LCJleHAiOjQ4OTA3OTAwNTh9.fmn6LrwmdLWWOv7wD3y7nrLDM4P3GeJ5fPZBuLD1IZU',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzU0NTUxMmFiZjBmMTUzMDdjYzIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMDg1LCJleHAiOjQ4OTA3OTAwODV9.Dryzz68VvQfz1Us6In0QkmrdN3zx6NwzGuzdDXmfEbI',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzU2NDUxMmFiZjBmMTUzMDdjZWUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMTE2LCJleHAiOjQ4OTA3OTAxMTZ9.p8enQZsNzJOuJIoXxzb6h6sbywOpj9nJaVv1zoc_FH4',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzU4NzUxMmFiZjBmMTUzMDdkNDAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMTUxLCJleHAiOjQ4OTA3OTAxNTF9.C75oCYkOa4foDF8eiF3fF39tGtP23UDQ5Bndls9Sa4o',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzVhYjUxMmFiZjBmMTUzMDdkOGUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMTg3LCJleHAiOjQ4OTA3OTAxODd9.xDYA0FCbCL0-pcXQAR4H7tQ_JaymAJd8IQZQv69VrRQ',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzVjZjUxMmFiZjBmMTUzMDdkYjQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMjIzLCJleHAiOjQ4OTA3OTAyMjN9.3s9lQIYe5rZpjY3kh91QATyLRTNbSIv0hBUiRW3xS7M',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzVmMDUxMmFiZjBmMTUzMDdkY2YiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMjU2LCJleHAiOjQ4OTA3OTAyNTZ9.48duVKmMmM7kR0BQqb0N1NCCpOjHNJSio9xkUG6M63c',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzYxNzUxMmFiZjBmMTUzMDdlMTEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMjk1LCJleHAiOjQ4OTA3OTAyOTV9.QIH6owodC-eKfTlRNlU8pA1_qZDu3kmKJALPa23jTRc',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzYzNjUxMmFiZjBmMTUzMDdlNzMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMzI2LCJleHAiOjQ4OTA3OTAzMjZ9.fZjOi8u_dOuRT0jQkFAIFyA0meFS8ll75wlMuZMcp-Q',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzY1MjUxMmFiZjBmMTUzMDdlYzYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMzU0LCJleHAiOjQ4OTA3OTAzNTR9.ZlR7ReGZ65vqbiao5ga2LpK9rPHo-THy83hl15SwfoE',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzY3MjUxMmFiZjBmMTUzMDdmNTUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwMzg2LCJleHAiOjQ4OTA3OTAzODZ9.4vJ7-dzpmdEutZnJIoIJWw9b6iceaB_1OC6bYh1AEYk',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzY4ZDUxMmFiZjBmMTUzMDdmNzkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNDEzLCJleHAiOjQ4OTA3OTA0MTN9.tEWumfwAdb5bOAunuFXeNZEzEuisJj1jj_d68pSI4Ts',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzZhZDUxMmFiZjBmMTUzMDdmYjUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNDQ1LCJleHAiOjQ4OTA3OTA0NDV9.fXJcTexX53cYqMa7W9AFT2xNAV2YQwDc-p5WHs0-Tc4',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzZjZDUxMmFiZjBmMTUzMDdmZGIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNDc3LCJleHAiOjQ4OTA3OTA0Nzd9.2vjC-3hDB2A6X2CUjP_3U69GkrDZrjlO1LvUoME1VrQ',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzZlYzUxMmFiZjBmMTUzMDgwMzQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNTA4LCJleHAiOjQ4OTA3OTA1MDh9.q3_K33L89gpuyVPGea8yEDh9VAvr9TqLAFSX76SU8_k',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzcwODUxMmFiZjBmMTUzMDgwN2MiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNTM2LCJleHAiOjQ4OTA3OTA1MzZ9.VVZzVokGj0X7CdiFWEuRb4YnLS35BWp64LLmNvYBbts',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzcyODUxMmFiZjBmMTUzMDgwOTciLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNTY4LCJleHAiOjQ4OTA3OTA1Njh9.wALCxcdE70JVz7EJYP9LM0qmQYqR49q6F1cAlTOTgFo',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzc0YTUxMmFiZjBmMTUzMDgwY2UiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNjAyLCJleHAiOjQ4OTA3OTA2MDJ9.x_lSLgfALGjVFF6hdg_ma0j6d61zihqQGQ3DII9704g',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzc2ODUxMmFiZjBmMTUzMDgwZTkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNjMyLCJleHAiOjQ4OTA3OTA2MzJ9.hfBqRyRLcX0574qidR8ae7gx_vnvEsqa6oCWRYexF_Y',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzc4NjUxMmFiZjBmMTUzMDgxMDQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNjYyLCJleHAiOjQ4OTA3OTA2NjJ9.bVzOy5jvrhT8FWbORMfoQXmYbSaebuXzH_0vN9GF4O8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzdhNTUxMmFiZjBmMTUzMDgxMWYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNjkzLCJleHAiOjQ4OTA3OTA2OTN9.f1HBBjPmBevCTLfqqEJ4gBiLyp_fQpsaqmimXQswd0U',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzdjNTUxMmFiZjBmMTUzMDgxM2EiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNzI1LCJleHAiOjQ4OTA3OTA3MjV9.ZT9tVdHfm2FpWhK43th72UwRv1KWFJR3kQyizuUqUfo',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzdlNDUxMmFiZjBmMTUzMDgxNTUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNzU2LCJleHAiOjQ4OTA3OTA3NTZ9.GK4JqcHJIcFAuuZMa41QZnpx0BpAgIMPMWv_AJz51bI',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzdmZTUxMmFiZjBmMTUzMDgxNzMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwNzgyLCJleHAiOjQ4OTA3OTA3ODJ9.I02SsXsKirox0ElN87MQieTh_1YyjXhuNSQ4bdqrKUY',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzgxYjUxMmFiZjBmMTUzMDgxOGUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwODExLCJleHAiOjQ4OTA3OTA4MTF9.P0OqPh5AH7xdE1ielEHrV5jdUY74w9mHrz_KFGSPHso',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzgzNTUxMmFiZjBmMTUzMDgxYTkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwODM3LCJleHAiOjQ4OTA3OTA4Mzd9.roE-Tn8n2xkiivYGhbsyUvjSs-aWr8jxWUe_8Q2zBYs',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzg1MTUxMmFiZjBmMTUzMDgxYzQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwODY1LCJleHAiOjQ4OTA3OTA4NjV9.hqk9IUOZaimnrnobj-JWfNKsLR1U9bMPVxBpkHYm-Yo',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzg4NjUxMmFiZjBmMTUzMDgxZGYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwOTE4LCJleHAiOjQ4OTA3OTA5MTh9.NAm_brc5RBZgmdqNQol6o4G5IYxQm3_UwaYzXVgjPyc',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzhhNDUxMmFiZjBmMTUzMDgxZmEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwOTQ4LCJleHAiOjQ4OTA3OTA5NDh9.8s3ZaJGMh-kKiHkjaQu0nD_RsasEhjSe4ybcQq2XYck',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzhjMDUxMmFiZjBmMTUzMDgyMTUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMwOTc2LCJleHAiOjQ4OTA3OTA5NzZ9.2tW_5bobKIILLDf45tN9Gss50WUKYcVeOH0nAOgKSNE',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzhkOTUxMmFiZjBmMTUzMDgyMzAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMDAxLCJleHAiOjQ4OTA3OTEwMDF9.p5fbqIGLcsT1lA1Xk4VvYV4jEluoi3hsBKqgZMpeX0M',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzhmNTUxMmFiZjBmMTUzMDgyNGIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMDI5LCJleHAiOjQ4OTA3OTEwMjl9.8qawZVWzw0O_jKNy3KWp_xoH699I3z9McfegQ3Nlg3E',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzkxMjUxMmFiZjBmMTUzMDgyNjYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMDU4LCJleHAiOjQ4OTA3OTEwNTh9.6vGFkadsmzqoP30X54BTGLNd3ymWKZOLsUXQc5ugg9I',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzkyZDUxMmFiZjBmMTUzMDgyODEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMDg1LCJleHAiOjQ4OTA3OTEwODV9._KoTo4nLguMK36-zBzpieByIfznm4sLH16WSUa6h-6k',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzk0ZTUxMmFiZjBmMTUzMDgyYWQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMTE4LCJleHAiOjQ4OTA3OTExMTh9.LOnq1U_ERfNLdJaPLcMrqrn8m3-ewNQEp9tDVJpHeXM',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzk2YzUxMmFiZjBmMTUzMDgzMDkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMTQ4LCJleHAiOjQ4OTA3OTExNDh9.WpoxqEoWAH2TyPLnkSeISp7MDhWFRl8rzzhd3yRAVa8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzk4OTUxMmFiZjBmMTUzMDgzNTMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMTc3LCJleHAiOjQ4OTA3OTExNzd9.Jh4uWgr2p4iFw5wK7E9QGildYOYhDtlnq8MPWMpySsM',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzlhMTUxMmFiZjBmMTUzMDgzODAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMjAxLCJleHAiOjQ4OTA3OTEyMDF9.NrqTV8VEpTey2xjMVDa6NH3M0QG6s8a7mFlyVHB_FuQ',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzliZjUxMmFiZjBmMTUzMDgzYWMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMjMxLCJleHAiOjQ4OTA3OTEyMzF9.QVbNydp6T069wFPQuJtZX5LskNSKgcVyNFrgCicnZHo',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzlkYzUxMmFiZjBmMTUzMDgzZDAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMjYwLCJleHAiOjQ4OTA3OTEyNjB9.xL2XZAKd-zEltJxSVD9zNpSpsdIUQrAG39dGyTUJbuw',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzlmYjUxMmFiZjBmMTUzMDg0MDgiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMjkxLCJleHAiOjQ4OTA3OTEyOTF9.clTZDXariDXO8c2wFwTKFp0xmgkc68h_UHGFL4oodH8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhNzkxMjUxMmFiZjBmMTUzMDgyNjYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMDU4LCJleHAiOjQ4OTA3OTEwNTh9.6vGFkadsmzqoP30X54BTGLNd3ymWKZOLsUXQc5ugg9I',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2EzMzUxMmFiZjBmMTUzMDg0NjMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMzQ3LCJleHAiOjQ4OTA3OTEzNDd9.b31I_HMqhpLvv8DxMXSL2c9PseNHVzVFgxQLBKIUXws',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2E1MTUxMmFiZjBmMTUzMDg0N2UiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxMzc3LCJleHAiOjQ4OTA3OTEzNzd9.v-aX5gASPhgjnVxJqP8qyuJYDygy2L8M5XnAjfm_2y8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2E2ZjUxMmFiZjBmMTUzMDg0YjUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNDA3LCJleHAiOjQ4OTA3OTE0MDd9.rgYg920OFZ4o0_zwRb7sfeZSfFchyO2WiYks_B1Ysmg',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2E4YTUxMmFiZjBmMTUzMDg0ZTAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNDM0LCJleHAiOjQ4OTA3OTE0MzR9.JGagLXKZV5eVi-vOH2A67WKVTD2EAvMxbG8X4-ff0To',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2FhNzUxMmFiZjBmMTUzMDg1M2IiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNDYzLCJleHAiOjQ4OTA3OTE0NjN9.02zTUQwLeAlKprleh-v5zY_7Z1dygJJHnwLzCgY5OvU',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2FjNTUxMmFiZjBmMTUzMDg1NTYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNDkzLCJleHAiOjQ4OTA3OTE0OTN9.s9SGXgRlKELY5ouZYHExQaUfB_3ub9fePzIHLQwzClk',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2FlODUxMmFiZjBmMTUzMDg1NzEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNTI4LCJleHAiOjQ4OTA3OTE1Mjh9.SaMWXsZhT5w9D07V7xxQzorpCWP81a63i4MNHD3w0Ys',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2IwNDUxMmFiZjBmMTUzMDg1OGMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNTU2LCJleHAiOjQ4OTA3OTE1NTZ9.P3iA5RL_eyxmkHN6fzX2MlW42TYAkiDKTzTo_tGgzdE',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2I0YjUxMmFiZjBmMTUzMDg1YmQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNjI3LCJleHAiOjQ4OTA3OTE2Mjd9.ggBnshNc0jzyhPc76y7Y0kwwNBJjm4-5c6Kpp9jb5f0',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2I2NzUxMmFiZjBmMTUzMDg1ZTMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNjU1LCJleHAiOjQ4OTA3OTE2NTV9.l0UyuCPFKKgfgsw1lfdAWEd-coFWPAJyW20RiClkxng',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2I4OTUxMmFiZjBmMTUzMDg1ZmUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNjg5LCJleHAiOjQ4OTA3OTE2ODl9.8h3KTqDuPDypZLT55E6YzZ_9AsZ-H-XQteV9NOWqPCc',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2JhZDUxMmFiZjBmMTUzMDg2MTkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNzI1LCJleHAiOjQ4OTA3OTE3MjV9.sXCM1Vyu-tieAXTqVRFkM_NGWmbpta4P2-vMcKFipSU',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2JjZTUxMmFiZjBmMTUzMDg2NWIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNzU4LCJleHAiOjQ4OTA3OTE3NTh9.IHGRJP17bWXLmTfhrNxcaRLiGxBTqdHRHyIr3Q3dLqI',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2JlZTUxMmFiZjBmMTUzMDg2YjAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxNzkwLCJleHAiOjQ4OTA3OTE3OTB9.nj3x_e4zN50hLs-nNodmYUlKzwktnGApU7wJF9b342s',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2MxMTUxMmFiZjBmMTUzMDg2ZGQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxODI1LCJleHAiOjQ4OTA3OTE4MjV9.kDO3LJVE5HyxY76PGSoA0QX_YsmZ0E_h9AlTWKWXTYk',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2MzMjUxMmFiZjBmMTUzMDg3MmEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxODU4LCJleHAiOjQ4OTA3OTE4NTh9.AUY-krTxCKIWPL71V-n_yTqo3rzYFrSjJ4xXbDTdHUU',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2M1NTUxMmFiZjBmMTUzMDg3ODgiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxODkzLCJleHAiOjQ4OTA3OTE4OTN9.QuRWJ6G4xJZmGQAGc--vtmyb95eIAfAPW85h5NFrtIg',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2M3MzUxMmFiZjBmMTUzMDg3YTMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxOTIzLCJleHAiOjQ4OTA3OTE5MjN9.gukEu3JTTOuWidGkyP2DwBjMsriL0X9jMUvcENuLHdM',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2M5MzUxMmFiZjBmMTUzMDg3YmUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxOTU1LCJleHAiOjQ4OTA3OTE5NTV9.ZEGjVfhl-RWiVgSjgY_omtLXGICdaOaQnwYU4_1u9p0',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2NiNDUxMmFiZjBmMTUzMDg3ZDkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMxOTg4LCJleHAiOjQ4OTA3OTE5ODh9.bPOgG2TU88JVHmsY5PCc1EZQTvhOdJhOA6fSJPLcGA8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2NkNDUxMmFiZjBmMTUzMDg3ZjQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMDIwLCJleHAiOjQ4OTA3OTIwMjB9.Aj1ZxbyngLePI86nA1iKSwRh5Ot1PZCeZ6PX0bvh4nE',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2NmMTUxMmFiZjBmMTUzMDg4MWEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMDQ5LCJleHAiOjQ4OTA3OTIwNDl9.QTANqy6icpymjTO_gYAVXZb9LIoXIV6BWL6PMOAeICk',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2QxMDUxMmFiZjBmMTUzMDg4NTYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMDgwLCJleHAiOjQ4OTA3OTIwODB9.XQNLjn0JxOCbQbG2_uz2_wREgSaMUJayxOB0bgAmQ3g',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2QyZDUxMmFiZjBmMTUzMDg4N2MiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMTA5LCJleHAiOjQ4OTA3OTIxMDl9.fdQbqyprgV1JyB4moSDTO3hn8qtLTPdNMxnlEyHlSeA',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2Q2OTUxMmFiZjBmMTUzMDg4YjkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMTY5LCJleHAiOjQ4OTA3OTIxNjl9.BQNYPnNQmNlwqVG0A6VoZ1YtQRkR3uuhwbPUZTx9N7I',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2Q4NzUxMmFiZjBmMTUzMDg4ZTUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMTk5LCJleHAiOjQ4OTA3OTIxOTl9.PVIoYWPTCauQaO51tWXXySZaeUzT-_c1-rZ9eSX_JaI',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2RhMzUxMmFiZjBmMTUzMDg5MDAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMjI3LCJleHAiOjQ4OTA3OTIyMjd9.YR4fk2gmLifY89oBmZeF5ymEAGu_5Z1o8jq8gdxF7_I',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2RjYTUxMmFiZjBmMTUzMDg5MWIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMjY2LCJleHAiOjQ4OTA3OTIyNjZ9.v3EjdkyFC-M3A9LPZ13l2EPM-38byDcujjbt7WdD2zg',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2RlYTUxMmFiZjBmMTUzMDg5MzYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMjk4LCJleHAiOjQ4OTA3OTIyOTh9.yLnTef0Vt15_cxTmje9uMNWWtevBbPPwA40AnnJnE0Y',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2UwNzUxMmFiZjBmMTUzMDg5NTEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMzI3LCJleHAiOjQ4OTA3OTIzMjd9.BZgpYuArihJ50Rw3FITgQu3WZh5qTebEK5oVngbxn5M',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2UyOTUxMmFiZjBmMTUzMDg5NmMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMzYxLCJleHAiOjQ4OTA3OTIzNjF9.9-GAnXCA-7Idh2HRRw6m4EfufjFmo7RigD6QS8pROJk',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2U0YTUxMmFiZjBmMTUzMDg5ODciLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyMzk0LCJleHAiOjQ4OTA3OTIzOTR9.wrCpNc3llm10LLHQRMnaa667wlYAC7fwv3p8uxM11WU',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2U2ZjUxMmFiZjBmMTUzMDg5YTIiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNDMxLCJleHAiOjQ4OTA3OTI0MzF9.TRZzRy-CSs_w4X9b3Vvd5WVEZLvrRzIyf5ebtZkvwCY',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2U4YjUxMmFiZjBmMTUzMDg5YmQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNDU5LCJleHAiOjQ4OTA3OTI0NTl9.HL54qKwL5Kzdem_9-xX1BkjlpRr2pQy8S3vBewNmvDg',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2VhNjUxMmFiZjBmMTUzMDg5ZDgiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNDg2LCJleHAiOjQ4OTA3OTI0ODZ9.DDj45CK69RMooct_4iKaWVKAVnlke6AUvbOmmKE8TH4',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2VjNjUxMmFiZjBmMTUzMDg5ZjMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNTE4LCJleHAiOjQ4OTA3OTI1MTh9.hC12bj9su6qQ9KeuhAigWqv2N5FF3Lr8Ar6wvhh4Tzk',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2VlMzUxMmFiZjBmMTUzMDhhMGUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNTQ3LCJleHAiOjQ4OTA3OTI1NDd9.-PDFno8ymM9y09rQdwZgbJN3-EPs1zzFB2NGaAkKO7s',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2VmZTUxMmFiZjBmMTUzMDhhMjkiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNTc0LCJleHAiOjQ4OTA3OTI1NzR9.FYPVvHp0q2Yz1vm_z3D7eqN_h85D55o1x125giOtr2o',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2YxOTUxMmFiZjBmMTUzMDhhNDQiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNjAxLCJleHAiOjQ4OTA3OTI2MDF9.vXlmf9F3X8D9eAK4K0-rbbKlaOCGzluebBHFyJuuFvY',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2YzOTUxMmFiZjBmMTUzMDhhNWYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNjMzLCJleHAiOjQ4OTA3OTI2MzN9.BGGadny7_ZdsdTH7K4H6v0FNV9lbfDfL7XVmF3gURqc',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2Y1MjUxMmFiZjBmMTUzMDhhN2EiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNjU4LCJleHAiOjQ4OTA3OTI2NTh9._K5V0QzUl1dmlrEZ4wvXXbS9eRtkaGzDDGIB67oS16E',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2Y2YjUxMmFiZjBmMTUzMDhhOTUiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNjgzLCJleHAiOjQ4OTA3OTI2ODN9.XHZwW06Z_1Eis7-wdB5Dbpt4VoymY6H-SYXQ0rfuV-A',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2Y4NDUxMmFiZjBmMTUzMDhhYjAiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNzA4LCJleHAiOjQ4OTA3OTI3MDh9.iI7l3CSmJAVZ-DD-FO7ofy4Mf6i9PLXVGmVR93BOKB8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2Y5ZDUxMmFiZjBmMTUzMDhhY2IiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNzMzLCJleHAiOjQ4OTA3OTI3MzN9.SRO07W6fchfv-hMxLd1sa4wWazNcOjnqRV3R06NGYsw',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2ZiNjUxMmFiZjBmMTUzMDhhZTYiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNzU4LCJleHAiOjQ4OTA3OTI3NTh9.y3kGyXzwUKN1H6cmtzoO9hC-i-6SFCQWbo67XyL7mQ8',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2ZkMDUxMmFiZjBmMTUzMDhiMDEiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyNzg0LCJleHAiOjQ4OTA3OTI3ODR9.Mal6F8C-thDSXaaYDvBPlAz2VFxj-k_FR9Z6ROBg8ag',													
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzZhN2ZlYzUxMmFiZjBmMTUzMDhiMWMiLCJ0eXBlIjoiYXBpS2V5IiwiaWF0IjoxNzM1MDMyODEyLCJleHAiOjQ4OTA3OTI4MTJ9.E1CJ_ialdZ3HAPCIDOO5TzDuodwBSEea2xst_I9obno',													
            // Add all other tokens from paste.txt here
        ];
        this.tokenManager = new TokenManager(API_TOKENS);
        this.TTS_API_URL = 'https://waves-api.smallest.ai/api/v1';
        
        // Configure retry settings
        this.maxRetries = 3;
        this.retryDelay = 1000; // 1 second
    }

    async initAudioContext() {
        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        }
        if (this.audioContext.state === 'suspended') {
            await this.audioContext.resume();
        }
    }

    async convertTextToSpeech(text, model = 'lightning') {
        let attempts = 0;
        let lastError = null;
        
        while (attempts < this.maxRetries) {
            try {
                const currentToken = this.tokenManager.getCurrentToken();
                console.log(`Attempt ${attempts + 1} using token: ${currentToken.substring(0, 20)}...`);
                
                const response = await fetch(`${this.TTS_API_URL}/${model}/get_speech`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${currentToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        voice_id: "emily",
                        text: text,
                        sample_rate: 24000,
                        add_wav_header: true
                    })
                });

                if (!response.ok) {
                    let shouldRotateToken = false;
                    
                    // Check for specific error responses
                    try {
                        const errorData = await response.json();
                        if (errorData.error === "Credits expired.") {
                            shouldRotateToken = true;
                            lastError = new Error('Credits expired for current token');
                        } else {
                            lastError = new Error(`API error: ${errorData.error}`);
                        }
                    } catch (e) {
                        // If we can't parse the JSON, fall back to status code check
                        if (response.status === 401 || response.status === 403) {
                            shouldRotateToken = true;
                            lastError = new Error(`Authentication failed: ${response.status}`);
                        } else {
                            lastError = new Error(`HTTP error: ${response.status}`);
                        }
                    }

                    if (shouldRotateToken) {
                        console.log(`Token failed, rotating to next token. Status: ${response.status}`);
                        this.tokenManager.markCurrentTokenAsFailed();
                        attempts++;
                        continue;
                    }

                    throw lastError;
                }

                const audioData = await response.arrayBuffer();
                await this.playAudio(audioData);
                console.log('Successfully converted text to speech and played audio');
                return true;

            } catch (error) {
                console.error(`Attempt ${attempts + 1} failed:`, error);
                lastError = error;
                
                // Network errors or other critical failures
                attempts++;
                
                if (attempts < this.maxRetries) {
                    console.log(`Retrying after ${this.retryDelay}ms delay...`);
                    await new Promise(resolve => setTimeout(resolve, this.retryDelay));
                }
            }
        }
        
        throw lastError || new Error('Maximum retry attempts reached');
    }

    async playAudio(audioData) {
        try {
            await this.initAudioContext();
            
            // Add to queue
            this.audioQueue.push(audioData);
            
            // If not already playing, start playing
            if (!this.isPlaying) {
                this.playNextInQueue();
            }
        } catch (error) {
            console.error('Error playing audio:', error);
            throw error;
        }
    }

    async playNextInQueue() {
        if (this.audioQueue.length === 0) {
            this.isPlaying = false;
            return;
        }

        this.isPlaying = true;
        const audioData = this.audioQueue.shift();

        try {
            const audioBuffer = await this.audioContext.decodeAudioData(audioData);
            const source = this.audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(this.audioContext.destination);
            
            source.onended = () => {
                this.playNextInQueue();
            };

            source.start(0);
        } catch (error) {
            console.error('Error playing audio buffer:', error);
            this.playNextInQueue(); // Skip to next in queue if there's an error
        }
    }

    stopAll() {
        this.audioQueue = [];
        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }
        this.isPlaying = false;
    }
}

export default new TTSService();