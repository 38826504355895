import React, { useState, useEffect } from 'react';
import MessagePopup from './MessagePopup';
import webSocketService from '../services/apiws';

const MessagePopupHandler = ({ user }) => {
  const [newMessagePopup, setNewMessagePopup] = useState({
    open: false,
    message: null
  });

  const checkRecentMessages = async () => {
    try {
      const response = await fetch('https://dj.nilconnect.in/api/user-messages/?time_range=10', {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const messages = Array.isArray(data) ? data : [];

      // Filter messages from the last 10 minutes
      const tenMinutesAgo = new Date(Date.now() - 10 * 60 * 1000);
      const recentMessages = messages.filter(msg => {
        const messageDate = new Date(msg.created_at);
        return !msg.is_read && messageDate > tenMinutesAgo;
      });

      // Show the most recent unread message if it exists
      const mostRecentMessage = recentMessages[0];
      if (mostRecentMessage) {
        setNewMessagePopup({
          open: true,
          message: mostRecentMessage
        });
      }
    } catch (error) {
      console.error('Error checking recent messages:', error);
    }
  };

  useEffect(() => {
    // Check for recent messages when component mounts
    checkRecentMessages();

    // Set up WebSocket listener for new messages
    console.log('Setting up WebSocket listener');
    const unsubscribe = webSocketService.addListener((data) => {
      console.log('WebSocket message received:', data);
      
      if (data.type === 'notification' && data.notification) {
        // For new messages via WebSocket, we don't need to check the time
        // as they are always current
        setNewMessagePopup({
          open: true,
          message: data.notification
        });
      }
    });

    // Try to reconnect WebSocket if not connected
    if (!webSocketService.isConnected()) {
      webSocketService.connect().catch(error => {
        console.error('Error connecting to WebSocket:', error);
      });
    }

    return () => {
      if (unsubscribe) {
        console.log('Cleaning up WebSocket listener');
        unsubscribe();
      }
    };
  }, []);

  // Add reconnect handler for page visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && !webSocketService.isConnected()) {
        console.log('Page became visible, checking recent messages and reconnecting');
        checkRecentMessages();
        webSocketService.connect().catch(error => {
          console.error('Error reconnecting to WebSocket:', error);
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('online', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('online', handleVisibilityChange);
    };
  }, []);

  const handleCloseMessagePopup = async () => {
    try {
      if (newMessagePopup.message?.id && !newMessagePopup.message?.is_read) {
        // Mark the message as read using WebSocket
        await webSocketService.markAsRead(newMessagePopup.message.id);
        
        // Also mark it as read through the API to ensure consistency
        await fetch(`https://dj.nilconnect.in/api/user-messages/${newMessagePopup.message.id}/mark_read/`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        });
      }
    } catch (error) {
      console.error('Error marking message as read:', error);
    } finally {
      setNewMessagePopup({
        open: false,
        message: null
      });
    }
  };

  return (
    <MessagePopup
      open={newMessagePopup.open}
      message={newMessagePopup.message}
      onClose={handleCloseMessagePopup}
    />
  );
};

export default MessagePopupHandler;