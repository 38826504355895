import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, Paper, styled, Typography, CircularProgress, List, ListItem, ListItemAvatar,
  ListItemText, ListItemIcon, Avatar, Button, Divider, Dialog, DialogTitle, DialogContent, 
  DialogActions, TextField, Chip, useMediaQuery, useTheme, Autocomplete, IconButton, 
  Tooltip, Snackbar, Alert, Drawer, AppBar, Toolbar, InputBase, Badge
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArchiveIcon from '@mui/icons-material/Archive';
import FolderIcon from '@mui/icons-material/Folder';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import debounce from 'lodash/debounce';

import {
  listInboxMessages,
  listSentMessages,
  sendMessage,
  markMessageAsRead,
  getMessage,
  listUsers,
  getCurrentUser,
  pinMessage,
  unpinMessage,
  softDeleteMessage,
  getConversation,
  listPinnedMessages,
} from '../api';

dayjs.extend(relativeTime);

// Styled Components
// Updated styled components
const EmailContainer = styled(Box)(({ theme }) => ({
  height: '90%',
  display: 'flex',
  flexDirection: 'column',
  background: 'transparent',
  color: theme.palette.common.white,
}));

const MainContent = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
});

const Sidebar = styled(Box)(({ theme }) => ({
  width: 256,
  background: '#0F2744',
  borderRight: '1px solid rgba(255, 255, 255, 0.08)',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const EmailList = styled(Box)({
  width: 360,
  background: '#0F2744',
  borderRight: '1px solid rgba(255, 255, 255, 0.08)',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const EmailDetail = styled(Box)({
  flexGrow: 1,
  background: '#0F2744',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

const ComposeButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(1.5, 3),
  backgroundColor: '#4B8BF4',
  color: 'white',
  borderRadius: '28px',
  textTransform: 'none',
  fontSize: '0.9375rem',
  fontWeight: 500,
  boxShadow: '0 1px 2px 0 rgba(0,0,0,0.1)',
  '&:hover': {
    backgroundColor: '#3B78E7',
  },
  '& .MuiButton-startIcon': {
    marginRight: 8,
  },
  position: 'relative', // Add this
  zIndex: 1, // Add this
}));

const SearchBar = styled(Box)(({ theme }) => ({
  padding: '12px 16px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
  background: '#0F2744',
  zIndex: 1,
  gap: '8px', // Add gap between elements
}));

// Update the StyledSearchInput to accommodate the refresh button
const StyledSearchInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  color: 'inherit',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: 8,
  padding: '8px 12px',
  paddingLeft: 36,
  fontSize: '0.9375rem',
  width: '100%',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  '& input::placeholder': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
}));

const SearchIconWrapper = styled('div')({
  position: 'absolute',
  left: 12,
  top: '50%',
  transform: 'translateY(-50%)',
  color: 'rgba(255, 255, 255, 0.5)',
  display: 'flex',
  alignItems: 'center',
});

const FolderItem = styled(ListItem)(({ theme, selected }) => ({
  borderRadius: 0,
  margin: '2px 8px',
  padding: '8px 12px',
  color: selected ? '#4B8BF4' : 'rgba(255, 255, 255, 0.85)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  '& .MuiListItemIcon-root': {
    color: 'inherit',
    minWidth: 40,
  },
  '& .MuiTypography-root': {
    fontSize: '0.9375rem',
    fontWeight: selected ? 500 : 400,
  },
}));

const EmailListItem = styled(ListItem)(({ theme, selected, unread }) => ({
  borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
  padding: '12px 16px',
  backgroundColor: selected ? 'rgba(75, 139, 244, 0.08)' : 'transparent',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: selected ? 'rgba(75, 139, 244, 0.12)' : 'rgba(255, 255, 255, 0.03)',
  },
  userSelect: 'none', // Add this
  WebkitUserSelect: 'none', // Add this
}));

const Header = styled(Box)(({ theme }) => ({
  padding: '12px 24px',
  borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 64,
}));






// ComposeMessage Component
const ComposeDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: '#0F2744',
    maxWidth: 800,
    width: '100%',
    margin: 16,
    borderRadius: 12,
  },
}));

const ComposeMessage = ({
  open,
  onClose,
  onSend,
  replyTo,
  userList,
  currentUserRole,
  onSearchChange,
  onLoadMore,
  nextPage,
  isSearching,
  inputValue,
}) => {
  const [message, setMessage] = useState({
    recipient: null,
    subject: '',
    body: '',
  });
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (replyTo) {
      setMessage({
        ...message,
        recipient: replyTo.sender,
        subject: `Re: ${replyTo.subject}`,
        body: `\n\nOn ${dayjs(replyTo.created_at).format('MMM D, YYYY h:mm A')}, ${
          replyTo.sender.full_name
        } wrote:\n> ${replyTo.body}`,
      });
    }
  }, [replyTo]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setAttachments(prev => [...prev, ...files]);
    // Reset the input value to allow selecting the same file again
    event.target.value = '';
  };

  const handleDeleteAttachment = (indexToDelete) => {
    setAttachments(prev => prev.filter((_, index) => index !== indexToDelete));
  };

  const handleClose = () => {
    setMessage({ recipient: null, subject: '', body: '' });
    setAttachments([]);
    onClose();
  };

  

  const handleSend = () => {
    if (!message.recipient || (!message.body.trim() && attachments.length === 0)) {
      return;
    }

    const formData = new FormData();
    formData.append('recipient', message.recipient.id);
    formData.append('subject', message.subject);
    formData.append('body', message.body);

    if (replyTo?.id) {
      formData.append('parent_message', replyTo.id);
    }

    // Properly append attachments
    attachments.forEach((file) => {
      formData.append('attachments', file);
    });

    // Debug log
    console.log("Sending FormData with attachments:");
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(`${key}: File - ${value.name} (${value.type}, ${value.size} bytes)`);
      } else {
        console.log(`${key}: ${value}`);
      }
    }

    onSend(formData);
    handleClose();
  };

  return (
    <ComposeDialog 
      open={open} 
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        padding: '16px 24px',
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center'
        }}>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {replyTo ? 'Reply to Message' : 'New Message'}
          </Typography>
          <IconButton onClick={handleClose} size="small" sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ padding: '24px' }}>
        <Autocomplete
          options={userList}
          getOptionLabel={(option) => `${option.full_name} (${option.email})`}
          value={message.recipient}
          onChange={(_, newValue) => setMessage({ ...message, recipient: newValue })}
          onInputChange={(_, newValue, reason) => {
            if (reason === 'input') {
              onSearchChange(newValue);
            }
          }}
          inputValue={inputValue}
          loading={isSearching}
          disabled={!!replyTo}
          filterOptions={(x) => x}
          renderOption={(props, option) => (
            <Box 
              component="li" 
              {...props} 
              key={option.id}
              sx={{ 
                color: 'white',
                py: 1,
                px: 2,
                '&:hover': {
                  backgroundColor: 'rgba(75, 139, 244, 0.08)',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1">
                  {option.full_name}
                </Typography>
                <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {option.email}
                </Typography>
              </Box>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="To"
              margin="normal"
              fullWidth
              InputLabelProps={{ 
                sx: { color: 'rgba(255, 255, 255, 0.7)' } 
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.2)' },
                  '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                  '&.Mui-focused fieldset': { borderColor: '#4B8BF4' },
                },
              }}
            />
          )}
          noOptionsText={
            inputValue && inputValue.length < 2 
              ? "Type at least 2 characters to search" 
              : "No users found"
          }
          ListboxProps={{
            sx: {
              bgcolor: '#0F2744',
              '& .MuiAutocomplete-option': {
                '&:hover': {
                  bgcolor: 'rgba(75, 139, 244, 0.08)',
                },
                '&[aria-selected="true"]': {
                  bgcolor: 'rgba(75, 139, 244, 0.15)',
                },
              },
            },
          }}
        />

        <TextField
          label="Subject"
          value={message.subject}
          onChange={(e) => setMessage({ ...message, subject: e.target.value })}
          margin="normal"
          fullWidth
          InputLabelProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
          sx={{
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.2)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&.Mui-focused fieldset': { borderColor: '#4B8BF4' },
            },
          }}
        />

        <TextField
          label="Message"
          value={message.body}
          onChange={(e) => setMessage({ ...message, body: e.target.value })}
          multiline
          rows={8}
          margin="normal"
          fullWidth
          InputLabelProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
          sx={{
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.2)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&.Mui-focused fieldset': { borderColor: '#4B8BF4' },
            },
          }}
        />

        <Box sx={{ mt: 2 }}>
          <input
            accept="*/*"
            style={{ display: 'none' }}
            id="compose-file-input"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="compose-file-input">
            <Button
              component="span"
              variant="outlined"
              startIcon={<AttachFileIcon />}
              sx={{
                color: 'white',
                borderColor: 'rgba(255, 255, 255, 0.2)',
                '&:hover': {
                  borderColor: 'white',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                },
              }}
            >
              Attach Files
            </Button>
          </label>

          <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {attachments.map((file, index) => (
              <Chip
                key={index}
                label={file.name}
                onDelete={() => handleDeleteAttachment(index)}
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  '& .MuiChip-deleteIcon': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': { color: 'white' },
                  },
                }}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ 
        padding: '16px 24px', 
        borderTop: '1px solid rgba(255, 255, 255, 0.08)'
      }}>
        <Button
          onClick={handleClose}
          sx={{ 
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.05)' },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSend}
          variant="contained"
          disabled={!message.recipient || (!message.body.trim() && attachments.length === 0)}
          sx={{
            backgroundColor: '#4B8BF4',
            '&:hover': { backgroundColor: '#3B78E7' },
            '&.Mui-disabled': { backgroundColor: 'rgba(75, 139, 244, 0.3)' },
          }}
          startIcon={<SendIcon />}
        >
          Send
        </Button>
      </DialogActions>
    </ComposeDialog>
  );
};

// ReplyBox Component
const ReplyBox = ({ onSend, currentUserId, conversation }) => {
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const fileInputRef = useRef(null);

  const handleSend = async () => {
    if (message.trim() || attachments.length > 0) {
      const formData = new FormData();
      formData.append('body', message);
      formData.append('parent_message', conversation.id);
      
      // Get subject from conversation
      const subject = conversation.messages?.[0]?.subject || conversation.subject;
      formData.append('subject', `Re: ${subject}`);
      
      // Get recipient ID
      const recipientId = conversation.sender.id === currentUserId 
        ? conversation.recipient.id 
        : conversation.sender.id;
      formData.append('recipient', recipientId);

      // Properly append each file to formData
      attachments.forEach((file) => {
        formData.append('attachments', file);
    });

      // Debug log to verify FormData contents
      for (let [key, value] of formData.entries()) {
        if (value instanceof File) {
          console.log(`${key}: File - ${value.name} (${value.type}, ${value.size} bytes)`);
        } else {
          console.log(`${key}: ${value}`);
        }
      }

      try {
        await onSend(formData);
        setMessage('');
        setAttachments([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Reset file input
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files || []);
    // Validate files before adding
    const validFiles = files.filter(file => {
      // Add any file validation logic here
      return file.size <= 10 * 1024 * 1024; // Example: 10MB limit
    });
    
    setAttachments(prev => [...prev, ...validFiles]);
    
    // Reset file input to allow selecting the same file again
    if (event.target) {
      event.target.value = '';
    }
  };

  const handleDeleteAttachment = (indexToDelete) => {
    setAttachments(prev => prev.filter((_, index) => index !== indexToDelete));
  };

  return (
    <Box mt={2} p={2} bgcolor="rgba(255, 255, 255, 0.05)" borderRadius={2}>
      <Box mb={2}>
        {attachments.map((file, index) => (
          <Chip
            key={index}
            label={`${file.name} (${(file.size / 1024).toFixed(1)}KB)`}
            onDelete={() => handleDeleteAttachment(index)}
            sx={{
              marginRight: 1,
              marginBottom: 1,
              background: 'rgba(255, 255, 255, 0.1)',
              color: 'white',
              '& .MuiChip-deleteIcon': {
                color: 'rgba(255, 255, 255, 0.7)',
                '&:hover': { color: 'white' },
              },
            }}
          />
        ))}
      </Box>
      <Box display="flex" alignItems="center">
        <InputBase
          fullWidth
          multiline
          rows={2}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          sx={{ 
            mr: 1, 
            color: 'white',
            '::placeholder': { 
              color: 'rgba(255, 255, 255, 0.5)',
              opacity: 1
            }
          }}
        />
        <input
          ref={fileInputRef}
          accept="*/*"
          style={{ display: 'none' }}
          id="reply-file-input"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="reply-file-input">
          <IconButton component="span" color="primary">
            <AttachFileIcon />
          </IconButton>
        </label>
        <IconButton 
          color="primary" 
          onClick={handleSend}
          disabled={!message.trim() && attachments.length === 0}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

// Main MessagingComponent
const MessagingComponent = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [composeOpen, setComposeOpen] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userList, setUserList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFolder, setActiveFolder] = useState('inbox');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const messagesEndRef = useRef(null);
  const [conversationLocations, setConversationLocations] = useState(new Map());
  const [userRole, setUserRole] = useState(null); 

  
  const [mobileView, setMobileView] = useState('list'); 
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  

  const handleSelectConversation = useCallback(async (conversation) => {
    if (!conversation) return;
    
    try {
      let updatedConversation = { ...conversation };
      let markReadPromises = [];
      let shouldUpdateLocation = false;
  
      // Mark main message as read if user is recipient
      if (!conversation.read_at && conversation.recipient?.id === currentUser?.id) {
        markReadPromises.push(markMessageAsRead(conversation.id));
        updatedConversation.read_at = new Date().toISOString();
        shouldUpdateLocation = true;
      }
  
      // Mark unread replies as read
      if (conversation.replies?.length > 0) {
        updatedConversation.replies = conversation.replies.map(reply => {
          if (!reply.read_at && reply.recipient?.id === currentUser?.id) {
            markReadPromises.push(markMessageAsRead(reply.id));
            shouldUpdateLocation = true;
            return { ...reply, read_at: new Date().toISOString() };
          }
          return reply;
        });
      }
  
      // Wait for all mark-as-read operations
      if (markReadPromises.length > 0) {
        await Promise.all(markReadPromises);
      }
  
      // Update conversation location if needed
      if (shouldUpdateLocation && conversation.sender?.id === currentUser?.id) {
        const newLocations = new Map(conversationLocations);
        newLocations.set(conversation.id, checkUnreadStatus(updatedConversation) ? 'inbox' : 'sent');
        setConversationLocations(newLocations);
      }
  
      setConversations(prev => prev.map(conv => 
        conv.id === conversation.id ? updatedConversation : conv
      ));
      
      setSelectedConversation(updatedConversation);
      if (isMobile) {
        setMobileView('conversation');
      }
    } catch (error) {
      console.error('Error selecting conversation:', error);
      setError('Failed to load conversation');
    }
  }, [currentUser?.id, isMobile, conversationLocations]);

  const getUnreadCount = useCallback((conversations) => {
    if (!currentUser) return 0;
  
    return conversations.reduce((count, conversation) => {
      let conversationUnreadCount = 0;
      
      // Check if main message is unread and user is recipient
      if (!conversation.read_at && conversation.recipient?.id === currentUser.id) {
        conversationUnreadCount++;
      }
      
      // Check for unread replies where user is recipient
      if (conversation.replies?.length > 0) {
        const unreadReplies = conversation.replies.filter(reply => 
          !reply.read_at && reply.recipient?.id === currentUser.id
        );
        conversationUnreadCount += unreadReplies.length;
      }
  
      // If there are any unread messages in this conversation, increment the count by 1
      return count + (conversationUnreadCount > 0 ? 1 : 0);
    }, 0);
  }, [currentUser]);
  
  const fetchConversations = useCallback(async () => {
    setLoading(true);
    try {
      let response;
      let fetchedConversations = [];
      
      // Helper to get the latest message in a conversation (either original or reply)
      const getLatestMessage = (conv) => {
        if (!conv.replies || conv.replies.length === 0) return conv;
        return conv.replies[conv.replies.length - 1];
      };
  
      // Helper to check if we've replied to the latest message
      const hasRepliedToLatest = (conv) => {
        const latestMessage = getLatestMessage(conv);
        return latestMessage.sender?.id === currentUser?.id;
      };
  
      // Helper to check if all messages are read
      const allMessagesRead = (conv) => {
        // Check main message
        if (!conv.read_at && conv.recipient?.id === currentUser?.id) return false;
        
        // Check replies
        return !conv.replies?.some(reply => 
          !reply.read_at && reply.recipient?.id === currentUser?.id
        );
      };
  
      if (activeFolder === 'sent') {
        // Get sent messages
        const sentResponse = await listSentMessages();
        let sentMessages = Array.isArray(sentResponse.data) ? sentResponse.data : [sentResponse.data];
        
        // Keep in sent only if:
        // 1. All messages are read AND
        // 2. User has replied to the latest message
        sentMessages = sentMessages.filter(conv => {
          const isRead = allMessagesRead(conv);
          const hasReplied = hasRepliedToLatest(conv);
          return isRead && hasReplied;
        });
        
        fetchedConversations = sentMessages;
      } 
      else if (activeFolder === 'inbox') {
        // Get inbox messages
        const inboxResponse = await listInboxMessages();
        const inboxMessages = Array.isArray(inboxResponse.data) ? inboxResponse.data : [inboxResponse.data];
        
        // Get sent messages
        const sentResponse = await listSentMessages();
        const sentMessages = Array.isArray(sentResponse.data) ? sentResponse.data : [sentResponse.data];
        
        // Include sent messages in inbox if:
        // 1. Has unread messages OR
        // 2. Latest message is not from current user
        const sentToInbox = sentMessages.filter(conv => {
          const isRead = allMessagesRead(conv);
          const hasReplied = hasRepliedToLatest(conv);
          return !isRead || !hasReplied;
        });
        
        fetchedConversations = [...inboxMessages, ...sentToInbox];
      }
      else if (activeFolder === 'pinned') {
        response = await listPinnedMessages();
        fetchedConversations = Array.isArray(response.data) ? response.data : [response.data];
      }
  
      // Sort conversations by latest activity
      fetchedConversations = fetchedConversations
        .filter(Boolean)
        .sort((a, b) => {
          const getLatestTimestamp = (conv) => {
            const replies = conv.replies || [];
            const latestReply = replies.length > 0 ? 
              new Date(replies[replies.length - 1].created_at) : 
              new Date(conv.created_at);
            return latestReply;
          };
  
          const aLatest = getLatestTimestamp(a);
          const bLatest = getLatestTimestamp(b);
          return bLatest - aLatest;
        });
  
      // Sort replies chronologically
      fetchedConversations = fetchedConversations.map(conv => ({
        ...conv,
        replies: (conv.replies || []).sort((a, b) => 
          new Date(a.created_at) - new Date(b.created_at)
        )
      }));
  
      setConversations(fetchedConversations);
  
      if (!selectedConversation && !isMobile && fetchedConversations.length > 0) {
        handleSelectConversation(fetchedConversations[0]);
      }
    } catch (err) {
      console.error('Error fetching conversations:', err);
      setError('Failed to fetch conversations');
    } finally {
      setLoading(false);
    }
  }, [activeFolder, currentUser?.id, isMobile, handleSelectConversation]);


  const checkUnreadStatus = (conversation) => {
    if (!currentUser || !conversation) return false;
  
    const hasUnreadMainMessage = !conversation.read_at && 
      conversation.recipient?.id === currentUser.id;
  
    const hasUnreadReplies = conversation.replies?.some(reply => 
      !reply.read_at && reply.recipient?.id === currentUser.id
    );
  
    return hasUnreadMainMessage || hasUnreadReplies;
  };
  
  // Modified handleSendMessage function
  const handleSendMessage = async (formData) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
  
      const response = await sendMessage(formData, config);
      
      // Update conversation location after sending a reply
      const parentMessageId = formData.get('parent_message');
      if (parentMessageId) {
        const updatedConversation = await getConversation(parentMessageId);
        setSelectedConversation(updatedConversation.data);
        
        // Move conversation back to sent folder after reply
        const newLocations = new Map(conversationLocations);
        newLocations.set(parentMessageId, 'sent');
        setConversationLocations(newLocations);
      } else {
        // New conversation
        setConversations(prev => [response.data, ...prev]);
      }
  
      setSnackbar({ 
        open: true, 
        message: 'Message sent successfully.', 
        severity: 'success' 
      });
      
      setComposeOpen(false);
      await fetchConversations();
      
    } catch (error) {
      console.error('Error sending message:', error);
      let errorMessage = 'Failed to send message. Please try again.';
      
      if (error.response?.data) {
        errorMessage = typeof error.response.data === 'object'
          ? Object.entries(error.response.data)
              .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
              .join('; ')
          : error.response.data;
      }
      
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    }
  };
  
  useEffect(() => {
    console.log('Selected conversation changed:', selectedConversation);
  }, [selectedConversation]);
  

  
  
  // Only fetch when folder changes
  useEffect(() => {
    const initializeData = async () => {
      try {
        // Load user first
        const userResponse = await getCurrentUser();
        setCurrentUser(userResponse.data);
        setUserRole(userResponse.data?.role); // Set the user role
        console.log('User data:', userResponse.data); // Debug log

        // Then load initial user list
        const usersResponse = await listUsers();
        setUserList(usersResponse.data?.results || []);
        setNextPage(usersResponse.data?.links?.next || null);

        // Finally load conversations
        await fetchConversations();
      } catch (error) {
        console.error('Error in initialization:', error);
        setError('Failed to initialize');
      }
    };

    initializeData();
  }, []); // Empty dependency array for initialization

  // 4. Separate useEffect for folder changes
  useEffect(() => {
    if (!loading) {
      fetchConversations();
    }
  }, [activeFolder]);

  useEffect(() => {
    const loadInitialUserList = async () => {
      try {
        const usersResponse = await listUsers();
        setUserList(usersResponse.data.results || []);
        setNextPage(usersResponse.data.links?.next || null);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    loadInitialUserList();
  }, []);
  

  const fetchUserList = useCallback(
    async (url = null, search = '') => {
      if (!search || search.length < 2) {
        setUserList([]);
        setNextPage(null);
        setIsSearching(false);
        return;
      }

      try {
        setIsSearching(true);
        let response;
        
        if (url) {
          response = await fetch(url);
          const data = await response.json();
          setUserList(prev => [...prev, ...data.results]);
          setNextPage(data.links?.next);
        } else {
          const result = await listUsers({ search });
          // Make sure we're getting the results from the correct part of the response
          const users = result.data?.results || result.results || [];
          setUserList(users);
          setNextPage(result.data?.links?.next || result.links?.next);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setUserList([]);
        setNextPage(null);
      } finally {
        setIsSearching(false);
      }
    },
    []
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await listUsers();
        setUserList(response.data.results || []);
        setNextPage(response.data.links?.next || null);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    getCurrentUser().then(response => setCurrentUser(response.data));
    fetchUsers();
    fetchConversations();
  }, [fetchConversations]);

 

  // Update the handleSendMessage function in MessagingComponent
// Update the handleSendMessage function



  const handlePinMessage = async (id, isPinned) => {
    try {
      if (isPinned) {
        await pinMessage(id);
      } else {
        await unpinMessage(id);
      }
      await fetchConversations();
      setSnackbar({
        open: true,
        message: `Message ${isPinned ? 'pinned' : 'unpinned'} successfully`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating pin status:', error);
      setSnackbar({
        open: true,
        message: `Failed to ${isPinned ? 'pin' : 'unpin'} message`,
        severity: 'error'
      });
    }
  };

  const handleDeleteMessage = async (id) => {
    try {
      await softDeleteMessage(id);
      setSnackbar({ open: true, message: 'Message deleted', severity: 'success' });
      fetchConversations();
      if (selectedConversation?.id === id) {
        setSelectedConversation(null);
      }
    } catch (error) {
      console.error('Error deleting message:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete message',
        severity: 'error'
      });
    }
  };

  const handleUserSearch = useCallback(
    debounce((searchTerm) => {
      console.log('Searching for:', searchTerm); // Debug log
      fetchUserList(null, searchTerm);
    }, 300),
    [fetchUserList]
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleComposeSearchChange = (newValue) => {
    setInputValue(newValue);
    if (newValue.length >= 2) {
      setIsSearching(true);
      handleUserSearch(newValue);
    } else {
      setUserList([]);
      setNextPage(null);
      setIsSearching(false);
    }
  };

  const renderSidebar = () => (
    <Sidebar>
      <ComposeButton
        startIcon={<AddIcon />}
        onClick={() => {
          setReplyTo(null);
          setComposeOpen(true);
        }}
      >
        Compose
      </ComposeButton>
  
      <List component="nav" sx={{ px: 1 }}>
        <FolderItem
          button
          selected={activeFolder === 'inbox'}
          onClick={() => setActiveFolder('inbox')}
        >
          <ListItemIcon>
            <Badge 
              badgeContent={getUnreadCount(conversations)}
              color="primary"
            >
              <MailIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Inbox" />
        </FolderItem>

        <FolderItem
          button
          selected={activeFolder === 'sent'}
          onClick={() => setActiveFolder('sent')}
        >
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary="Sent" />
        </FolderItem>

        <FolderItem
          button
          selected={activeFolder === 'pinned'}
          onClick={() => setActiveFolder('pinned')}
        >
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Pinned" />
        </FolderItem>
      </List>
    </Sidebar>
  );

  const renderEmailList = () => (
    <EmailList>
      <SearchBar>
        <SearchIconWrapper>
          <SearchIcon fontSize="small" />
        </SearchIconWrapper>
        <StyledSearchInput
          placeholder="Search messages..."
          onChange={handleSearchChange}
        />
        <IconButton
          onClick={fetchConversations}
          sx={{ 
            color: 'rgba(255, 255, 255, 0.7)',
            padding: '8px',
            '&:hover': {
              color: 'white',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            }
          }}
          size="small"
        >
          <RefreshIcon fontSize="small" />
        </IconButton>
      </SearchBar>
  
      {loading ? (
        <Box display="flex" justifyContent="center" p={4}>
          <CircularProgress />
        </Box>
      ) : (
        <List sx={{ padding: 0, overflow: 'auto', height: '100%' }}>
          {conversations.map((conversation) => {
            console.log('Conversation:', conversation); // Add this debug log
            const isUserSender = conversation.sender?.id === currentUser?.id;
            const displayName = isUserSender 
              ? conversation.recipient?.full_name 
              : conversation.sender?.full_name;
  
          // Check if there are any unread messages in the conversation
          const hasUnreadContent = (
            (!conversation.read_at && conversation.recipient?.id === currentUser?.id) ||
            conversation.replies?.some(reply => 
              !reply.read_at && reply.recipient?.id === currentUser?.id
            )
          );
  
          return (
            <EmailListItem
              key={conversation.id}
              onClick={() => {
                console.log('Clicked conversation:', conversation.id); // Add this debug log
                handleSelectConversation(conversation);
              }}
              selected={selectedConversation?.id === conversation.id}
              button // Add this prop
              sx={{ cursor: 'pointer' }} // Add this style
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 0.5 
                  }}>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        fontWeight: conversation.read_at ? 400 : 600,
                        color: 'white'
                      }}
                    >
                      {displayName}
                    </Typography>
                    <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
                      {dayjs(conversation.created_at).fromNow()}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body2"
                    sx={{
                      color: conversation.read_at ? 'rgba(255, 255, 255, 0.7)' : 'white',
                      fontWeight: conversation.read_at ? 400 : 500,
                      mb: 0.5
                    }}
                  >
                    {conversation.subject}
                  </Typography>

                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between' 
                  }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'rgba(255, 255, 255, 0.5)',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '70%'
                      }}
                    >
                      {conversation.body}
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      {conversation.is_pinned && (
                        <StarIcon 
                          fontSize="small" 
                          sx={{ color: '#4B8BF4' }} 
                        />
                      )}
                      {conversation.attachments?.length > 0 && (
                        <AttachFileIcon 
                          fontSize="small" 
                          sx={{ color: 'rgba(255, 255, 255, 0.5)' }} 
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </EmailListItem>
            );
          })}
        </List>
      )}
    </EmailList>
  );

  const getFullUrl = (url) => {
    if (!url) return '';
    // Check if it's already a full URL
    if (url.startsWith('http')) {
      return url;
    }
    // Add the base URL if it's a relative path
    return `https://dj.nilconnect.in${url}`;
  };

  // Update the renderMessageContent function with proper null checks
  const renderMessageContent = () => {
    if (!selectedConversation) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          color: 'rgba(255, 255, 255, 0.5)'
        }}>
          <MailIcon sx={{ fontSize: 48, mb: 2 }} />
          <Typography variant="h6">Select a message to read</Typography>
        </Box>
      );
    }

    const renderAttachments = (attachments = []) => {
      if (!attachments || attachments.length === 0) return null;
    
      return (
        <Box mt={2} sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: 1,
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: 1,
          p: 2
        }}>
          {attachments.map((attachment, index) => {
            if (!attachment) return null;
            
            const isImage = attachment.file_name?.match(/\.(jpeg|jpg|gif|png)$/i);
            const fullUrl = getFullUrl(attachment.file);
    
            return (
              <Box key={index}>
                {isImage ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Box
                      component="img"
                      src={fullUrl}
                      alt={attachment.file_name}
                      sx={{
                        maxWidth: 200,
                        maxHeight: 200,
                        borderRadius: 1,
                        objectFit: 'cover'
                      }}
                    />
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      {attachment.file_name}
                    </Typography>
                  </Box>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={() => window.open(fullUrl, '_blank')}
                    sx={{
                      color: 'white',
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      '&:hover': {
                        borderColor: 'white',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)'
                      }
                    }}
                  >
                    {attachment.file_name}
                  </Button>
                )}
              </Box>
            );
          })}
        </Box>
      );
    };

  const sender = selectedConversation.sender || {};
  const recipient = selectedConversation.recipient || {};

  const allMessages = [
    { ...selectedConversation, isOriginal: true },
    ...(selectedConversation.replies || []).map(reply => ({ ...reply, isOriginal: false }))
  ].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ 
        p: 3, 
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        background: 'rgba(15, 39, 68, 0.7)'
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {selectedConversation.subject || 'No Subject'}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title={selectedConversation.is_pinned ? "Unpin" : "Pin"}>
              <IconButton
                onClick={() => handlePinMessage(selectedConversation.id, !selectedConversation.is_pinned)}
                sx={{ 
                  color: selectedConversation.is_pinned ? '#4B8BF4' : 'rgba(255, 255, 255, 0.7)'
                }}
              >
                <StarIcon />
              </IconButton>
            </Tooltip>
            {userRole === 'admin' && (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => handleDeleteMessage(selectedConversation.id)}
                  sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ 
        flexGrow: 1, 
        overflowY: 'auto',
        p: 3,
        backgroundColor: 'rgba(15, 39, 68, 0.3)'
      }}>
        {allMessages.map((message, index) => (
          <Box
            key={message.id}
            sx={{
              mt: index === 0 ? 0 : 3,
              p: 2,
              borderRadius: 1,
              backgroundColor: 'rgba(15, 39, 68, 0.5)',
              border: '1px solid rgba(255, 255, 255, 0.08)'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 2,
              mb: 1.5
            }}>
              <Avatar 
                sx={{ 
                  bgcolor: '#4B8BF4',
                  width: 32,
                  height: 32
                }}
              >
                {message.sender?.full_name?.[0] || '?'}
              </Avatar>
              <Box>
                <Typography sx={{ color: 'white' }}>
                  {message.sender?.full_name || 'Unknown Sender'}
                </Typography>
                <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {dayjs(message.created_at).format('MMM D, YYYY h:mm A')}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ whiteSpace: 'pre-wrap', color: 'white', ml: 6 }}>
              {message.body || 'No message content'}
            </Box>

            {message.attachments?.length > 0 && renderAttachments(message.attachments)}
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      <ReplyBox 
        onSend={handleSendMessage} 
        currentUserId={currentUser?.id} 
        conversation={selectedConversation}
      />
    </Box>
  );
};

  return (
    <EmailContainer>
      <MainContent>
        {renderSidebar()}
        {renderEmailList()}
        <EmailDetail>
          {renderMessageContent()}
        </EmailDetail>
      </MainContent>

      <ComposeMessage
        open={composeOpen}
        onClose={() => {
          setComposeOpen(false);
          setReplyTo(null);
          setInputValue('');
        }}
        onSend={handleSendMessage}
        replyTo={replyTo}
        userList={userList}
        currentUserRole={currentUser?.role}
        onSearchChange={handleComposeSearchChange}
        onLoadMore={() => {
          if (nextPage) {
            fetchUserList(nextPage, searchTerm);
          }
        }}
        nextPage={nextPage}
        isSearching={isSearching}
        inputValue={inputValue}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            backgroundColor: snackbar.severity === 'success' ? '#1E4620' : '#391111',
            color: 'white',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </EmailContainer>
  );
};

ComposeMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  replyTo: PropTypes.object,
  userList: PropTypes.array.isRequired,
  currentUserRole: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  nextPage: PropTypes.string,
  isSearching: PropTypes.bool.isRequired,
  inputValue: PropTypes.string,
};

ReplyBox.propTypes = {
  onSend: PropTypes.func.isRequired,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  conversation: PropTypes.object.isRequired,
};

MessagingComponent.propTypes = {
  userRole: PropTypes.oneOf(['admin', 'manager', 'trainer', 'user']),
};


export default MessagingComponent;