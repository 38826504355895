import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  IconButton,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Line } from 'recharts';
import { Delete, Refresh } from '@mui/icons-material';
import dayjs from 'dayjs';

import { getChatAnalytics, deleteOldRecords } from '../api';

const AnalyticsPage = () => {
  const [analytics, setAnalytics] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDate, setDeleteDate] = useState(dayjs().subtract(30, 'day'));

  const fetchAnalytics = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getChatAnalytics({
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD')
      });
      setAnalytics(data);
    } catch (err) {
      setError('Failed to fetch analytics data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const handleDelete = async () => {
    try {
      await deleteOldRecords(deleteDate.format('YYYY-MM-DD'));
      setShowDeleteDialog(false);
      fetchAnalytics(); // Refresh data after deletion
    } catch (err) {
      setError('Failed to delete old records');
      console.error(err);
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ color: 'white', mb: 2 }}>
          Chat Analytics
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <IconButton onClick={() => setShowDeleteDialog(true)} color="error">
            <Delete />
          </IconButton>
          <IconButton onClick={fetchAnalytics} color="primary">
            <Refresh />
          </IconButton>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            sx={{ width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.05)' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={setEndDate}
            sx={{ width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.05)' }}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        onClick={fetchAnalytics}
        disabled={loading}
        sx={{ mb: 4 }}
      >
        {loading ? 'Loading...' : 'Update Analytics'}
      </Button>

      {analytics && (
        <Grid container spacing={3}>
          {/* Daily Usage Card */}
          <Grid item xs={12} md={6}>
            <Card sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
              <CardContent>
                <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
                  Daily Usage
                </Typography>
                <Box sx={{ height: 300 }}>
                  <Line
                    data={analytics.daily_usage}
                    xAxisDataKey="date"
                    yAxisDataKey="count"
                    stroke="#8884d8"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Hourly Usage Card */}
          <Grid item xs={12} md={6}>
            <Card sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
              <CardContent>
                <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
                  Hourly Usage
                </Typography>
                <Box sx={{ height: 300 }}>
                  <Line
                    data={analytics.hourly_usage}
                    xAxisDataKey="hour"
                    yAxisDataKey="count"
                    stroke="#82ca9d"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* User Stats Card */}
          <Grid item xs={12}>
            <Card sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
              <CardContent>
                <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
                  User Statistics
                </Typography>
                <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
                  {analytics.user_stats.map((stat) => (
                    <Box key={stat.user_id} sx={{ mb: 2 }}>
                      <Typography sx={{ color: 'white' }}>
                        {stat.user_name} - {stat.total_conversations} conversations
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'gray' }}>
                        Average messages per conversation: {stat.avg_messages.toFixed(2)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {/* Delete Dialog */}
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DialogTitle>Delete Old Records</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Select a date. All records before this date will be deleted.
          </Typography>
          <DatePicker
            label="Delete Before Date"
            value={deleteDate}
            onChange={setDeleteDate}
            sx={{ width: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AnalyticsPage;