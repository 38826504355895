class WebSocketService {
    constructor() {
        this.ws = null;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.listeners = new Set();
        this.connectPromise = null;
        this.connectionState = 'DISCONNECTED';
        this.connectionAttempts = new Set();
        this.messageQueue = [];
        this.wsUrl = 'wss://dj.nilconnect.in/ws/user-messages/';
        this.pingInterval = null;
        this.lastPingTime = null;
    }

    handleError(error) {
        console.error('WebSocket error:', error);
        this.connectionState = 'ERROR';
        
        // Enhanced error logging
        const errorDetails = {
            message: error.message || 'WebSocket connection failed',
            connectionState: this.ws?.readyState,
            lastUrl: this.wsUrl,
            lastPing: this.lastPingTime ? new Date(this.lastPingTime).toISOString() : null,
            queueLength: this.messageQueue.length
        };
        
        console.error('Connection Details:', errorDetails);
        
        this.notifyListeners({ 
            type: 'error', 
            error: errorDetails.message,
            details: errorDetails
        });

        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            this.attemptReconnect();
        }
    }

    attemptReconnect() {
        const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 30000);
        console.log(`Attempting reconnection in ${delay}ms...`);
        
        setTimeout(() => {
            this.reconnectAttempts++;
            this.connect().catch(error => {
                console.error('Reconnection attempt failed:', error);
            });
        }, delay);
    }

    startPingInterval() {
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }
        
        this.pingInterval = setInterval(() => {
            if (this.isConnected()) {
                try {
                    this.ws.send(JSON.stringify({ type: 'ping' }));
                    this.lastPingTime = Date.now();
                } catch (error) {
                    console.error('Error sending ping:', error);
                    this.handleError(error);
                }
            }
        }, 30000); // Ping every 30 seconds
    }

    async connect() {
        const currentTime = Date.now();
        if (this.connectionAttempts.has(currentTime)) {
            return this.connectPromise;
        }
        
        if (this.isConnected()) {
            return Promise.resolve(this.ws);
        }

        if (this.connectPromise) {
            return this.connectPromise;
        }

        this.connectionAttempts.add(currentTime);
        setTimeout(() => this.connectionAttempts.delete(currentTime), 5000);

        this.connectPromise = new Promise((resolve, reject) => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    const error = new Error('No authentication token found');
                    this.handleError(error);
                    reject(error);
                    return;
                }

                const fullWsUrl = `${this.wsUrl}?token=${token}`;
                console.log('Connecting to WebSocket:', fullWsUrl);

                if (this.ws) {
                    this.ws.close(1000);
                    this.ws = null;
                }

                this.connectionState = 'CONNECTING';
                this.ws = new WebSocket(fullWsUrl);

                this.ws.onopen = () => {
                    console.log('WebSocket Connected');
                    this.connectionState = 'CONNECTED';
                    this.reconnectAttempts = 0;
                    this.startPingInterval();
                    this.processMessageQueue();
                    resolve(this.ws);

                    // Notify listeners of successful connection
                    this.notifyListeners({
                        type: 'connection_status',
                        status: 'connected'
                    });
                };

                this.ws.onmessage = (event) => {
                    try {
                        const data = JSON.parse(event.data);
                        console.log('WebSocket message received:', data);
                        
                        if (data.type === 'error') {
                            console.error('Server error:', data.message);
                            this.notifyListeners({
                                type: 'server_error',
                                message: data.message
                            });
                            return;
                        }
                        
                        if (data.type === 'pong') {
                            this.lastPingTime = Date.now();
                            return;
                        }
                        
                        this.notifyListeners(data);
                    } catch (error) {
                        console.error('Error parsing WebSocket message:', error);
                    }
                };

                this.ws.onclose = (event) => {
                    console.log('WebSocket Closed:', event.code, event.reason);
                    this.connectionState = 'DISCONNECTED';
                    this.connectPromise = null;
                    this.ws = null;

                    if (this.pingInterval) {
                        clearInterval(this.pingInterval);
                        this.pingInterval = null;
                    }

                    this.notifyListeners({
                        type: 'connection_status',
                        type: 'connection_status',
                        status: 'disconnected',
                        code: event.code,
                        reason: event.reason
                    });

                    if (event.code !== 1000 && this.reconnectAttempts < this.maxReconnectAttempts) {
                        this.attemptReconnect();
                    }
                };

                this.ws.onerror = (error) => {
                    console.error('WebSocket Error:', error);
                    this.handleError(error);
                };

            } catch (error) {
                this.connectionAttempts.delete(currentTime);
                console.error('Error establishing WebSocket connection:', error);
                this.connectionState = 'ERROR';
                this.connectPromise = null;
                reject(error);
            }
        });

        return this.connectPromise;
    }

    processMessageQueue() {
        while (this.messageQueue.length > 0 && this.isConnected()) {
            const message = this.messageQueue.shift();
            try {
                this.ws.send(JSON.stringify(message));
                console.log('Processed queued message:', message);
            } catch (error) {
                console.error('Error processing queued message:', error);
                this.messageQueue.unshift(message); // Put the message back at the start of the queue
                break;
            }
        }
    }

    async sendMessage(text, taggedUsers) {
        try {
            if (!this.isConnected()) {
                console.log('WebSocket not connected, attempting to connect...');
                await this.connect();
            }

            const message = {
                action: 'send_notification',
                text: text,
                tagged_users: taggedUsers
            };

            if (!this.isConnected()) {
                console.log('Queuing message for later delivery:', message);
                this.messageQueue.push(message);
                return;
            }

            console.log('Sending WebSocket message:', message);
            this.ws.send(JSON.stringify(message));
        } catch (error) {
            console.error('Error sending message:', error);
            this.handleError(error);
            throw error;
        }
    }

    async markAsRead(messageId) {
        try {
            if (!this.isConnected()) {
                await this.connect();
            }

            const message = {
                action: 'mark_read',
                notification_id: messageId
            };

            if (!this.isConnected()) {
                this.messageQueue.push(message);
                return;
            }

            console.log('Marking message as read:', message);
            this.ws.send(JSON.stringify(message));
        } catch (error) {
            console.error('Error marking message as read:', error);
            this.handleError(error);
            throw error;
        }
    }

    isConnected() {
        return this.ws && this.ws.readyState === WebSocket.OPEN;
    }

    getState() {
        return {
            connectionState: this.connectionState,
            queueLength: this.messageQueue.length,
            reconnectAttempts: this.reconnectAttempts,
            lastPingTime: this.lastPingTime
        };
    }

    addListener(callback) {
        this.listeners.add(callback);
        return () => this.listeners.delete(callback);
    }

    notifyListeners(data) {
        this.listeners.forEach(listener => {
            try {
                listener(data);
            } catch (error) {
                console.error('Error in listener:', error);
            }
        });
    }

    disconnect() {
        if (this.ws) {
            this.connectionState = 'DISCONNECTING';
            this.ws.close(1000);
            this.ws = null;
        }
        
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        }
        
        this.connectPromise = null;
        this.connectionState = 'DISCONNECTED';
        this.messageQueue = [];
        this.listeners.clear();
    }

    reconnect() {
        this.disconnect();
        return this.connect();
    }
}

const webSocketService = new WebSocketService();
export default webSocketService;