import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  CircularProgress,
  TextField,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Alert,
  Snackbar,
  ListItemIcon,
  Typography,
  Drawer,
  Tooltip,
  Tabs,
  Tab,
  Fade,
  ClickAwayListener
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { listTrainers, markTrainer, unmarkTrainer, clearAllMarks } from '../api';

const DRAWER_WIDTH = 300;

const colorMapping = {
  red: '#FF0000',
  yellow: '#FFD700',
  green: '#00FF00'
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '20px',
  overflow: 'hidden',
  margin: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: 'white',
    '&::before, &::after': {
      borderColor: 'transparent',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 2),
  },
}));

const RightTrainerNav = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [markedTrainers, setMarkedTrainers] = useState({});
  const [colorMenuAnchor, setColorMenuAnchor] = useState(null);
  const [selectedTrainerId, setSelectedTrainerId] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const searchTimeout = useRef(null);
  const isMounted = useRef(true);

  // Initial background load of trainers
  useEffect(() => {
    const loadInitialTrainers = async () => {
      if (!initialLoadComplete) {
        try {
          const response = await listTrainers({ 
            page: 1, 
            page_size: 80,
            search: '' 
          });
          setTrainers(response.results);
          setHasMore(response.results.length === 50);
          setInitialLoadComplete(true);
        } catch (error) {
          console.error('Error in background loading of trainers:', error);
        }
      }
    };

    loadInitialTrainers();
  }, []);

  // Load marked trainers from localStorage
  useEffect(() => {
    const savedMarkedTrainers = localStorage.getItem('markedTrainers');
    if (savedMarkedTrainers) {
      try {
        setMarkedTrainers(JSON.parse(savedMarkedTrainers));
      } catch (error) {
        console.error('Error parsing marked trainers:', error);
      }
    }
  }, []);

  // Save marked trainers to localStorage
  useEffect(() => {
    localStorage.setItem('markedTrainers', JSON.stringify(markedTrainers));
  }, [markedTrainers]);

  const fetchTrainers = useCallback(async (search = '', loadMore = false) => {
    if (!isOpen) return;
    
    try {
      setLoading(true);
      const response = await listTrainers({ 
        page: loadMore ? page + 1 : 1, 
        page_size: 80,
        search 
      });
      
      if (loadMore) {
        setTrainers(prev => [...prev, ...response.results]);
        setPage(prev => prev + 1);
      } else {
        setTrainers(response.results);
        setPage(1);
      }
      
      setHasMore(response.results.length === 50);
    } catch (error) {
      console.error('Error fetching trainers:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch trainers',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, [isOpen, page]);

  // Handle load more
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      fetchTrainers(searchTerm, true);
    }
  };

  // Initial load and search
  useEffect(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    if (isOpen) {
      searchTimeout.current = setTimeout(() => {
        fetchTrainers(searchTerm);
      }, 500);
    }

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, [searchTerm, fetchTrainers, isOpen]);

  const handleTrainerClick = (trainerId) => {
    navigate(`/trainers/${trainerId}`);
  };

  const handleMarkTrainer = (trainer, event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedTrainerId(trainer.user.id);
    setColorMenuAnchor(event.currentTarget);
  };

  const handleColorSelect = async (colorChoice) => {
    if (selectedTrainerId) {
      try {
        if (markedTrainers[selectedTrainerId] === colorChoice.value) {
          await unmarkTrainer(selectedTrainerId);
          setMarkedTrainers(prev => {
            const newMarked = { ...prev };
            delete newMarked[selectedTrainerId];
            return newMarked;
          });
        } else {
          await markTrainer(selectedTrainerId, colorChoice.value);
          setMarkedTrainers(prev => ({
            ...prev,
            [selectedTrainerId]: colorChoice.value
          }));
        }
        await fetchTrainers(searchTerm);
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to update trainer color marking',
          severity: 'error'
        });
      }
    }
    setColorMenuAnchor(null);
  };

  const handleClearMarks = async () => {
    try {
      await clearAllMarks();
      setMarkedTrainers({});
      await fetchTrainers(searchTerm);
      setSnackbar({
        open: true,
        message: 'All marks cleared successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to clear marks',
        severity: 'error'
      });
    }
  };

  const handleClickAway = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const colorOptions = [
    { name: 'Red', value: 'red' },
    { name: 'Yellow', value: 'yellow' },
    { name: 'Green', value: 'green' }
  ];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Tooltip title={isOpen ? "Close trainer sidebar" : "Open trainer sidebar"} placement="left">
          <IconButton
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              position: 'fixed',
              right: isOpen ? DRAWER_WIDTH : 0,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255, 0, 0, 0.8)',
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 0, 0, 0.9)',
              },
              transition: 'right 0.3s',
              zIndex: 1199,
              width: '40px',
              height: '40px',
              boxShadow: '0 0 10px rgba(0,0,0,0.3)',
              border: '2px solid rgba(255,255,255,0.2)',
            }}
          >
            {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Tooltip>

        <Drawer
          variant="permanent"
          anchor="right"
          sx={{
            display: isOpen ? 'block' : 'none',
            position: 'fixed',
            zIndex: 1200,
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              position: 'fixed',
              right: isOpen ? 0 : -DRAWER_WIDTH,
              height: '100%',
              backgroundColor: 'rgba(18, 18, 18, 0.95)',
              backdropFilter: 'blur(10px)',
              color: 'white',
              borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
              transition: 'right 0.3s ease-in-out',
            },
          }}
        >
          <DrawerHeader>
            <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
              Trainers
            </Typography>
            {Object.keys(markedTrainers).length > 0 && (
              <IconButton onClick={handleClearMarks} sx={{ color: 'white' }}>
                <ClearAllIcon />
              </IconButton>
            )}
          </DrawerHeader>

          <SearchBar component="form" onSubmit={(e) => { e.preventDefault(); fetchTrainers(searchTerm); }}>
            <StyledTextField
              size="small"
              placeholder="Search trainers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="standard"
              fullWidth
            />
            <IconButton type="submit" sx={{ color: 'white', padding: '8px' }}>
              <SearchIcon />
            </IconButton>
          </SearchBar>

          <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 120px)' }}>
            {loading && trainers.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                <CircularProgress size={24} sx={{ color: 'white' }} />
              </Box>
            ) : (
              <>
                <List sx={{ width: '100%', overflow: 'auto' }}>
                  {trainers.map((trainer) => (
                    <ListItem
                      key={trainer.id}
                      onClick={() => handleTrainerClick(trainer.id)}
                      onContextMenu={(e) => handleMarkTrainer(trainer, e)}
                      sx={{
                        backgroundColor: trainer.user.color_marking ? `${colorMapping[trainer.user.color_marking]}22` : 'transparent',
                        '&:hover': {
                          backgroundColor: trainer.user.color_marking ? 
                            `${colorMapping[trainer.user.color_marking]}44` : 
                            'rgba(255, 255, 255, 0.1)',
                        },
                        cursor: 'pointer',
                        borderRadius: 1,
                        mb: 0.5
                      }}
                    >
                      <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary={trainer.user.full_name}
                        secondary={trainer.user.city || ''}
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: 'white',
                          },
                          '& .MuiListItemText-secondary': {
                            color: 'rgba(255, 255, 255, 0.7)',
                          }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>

                {hasMore && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                    <IconButton
                      onClick={handleLoadMore}
                      disabled={loading}
                      sx={{ color: 'white' }}
                    >
                      {loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <ArrowForwardIcon />
                      )}
                    </IconButton>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Drawer>

        <Menu
          anchorEl={colorMenuAnchor}
          open={Boolean(colorMenuAnchor)}
          onClose={() => setColorMenuAnchor(null)}
          PaperProps={{
            sx: {
              backgroundColor: 'rgba(18, 18, 18, 0.8)',
              color: 'white',
            }
          }}
        >
          {colorOptions.map((color) => (
            <MenuItem 
              key={color.value} 
              onClick={() => handleColorSelect(color)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover': {
                  backgroundColor: `${colorMapping[color.value]}22`
                }
              }}
            >
              <FiberManualRecordIcon sx={{ color: colorMapping[color.value] }} />
              {color.name}
            </MenuItem>
          ))}
        </Menu>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ 
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              '& .MuiAlert-icon': {
                color: 'white'
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </ClickAwayListener>
  );
};

export default RightTrainerNav;