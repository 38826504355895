import React, { useEffect } from 'react';
import {
  Snackbar,
  Box,
  Typography,
  IconButton,
  Paper,
  Avatar,
} from '@mui/material';
import { Close as CloseIcon, Message as MessageIcon } from '@mui/icons-material';
import { keyframes } from '@mui/material/styles';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const MessagePopup = ({ open, message, onClose }) => {
  if (!message) return null;

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        '& .MuiSnackbarContent-root': {
          bgcolor: 'transparent',
          p: 0,
          minWidth: 'auto',
        }
      }}
    >
      <Paper
        elevation={6}
        sx={{
          background: 'rgba(13, 71, 161, 0.95)',
          backdropFilter: 'blur(10px)',
          borderRadius: '12px',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          minWidth: '300px',
          maxWidth: '400px',
          overflow: 'hidden',
          animation: `${slideIn} 0.3s ease-out`,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
            px: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <MessageIcon sx={{ color: 'white' }} />
            <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
              New Message
            </Typography>
          </Box>
          <IconButton 
            size="small" 
            onClick={onClose}
            sx={{ color: 'white' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* Content */}
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
            <Avatar
              src={message.sender?.profile_image}
              alt={message.sender?.full_name}
              sx={{
                width: 40,
                height: 40,
                border: '2px solid',
                borderColor: 'primary.main',
              }}
            >
              {message.sender?.full_name?.[0]}
            </Avatar>
            <Box>
              <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                {message.sender?.full_name}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: 'white',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              p: 1.5,
              borderRadius: '8px',
              wordBreak: 'break-word',
            }}
          >
            {message.text}
          </Typography>
          <Typography 
            variant="caption" 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.7)',
              display: 'block',
              textAlign: 'right',
              mt: 1
            }}
          >
            {new Date(message.created_at).toLocaleString()}
          </Typography>
        </Box>
      </Paper>
    </Snackbar>
  );
};

export default MessagePopup;
