import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  List,
  ListItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AlertTriangle, Check } from 'lucide-react';

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(15, 23, 42, 0.9)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: 'white',
    maxWidth: '600px',
    width: '90%'
  }
}));

const GradientButton = styled(Button)(() => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: 'white',
  borderRadius: '20px',
  padding: '8px 16px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
  }
}));

const ReadButton = styled(Button)(() => ({
  background: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  borderRadius: '20px',
  minWidth: '40px',
  padding: '4px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  }
}));

const NotificationItem = styled(ListItem)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '8px',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(2),
  border: '1px solid rgba(255, 255, 255, 0.1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
}));

const AbsenceAlertDialog = ({ open, onClose, notifications, onMarkRead }) => {
  const absenceNotifications = notifications.filter(
    notif => notif.notification_type === 'ABSENCE' && !notif.is_read
  );

  if (absenceNotifications.length === 0) return null;

  const handleMarkRead = (notificationId) => {
    onMarkRead(notificationId);
  };

  return (
    <GlassDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={2}>
          <AlertTriangle size={24} color="#FF1B6B" />
          <Typography variant="h6">
            Consecutive Absence Alerts
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.8)' }}>
          The following students have been absent for consecutive days:
        </Typography>
        <List>
          {absenceNotifications.map((notification) => (
            <NotificationItem key={notification.id}>
              <Box flex={1}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  {notification.message}
                </Typography>
                <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                  Reported: {new Date(notification.created_at).toLocaleString()}
                </Typography>
              </Box>
              <ReadButton
                onClick={() => handleMarkRead(notification.id)}
                title="Mark as read"
                size="small"
                startIcon={<Check size={16} />}
                sx={{ minWidth: '120px' }}
              >
                Acknowledge
              </ReadButton>
            </NotificationItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <GradientButton onClick={onClose}>
          Acknowledge All
        </GradientButton>
      </DialogActions>
    </GlassDialog>
  );
};

export default AbsenceAlertDialog;