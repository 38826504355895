import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  Pagination,
  Chip,
  useMediaQuery,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import AddEditStudentModal from '../components/AddEditStudentModal';
import { 
  listUsers, 
  updateUser, 
  createStudentProfile,
  listCoordinators,
  changeUserRole,
  deleteUser
} from '../api';
import ConfirmDialog from '../components/ConfirmDialog';

const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const GlassTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const GlassChip = styled(Chip)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  '&.MuiChip-colorPrimary': {
    background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  },
}));

function StudentsPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [users, setUsers] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedRoles, setSelectedRoles] = useState(['STUDENT']);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [usersResponse, coordinatorsResponse] = await Promise.all([
        listUsers({ 
          page, 
          page_size: 10, 
          roles: selectedRoles,
          search: searchTerm
        }),
        listCoordinators(),
      ]);

      setUsers(usersResponse.results || []);
      setTotalPages(usersResponse.total_pages || 1);
      setTotalCount(usersResponse.count || 0);
      setCoordinators(Array.isArray(coordinatorsResponse) ? coordinatorsResponse : []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
      setUsers([]);
      setCoordinators([]);
    } finally {
      setLoading(false);
    }
  }, [page, selectedRoles, searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddEdit = (user = null) => {
    if (user && user.role === 'STUDENT') {
      setEditingUser(user);
      setOpenModal(true);
    }
  };

  const handleStudentClick = (user) => {
    navigate(`/student-details-big/${user.id}`);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      setSnackbar({ open: true, message: 'User deleted successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to delete user. Please try again.', severity: 'error' });
    }
  };

  const handleSave = async (userData) => {
    try {
      let response;
      if (userData.id) {
        response = await updateUser(userData.id, userData);
      } else {
        response = await createStudentProfile(userData);
      }
      
      setSnackbar({ open: true, message: `Student ${userData.id ? 'updated' : 'added'} successfully`, severity: 'success' });
      setOpenModal(false);
      fetchData();
    } catch (error) {
      console.error('Error saving student:', error);
      setSnackbar({ open: true, message: `Failed to ${userData.id ? 'update' : 'add'} student. Please try again.`, severity: 'error' });
    }
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      await changeUserRole(userId, newRole);
      setSnackbar({ open: true, message: 'User role updated successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error changing user role:', error);
      setSnackbar({ open: true, message: 'Failed to update user role. Please try again.', severity: 'error' });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRoleFilter = (role) => {
    setSelectedRoles(prevRoles => 
      prevRoles.includes(role)
        ? prevRoles.filter(r => r !== role)
        : [...prevRoles, role]
    );
    setPage(1);
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(searchInput);
      setPage(1);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <GlassContainer sx={{ paddingBottom: '400px'}}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom color="white">
          Users
        </Typography>
        <GradientButton
          startIcon={<AddIcon />}
          onClick={() => handleAddEdit()}
        >
          Add Student
        </GradientButton>
      </Box>
      <Box mb={2}>
        <GlassTextField
          fullWidth
          label="Search by Name, Email, or Username"
          value={searchInput}
          onChange={handleSearchInputChange}
          onKeyPress={handleSearch}
          margin="normal"
        />
        <Box mt={2}>
          {['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'].map(role => (
            <GlassChip
              key={role}
              label={role}
              onClick={() => handleRoleFilter(role)}
              color={selectedRoles.includes(role) ? "primary" : "default"}
              style={{ margin: '0 4px 4px 0' }}
            />
          ))}
        </Box>
      </Box>
      {users.length === 0 ? (
        <Alert severity="info">No users found matching your search criteria.</Alert>
      ) : (
        <GlassTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                {!isMobile && <TableCell>Full Name</TableCell>}
                {!isMobile && <TableCell>Email</TableCell>}
                {!isMobile && <TableCell>Phone</TableCell>}
                <TableCell>Role</TableCell>
                {!isMobile && <TableCell>Coordinator</TableCell>}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Button
                      variant="text"
                      onClick={() => handleStudentClick(user)}
                      style={{ textTransform: 'none', color: 'white' }}
                    >
                      {user.username}
                    </Button>
                  </TableCell>
                  {!isMobile && <TableCell>{user.full_name}</TableCell>}
                  {!isMobile && <TableCell>{user.email}</TableCell>}
                  {!isMobile && <TableCell>{user.phone}</TableCell>}
                  <TableCell>
                    <FormControl>
                      <Select
                        value={user.role}
                        onChange={(e) => handleRoleChange(user.id, e.target.value)}
                        sx={{ color: 'white', '& .MuiSvgIcon-root': { color: 'white' } }}
                      >
                        <MenuItem value="STUDENT">Student</MenuItem>
                        <MenuItem value="TRAINER">Trainer</MenuItem>
                        <MenuItem value="ADMIN">Admin</MenuItem>
                        <MenuItem value="MANAGER">Manager</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  {!isMobile && (
                    <TableCell>
                      {user.coordinator ? (
                        <Box display="flex" alignItems="center">
                          <Avatar src={user.coordinator.profile_image} alt={user.coordinator.name} sx={{ width: 24, height: 24, mr: 1 }} />
                          {user.coordinator.name}
                        </Box>
                      ) : (
                        'Not assigned'
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleAddEdit(user)} sx={{ color: 'white' }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: 'Are you sure you want to delete this user?',
                          subTitle: "You can't undo this operation",
                          onConfirm: () => { handleDeleteUser(user.id) }
                        });
                      }} sx={{ color: 'white' }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GlassTableContainer>
      )}
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
          sx={{
            '& .MuiPaginationItem-root': {
              color: 'white',
            },
          }}
        />
      </Box>
      <AddEditStudentModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        student={editingUser}
        onSave={handleSave}
      />
      <ConfirmDialog 
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassContainer>
  );
}

export default StudentsPage;