import React, { useState, useEffect } from 'react';
import API from '../services/api';
import ttsService from '../services/ttsService';
import { MessageCircle } from 'lucide-react';
import styles from './ConversationStarter.module.css';



const ConversationStarter = ({ onConversationStarted, context = 'english_tutor', shouldStart }) => {
  const [isStarting, setIsStarting] = useState(false);
  const [error, setError] = useState(null);
  const topics = {
    'Personal and Lifestyle': [
      'Describe your daily routine.',
      'Talk about your favorite hobbies and why you enjoy them.',
      'What would your dream vacation look like?',
      'Describe your favorite meal and how it\'s prepared.',
      'Discuss a memorable experience you\'ve had recently.'
    ],
    'Travel and Places': [
      'Talk about a place you\'ve always wanted to visit.',
      'Describe your hometown and its unique features.',
      'Compare living in the countryside vs. a city.',
      'What\'s your favorite type of weather and why?',
      'Describe a famous landmark you\'ve learned about.'
    ],
    'Relationships and Social Life': [
      'Talk about the qualities of a good friend.',
      'How would you describe your family?',
      'What activities do you enjoy doing with others?',
      'Share tips for making new friends.',
      'Describe a typical celebration in your culture.'
    ],
    'Work and Education': [
      'What\'s your ideal job, and why would you choose it?',
      'Describe a skill you\'d like to learn.',
      'Talk about a memorable teacher or mentor.',
      'What do you enjoy most about studying or working?',
      'Discuss tips for staying motivated.'
    ],
    'Entertainment and Leisure': [
      'What\'s your favorite movie or TV show?',
      'Describe a book you\'ve enjoyed recently.',
      'What kind of music do you like, and why?',
      'Talk about your favorite outdoor activity.',
      'If you could try any sport, what would it be?'
    ],
    'Imagination and Fun': [
      'If you could have a superpower, what would it be?',
      'Imagine you\'ve won the lottery—what would you do first?',
      'If you could live in any time period, which would it be?',
      'What would you do if you could travel to space?',
      'Imagine meeting a fictional character—who would it be and why?'
    ]
  };

  const getRandomTopic = () => {
    const topicKeys = Object.keys(topics);
    const randomTopicKey = topicKeys[Math.floor(Math.random() * topicKeys.length)];
    const topicQuestions = topics[randomTopicKey];
    const randomQuestion = topicQuestions[Math.floor(Math.random() * topicQuestions.length)];
    
    return {
      category: randomTopicKey,
      question: randomQuestion
    };
  };

  const startConversation = async () => {
    if (isStarting) return;
    
    setIsStarting(true);
    setError(null);
    
    try {
      const selectedTopic = getRandomTopic();
      
      const starterMessage = {
        text: `Hi! Let's start our conversation with this topic: ${selectedTopic.question}`,
        sender: 'bot',
        timestamp: new Date()
      };

      // Instead of making the API call here, we pass the initial message to the parent
      // and let the parent handle the API call with user data
      onConversationStarted({
        initialMessage: starterMessage,
        topic: selectedTopic
      });

      if (context === 'english_tutor') {
        try {
          await ttsService.convertTextToSpeech(starterMessage.text);
        } catch (ttsError) {
          console.error('Text-to-speech processing error:', ttsError);
        }
      }
      
    } catch (error) {
      console.error('Failed to start conversation:', error);
      setError('Failed to start conversation. Please try again.');
    } finally {
      setIsStarting(false);
    }
  };

  if (error) {
    return (
      <div className="p-4 bg-red-100 border border-red-400 rounded-lg">
        <p className="text-red-700">{error}</p>
        <button 
          onClick={startConversation}
          className={styles.startButton}
          disabled={isStarting}
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <button 
      onClick={startConversation}
      disabled={isStarting}
      className={styles.startButton}
    >
      <MessageCircle className={styles.icon} />
      <span className={styles.buttonText}>
        {isStarting ? 'Starting conversation...' : 'Click to start conversation'}
      </span>
    </button>
  );
};

export default ConversationStarter;