import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Collapse,
  IconButton,
  Box,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useTheme,
  useMediaQuery,Paper,Container
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import dayjs from 'dayjs';
import GroupedSuggestions from './GroupedSuggestions';
import {
  getAvailableTrainersExtended,
  getUser,
  getTrainerSuggestionsForExternalLead,
  assignTrainerToCourse,
  listExternalLeads,
  searchUserByEmail,
  deleteExternalLead,
} from '../api';
import AddEditStudentModal from '../components/AddEditStudentModal';

const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));
const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const formatTime = (time24) => {
  return dayjs(time24, 'HH:mm').format('h:mm A');
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = dayjs(dateString);
  return date.isValid() ? date.format('D-MMM-YYYY').toLowerCase() : 'Invalid Date';
};

const ExternalLeadRow = ({ lead, onAssignTrainer, onDelete }) => {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showNoProfileModal, setShowNoProfileModal] = useState(false);
  const [expandedTrainer, setExpandedTrainer] = useState(null);

  const handleGetSuggestions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getTrainerSuggestionsForExternalLead(lead.id);
      setSuggestions(response.data);
      setOpen(true);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setShowNoProfileModal(true);
      } else {
        setError('Failed to fetch trainer suggestions. Please try again.');
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getTrainerAvailabilitySummary = (suggestion) => {
    const { availability, warnings = [], occupied_hours = 0, approved_hours = 0 } = suggestion;
    
    // Format the dates and slots
    const availableDates = availability.map(slot => ({
      date: formatDate(slot.date), // This will show as "6-nov-2024"
      time: formatTime(slot.slots[0]) // This will show "5:00 PM" for "17:00"
    }));
  
    return {
      summary: `Available from ${availableDates[0].date}`,
      availableDates: availableDates,
      warnings,
      hours: { occupied: occupied_hours, approved: approved_hours }
    };
  };


  const handleTrainerClick = (trainerId) => {
    setExpandedTrainer(expandedTrainer === trainerId ? null : trainerId);
  };

  const formatDateForDisplay = (date) => dayjs(date).format('ddd, MMM D');


  
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{lead.name}</TableCell>
        <TableCell>{lead.email || 'N/A'}</TableCell>
        <TableCell>{lead.phone}</TableCell>
        <TableCell>{lead.course}</TableCell>
        <TableCell>{lead.duration} minutes</TableCell>
        <TableCell>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleGetSuggestions}
            disabled={loading}
            style={{ marginRight: '8px' }}
          >
            {loading ? <CircularProgress size={24} /> : 'Get Suggestions'}
          </Button>
          <IconButton
            color="error"
            onClick={() => onDelete(lead.id)}
            disabled={loading}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Additional Information
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Slot 1</TableCell>
                    <TableCell>{formatTime(lead.slot_1)}</TableCell>
                    <TableCell component="th" scope="row">Slot 2</TableCell>
                    <TableCell>{formatTime(lead.slot_2)}</TableCell>
                    <TableCell component="th" scope="row">Slot 3</TableCell>
                    <TableCell>{formatTime(lead.slot_3)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Remarks</TableCell>
                    <TableCell colSpan={5}>{lead.remarks || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Coordinator Name</TableCell>
                    <TableCell>{lead.coordinator_name || 'N/A'}</TableCell>
                    <TableCell component="th" scope="row">Created At</TableCell>
                    <TableCell>{formatDate(lead.created_at)} {formatTime(lead.created_at)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              
              <Typography variant="h6" gutterBottom component="div" style={{ marginTop: '20px' }}>
                Trainer Suggestions
              </Typography>
              {error && <Alert severity="error">{error}</Alert>}
              {suggestions.length > 0 ? (
  <GroupedSuggestions
    suggestions={suggestions}
    expandedTrainer={expandedTrainer}
    handleTrainerClick={handleTrainerClick}
    onAssignTrainer={onAssignTrainer}
    lead={lead}
  />
) : (
  <Typography>No suggestions available.</Typography>
)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={showNoProfileModal} onClose={() => setShowNoProfileModal(false)}>
        <DialogTitle>User Profile Not Found</DialogTitle>
        <DialogContent>
          <Typography>
            The user hasn't made a profile yet. Please ask the user to create a profile or check if the email is correct.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNoProfileModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AllotmentPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [externalLeads, setExternalLeads] = useState([]);
  const [availableTrainers, setAvailableTrainers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [date, setDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(dayjs().startOf('hour'));
  const [duration, setDuration] = useState('');
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchExternalLeads();
  }, []);

  const fetchExternalLeads = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listExternalLeads();
      setExternalLeads(response.data);
    } catch (err) {
      setError('Failed to fetch external leads. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLead = async (leadId) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      await deleteExternalLead(leadId);
      setSuccess('External lead deleted successfully.');
      fetchExternalLeads(); // Refresh the list after deletion
    } catch (err) {
      setError('Failed to delete external lead. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAssignTrainer = async (lead, trainer) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      // Search for user by email
      const userResponse = await searchUserByEmail(lead.email);
      let student = null;

      if (userResponse.data.length > 0) {
        student = userResponse.data[0];
      } else {
        // If user not found, we'll create a new one in the AddEditStudentModal
        student = {
          email: lead.email,
          full_name: lead.name,
          username: lead.email.split('@')[0], // Use part of email as username
        };
      }

      setSelectedStudent(student);
      setSelectedTrainer(trainer);
      setSelectedCourse(lead.course);
      setIsAddEditModalOpen(true);
    } catch (err) {
      setError('Failed to assign trainer. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddEditModalClose = () => {
    setIsAddEditModalOpen(false);
    setSelectedStudent(null);
    setSelectedTrainer(null);
    setSelectedCourse(null);
  };

  const handleAddEditModalSave = async (savedData) => {
    setSuccess(`Successfully assigned trainer to student and created course.`);
    fetchExternalLeads(); // Refresh the external leads list
    handleAddEditModalClose();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await getAvailableTrainersExtended(
        startTime.format('HH:mm'),
        parseInt(duration),
        date.format('YYYY-MM-DD')
      );
      setAvailableTrainers(response.data);
      setSuccess('Successfully fetched available trainers.');
    } catch (err) {
      setError('Failed to fetch available trainers. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <GlassContainer sx={{ paddingBottom: '400px'}}>
      <Typography variant="h4" gutterBottom color="white">Trainer Allotment</Typography>
      
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        aria-label="allotment tabs"
        sx={{ 
          mb: 3,
          '& .MuiTab-root': { color: 'white' },
          '& .Mui-selected': { color: theme.palette.primary.main }
        }}
      >
        <Tab label="External Leads" />
        <Tab label="Manual Search" />
      </Tabs>

      {loading && <CircularProgress sx={{ color: 'white' }} />}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

      {tabValue === 0 && (
        <>
          <Typography variant="h5" gutterBottom color="white" sx={{ mt: 2 }}>External Leads</Typography>
          {externalLeads.length > 0 ? (
            <GlassTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Name</TableCell>
                    {!isMobile && <TableCell>Email</TableCell>}
                    {!isMobile && <TableCell>Phone</TableCell>}
                    <TableCell>Course</TableCell>
                    {!isMobile && <TableCell>Duration</TableCell>}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {externalLeads.map((lead) => (
                    <ExternalLeadRow 
                      key={lead.id} 
                      lead={lead} 
                      onAssignTrainer={handleAssignTrainer}
                      onDelete={handleDeleteLead}
                      isMobile={isMobile}
                    />
                  ))}
                </TableBody>
              </Table>
            </GlassTableContainer>
          ) : (
            <Typography color="white">No external leads available.</Typography>
          )}
        </>
      )}

      {tabValue === 1 && (
        <>
          <GlassContainer sx={{ p: 3, mb: 3 }}>
            <Typography variant="h5" gutterBottom color="white">Search Available Trainers</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={(newDate) => setDate(newDate)}
                  renderInput={(params) => <StyledTextField {...params} fullWidth />}
                  format="DD-MMM-YYYY"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={(newTime) => setStartTime(newTime)}
                  renderInput={(params) => <StyledTextField {...params} fullWidth />}
                  format="hh:mm A"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StyledTextField
                  fullWidth
                  label="Duration (minutes)"
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <GradientButton
                  onClick={handleSearch}
                  fullWidth
                >
                  Search
                </GradientButton>
              </Grid>
            </Grid>
          </GlassContainer>

          {availableTrainers.length > 0 && (
            <>
              <Typography variant="h5" gutterBottom color="white">Available Trainers</Typography>
              <GlassTableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Trainer Name</TableCell>
                      {!isMobile && <TableCell>Available Today</TableCell>}
                      {!isMobile && <TableCell>Available Within Week</TableCell>}
                      <TableCell>Availability</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {availableTrainers.map((trainer) => (
                      <TableRow key={trainer.trainer.id}>
                        <TableCell>{trainer.trainer.full_name || trainer.trainer.username}</TableCell>
                        {!isMobile && <TableCell>{trainer.available_today ? 'Yes' : 'No'}</TableCell>}
                        {!isMobile && <TableCell>{trainer.available_within_week ? 'Yes' : 'No'}</TableCell>}
                        <TableCell>
                          {trainer.availability.map((day, index) => (
                            <div key={index}>
                              {formatDate(day.date)}: {day.is_available ? 'Available' : 'Not Available'}
                            </div>
                          ))}
                        </TableCell>
                        <TableCell>
                          <GradientButton 
                            onClick={() => handleAssignTrainer(null, trainer.trainer)}
                          >
                            Assign
                          </GradientButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GlassTableContainer>
            </>
          )}
        </>
      )}

      <AddEditStudentModal
        open={isAddEditModalOpen}
        onClose={handleAddEditModalClose}
        student={selectedStudent}
        onSave={handleAddEditModalSave}
        initialTrainer={selectedTrainer}
        initialCourse={selectedCourse}
      />
    </GlassContainer>
  );
};

export default AllotmentPage;