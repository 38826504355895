import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://ai.nilconnect.in/api',
    timeout: 30000,
    withCredentials: false,  // Change this to false
    headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
    }
});

const chatAPI = {
    sendMessage: async ({ message, conversation_id, context = 'general', user_id, user_name }) => {
        try {
            console.log('Sending message:', { message, conversation_id, context, user_id, user_name });
            const response = await api.post('/chat/message/', {
                message,
                conversation_id,
                context,
                user_id,
                user_name
            });
            console.log('Response received:', response.data);
            return {
                conversation_id: response.data.conversation_id,
                response: response.data.response,
                corrections: response.data.corrections || [],
                learning_points: response.data.learning_points || [],
                follow_up_questions: response.data.follow_up_questions || [],
                grammar_score: response.data.grammar_score,
                vocabulary_used: response.data.vocabulary_used,
                user_message_id: response.data.user_message_id,
                bot_message_id: response.data.bot_message_id
            };
        } catch (error) {
            console.error('Error sending message:', error);
            throw new Error(error.response?.data?.error || 'Failed to send message');
        }
    },

    newConversation: async ({ context = 'english_tutor', user_id, user_name }) => {
        try {
            // Log the exact data being sent
            const requestData = { 
                context,
                user_id,
                user_name
            };
            console.log('New conversation request data:', requestData);

            const response = await api.post('/chat/new_conversation/', requestData);
            console.log('New conversation response:', response.data);

            if (response.data && response.data.conversation_id) {
                return {
                    conversation_id: response.data.conversation_id,
                    context: response.data.context
                };
            }
            throw new Error('Invalid response format');
        } catch (error) {
            console.error('Error creating new conversation:', error);
            throw new Error(error.response?.data?.error || 'Failed to create new conversation');
        }
    },

    getHistory: async (conversation_id) => {
        try {
            const response = await api.get('/chat/', {
                params: { conversation_id }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching chat history:', error);
            throw new Error(error.response?.data?.error || 'Failed to fetch chat history');
        }
    }
};

const voiceAPI = {
    transcribeAudio: async (audioBlob, conversation_id, context = 'general') => {
        try {
            const formData = new FormData();
            // Change 'audio' to 'file' to match backend expectation
            formData.append('file', audioBlob, 'audio.webm'); // Use .webm extension
            if (conversation_id) {
                formData.append('conversation_id', conversation_id);
            }
            formData.append('context', context);
    
            const response = await api.post('/voice/transcribe/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error transcribing audio:', error);
            throw new Error(error.response?.data?.error || 'Failed to transcribe audio');
        }
    }
};

const systemAPI = {
    checkStatus: async () => {
        try {
            const response = await api.get('/system/status/');
            return response.data;
        } catch (error) {
            console.error('Error checking system status:', error);
            throw new Error(error.response?.data?.error || 'Failed to check system status');
        }
    }
};

const API = {
    chat: chatAPI,
    voice: voiceAPI,
    system: systemAPI
};

export default API;