import React from 'react';
import { Box, Typography } from '@mui/material';
import MessagingComponent from '../components/MessagingComponent';

const MessagingPage = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: '#0A1929',
        position: 'relative',
      }}
    >
      {/* Fixed Header */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '64px',
          backgroundColor: 'rgba(15, 39, 68, 0.95)',
          backdropFilter: 'blur(10px)',
          borderBottom: '1px solid rgba(255,255,255,0.1)',
          display: 'flex',
          alignItems: 'center',
          px: 3,
          zIndex: 100,
        }}
      >
        <Typography 
          variant="h5" 
          sx={{ 
            color: 'white',
            fontWeight: 500
          }}
        >
          Mail Center
        </Typography>
      </Box>

      {/* Main Content with adjusted padding for header */}
      <Box 
        sx={{ 
          flexGrow: 1,
          mt: '64px', // Offset for fixed header
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <MessagingComponent />
      </Box>
    </Box>
  );
};

export default MessagingPage;