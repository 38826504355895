import React from 'react';
import { Dialog, IconButton, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const StudentDetailsModal = ({ open, onClose, studentId }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'rgba(18, 18, 18, 0.95)',
          backdropFilter: 'blur(10px)',
          height: '90vh',
          position: 'relative'
        }
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
          zIndex: 1,
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            bgcolor: 'rgba(0, 0, 0, 0.7)'
          }
        }}
      >
        <CloseIcon />
      </IconButton>
      
      <Box sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
        <iframe
          src={`/student-details-big/${studentId}`}
          style={{
            width: '100%',
            height: '100%',
            border: 'none'
          }}
          title="Student Details"
        />
      </Box>
    </Dialog>
  );
};

export default StudentDetailsModal;